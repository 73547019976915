export const CUSTOMER_DETAIL="CUSTOMER_DETAIL"
export const CUSTOMER_ORDERS_LIST="CUSTOMER_ORDERS_LIST"
export const ORDER_DETAIL="ORDER_DETAIL"
export const STORES_LIST="STORES_LIST"
export const TRADE_AREAS_LIST="TRADE_AREAS_LIST"
export const ALL_STORES_LIST="ALL_STORES_LIST"
export const SELECT_STORE="SELECT_STORE"
export const HANDLE_INPUT="HANDLE_INPUT"
export const VALIDATE_EMAIL="VALIDATE_EMAIL"
export const TIMER_START="TIMER_START"
export const ORDER_DATA="ORDER_DATA"
export const SHOW_TIMER="SHOW_TIMER"
export const PICKUP_STORES_LIST="PICKUP_STORES_LIST"
export const ORDER="ORDER"
export const PAYMENT = "PAYMENT";
export const ADDRESS_LIST = "ADDRESS_LIST";
export const SELECTED_FROM_SAVED_ADDRESS = "SELECTED_FROM_SAVED_ADDRESS";
export const SELECTED_ADDRESS = 'SELECTED_ADDRESS'
export const TOGGLE_FIRST_CLICK = 'TOGGLE_FIRST_CLICK'
export const RECEIVE_CUSTOMER_DATA  = 'RECEIVE_CUSTOMER_DATA';