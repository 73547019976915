// import moment from 'moment';
// import React, { Component } from 'react';
// import ReactToPrint from "react-to-print";
// import { priceunit } from '../../../client-config'
// import './fbrInvoice.css'
// var QRCode = require('qrcode.react');
// class Invoice extends Component<{ data: any, orderId: any }, {}> {
//     componentRef: any;
//     calcSubTotalTotal = (orderItems: any) => {
//         let sum = 0;
//         orderItems.forEach((item: any) => {
//             sum += parseInt(item.order_item_grossprice);
//         });
//         return Math.round(sum);
//     };
//     calcTotal = (orderItems: any) => {
//         let sum = 0;
//         orderItems.forEach((item: any) => {
//             sum += parseInt(item.order_item_grossprice);
//         });
//         return Math.round(sum);
//     };
//     render() {
//         let { data, orderId } = this.props;
//         // console.log("data.orderDetail.fbr_invoice_number",data.orderDetail.fbr_invoice_number);
//         let DateCreated: any = "";
//         let FutureDate: any = "";
//         let utcDateTime: any = "";
//         let FbrNumber: any = "";
//         let name: any = "";
//         let FbrNumberComplete: any = "";
//         if (data.orderDetail) {
//             DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
//             DateCreated = DateCreated[1] + " , " + DateCreated[0];

//             if (data.orderDetail.fbr_invoice_number != undefined) {
//                 FbrNumberComplete = data.orderDetail.fbr_invoice_number;
//                 FbrNumber = FbrNumberComplete.split(':');
//                 name = FbrNumber[0];
//                 FbrNumber = FbrNumber[1];
//                 console.log("FbrNumber", FbrNumber);
//             }
//         }
//         if (data.orderDetail && data.orderDetail.future_date) {
//             utcDateTime = moment(data.orderDetail.future_date).utc(false);
//             FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
//             FutureDate = FutureDate[1] + " , " + FutureDate[0];
//         }
//         return (
//             <div>
//                 <div className="d-flex justify-content-end ">
//                     {
//                         data.orderDetail.order_status_code !== 1 &&
//                         <ReactToPrint
//                             trigger={() => <button type="button" className="btn btn-success">Print</button>}
//                             content={() => this.componentRef}
//                         />
//                     }
//                 </div>
//                 <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
//                     <header className="clearfix">
//                         <div id="logo">
//                             <img width={143} height={132} style={{ borderRadius: 10 }} src={process.env.PUBLIC_URL + "/assets/img/br-logo.png"} />
//                         </div>
//                         <h4 className="text-center mt-3 change_color"><b>Baskin Robins</b></h4>
//                         <h6 className="heading_text text-center mt-3 change_color" >{data.orderDetail.store_name}</h6>
//                         <h6 className="heading_text text-center mt-3 change_color">{data.orderDetail.address}</h6>
//                         {/* <h6 className="heading_text text-center mt-3 change_color">The Potato Factory International</h6> */}
//                         <h6 className="heading_text text-center mt-3 change_color">NTN # {data.orderDetail.ntn_number}</h6>
//                         <h6 className="heading_text text-center mt-3 change_color">POS NO: {data.orderDetail.pos_no}</h6>

//                         <div id="project">
//                             {/* <h3>Customer Information</h3> */}
//                             <div style={{ display: 'flex'}}><span><b>ORDER NO:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40}}> {orderId}</span></div>
//                             <div style={{ display: 'flex' }}><span><b>Order Date:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40,}}> {data.orderDetail && DateCreated}</span></div>
//                             <div style={{ display: 'flex' }}><span><b>Customer:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40,}}> {data.orderDetail && data.orderDetail.login_name}</span></div>
//                             {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span><b>Address:</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40}}> {data.orderDetail.delivery_address}</span></div>}
//                             {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40,}}> {data.orderDetail.phone_number}</span></div>}
//                             <div style={{ display: 'flex' }}><span><b>Payment Mode: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40,}}>{data.orderDetail && data.orderDetail.payment_method}</span></div>
//                             {(data.orderDetail && data.orderDetail.special_inst) && <div style={{ display: 'flex' }}><span><b>Special Ins: </b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto', marginLeft: 40}}>{data.orderDetail && data.orderDetail.special_inst}</span></div>}
//                             {(data.orderDetail.order_status_code === 4 || data.orderDetail.order_status_code === 5 || data.orderDetail.order_status_code === 6) && <div style={{ display: 'flex' }}><span><b>Status:</b></span> <span style={{ whiteSpace: "normal", textAlign: 'left', width: "80px", textTransform: 'capitalize', marginLeft: 40}}>{data.orderDetail.order_status_description}</span></div>}
//                             {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b></span><br /><span>{FutureDate}</span></div>}
//                         </div>
//                     </header>
//                     <main>
//                         <table className="invoicetable">
//                             <thead>
//                                 <tr>
//                                     <th style={{ width: 40 }}>#</th>
//                                     <th style={{ width: 150 }}>DESC</th>
//                                     <th style={{ width: 60, overflow: 'unset' }}>PRICE</th>
//                                     <th style={{ width: 60, overflow: 'unset' }}>TAX %</th>
//                                     <th style={{ width: 40, overflow: 'unset' }}>QTY</th>
//                                     <th style={{ width: 60, overflow: 'unset' }}>GST</th>
//                                     <th style={{ width: 60, overflow: 'unset' }}>TOTAL</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {data.Items.map((obj: any, index: any) => {
//                                     let AddOnsPrice: any = 0;
//                                     let AddOnsTax: any = 0;
//                                     const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
//                                         return <li><b>{element.quantity * obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${priceunit}.${element.extra_price * obj.order_item_quantity}`}</li>
//                                     })
//                                     return (
//                                         <>
//                                             <tr title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}>
//                                                 <td style={{ whiteSpace: 'normal' }} colSpan={7}>
//                                                     <b>
//                                                         {(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}
//                                                     </b>
//                                                 </td>
//                                             </tr>
//                                             <tr key={index}>
//                                                 <td title={`${index + 1}`}>{index + 1}</td>
//                                                 <td style={{ padding: 10, width: 150 }}>
//                                                     <ul style={{ whiteSpace: 'normal' }}>
//                                                         {ingredients}
//                                                         <br />
//                                                         <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
//                                                     </ul>
//                                                 </td>
//                                                 <td className='textprop'>{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
//                                                     AddOnsPrice += (element.quantity * element.modifier_sale_price);
//                                                     AddOnsTax += element.tax_amount * element.quantity;
//                                                 })}
//                                                     {priceunit}{obj.order_item_grossprice - AddOnsPrice}
//                                                 </td>
//                                                 <td className='textprop' title={`${obj.tax_percent}`}>{obj.tax_percent}%</td>
//                                                 <td className='textprop' title={`${obj.order_item_quantity}`}>{obj.order_item_quantity}</td>
//                                                 <td className='textprop' title={`${obj.tax_amount}`}>{(obj.tax_amount - AddOnsTax).toFixed(2)}</td>
//                                                 <td className='textprop' title={`${priceunit}${obj.order_item_grossprice + Math.round((obj.tax_amount -  AddOnsTax) - AddOnsPrice)}`}>{priceunit}{obj.order_item_grossprice + Math.round((obj.tax_amount - AddOnsTax) - AddOnsPrice)}</td>
//                                             </tr>
//                                             {
//                                                 obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
//                                                     return <tr>

//                                                         <td className='total'></td>
//                                                         <td style={{ textAlign: "left" }}>
//                                                             <b>{element.quantity} </b>{element.modifier_name}
//                                                         </td>
//                                                         <td title={`${priceunit}${element.modifier_sale_price * element.quantity}`}>{priceunit}{element.modifier_sale_price * element.quantity}</td>
//                                                         <td title={`${element.tax_percent}`}>{element.tax_percent}%</td>
//                                                         <td title={`${element.quantity}`}>{element.quantity}</td>
//                                                         <td title={`${(element.tax_amount * element.quantity).toFixed(2)}`}>{(element.tax_amount * element.quantity).toFixed(2)}</td>
//                                                         <td title={`${priceunit}${(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}`}>{priceunit}{(element.modifier_sale_price * element.quantity) + Math.round(element.tax_amount * element.quantity)}</td>
//                                                     </tr>
//                                                 })
//                                             }
//                                         </>
//                                     )
//                                 })}
//                                 <tr>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td colSpan={3} style={{ textAlign: 'left' }} className="total textprop">Total Net Amount</td>
//                                     <td colSpan={2} className="total" title={`${priceunit}${data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}`}>{priceunit}{data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}</td>
//                                 </tr>
//                                 {data.orderDetail.coupon &&
//                                     <tr>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
//                                         <td colSpan={2} className="discount total" title={`${priceunit}${Math.round(data.orderDetail.coupon_discount_value)}`}>{priceunit}{Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
//                                     </tr>
//                                 }
//                                 {
//                                     data.orderDetail.discount > 0 &&
//                                     <tr>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td colSpan={3} style={{ textAlign: 'left' }} className="discount total">Discount</td>
//                                         <td colSpan={2} className="discount total" title={`${priceunit}${Math.round(data.orderDetail.discount)}`}>{priceunit}{Math.round(data.orderDetail.discount)} </td>
//                                     </tr>
//                                 }
//                                 {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td colSpan={3} style={{ textAlign: 'left' }} className="total">Delivery Charges</td>
//                                     <td colSpan={2} className="total" title={`${priceunit}${data.orderDetail && data.orderDetail.delivery_fee}`}>{priceunit}{data.orderDetail && data.orderDetail.delivery_fee}</td>
//                                 </tr>
//                                 }
//                                 <tr>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td colSpan={3} style={{ textAlign: 'left' }} className="total">{data.orderDetail.tax_percent}% Sales Tax</td>
//                                     <td colSpan={2} className="total" title={`${priceunit}${Math.round(data.orderDetail.tax_amount)}`}>{priceunit}{parseFloat(data.orderDetail.tax_amount)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
//                                 </tr>
//                                 {data.orderDetail.pos_service_fee > 0 &&
//                                     <>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td style={{ border: 'none' }}></td>
//                                         <td colSpan={3} style={{ textAlign: 'left' }} className="total">Pos Service Fee</td>
//                                         <td colSpan={2} className="total" title={`${priceunit}${data.orderDetail.pos_service_fee}`}>{priceunit}{data.orderDetail.pos_service_fee}</td>
//                                     </>
//                                 }
//                                 <tr>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td style={{ border: 'none' }}></td>
//                                     <td colSpan={3} style={{ textAlign: 'left' }} className="grand total"><b>Payable</b></td>
//                                     <td colSpan={2} className="grand total" title={`${priceunit}${(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount)}`}><b>{priceunit}{(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount)}</b> </td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                         {FbrNumber &&
//                             <>
//                                 <p className="text-center my-4 change_color"><span><b style={{ fontSize: "13px" }}>{name}# </b>{FbrNumber}{moment(data.orderDetail.date_created).local().format('/MM/YYYY').split(' ')}</span></p>
//                                 <div className="d-flex justify-content-around">
//                                     <div><img width={100} height={100} style={{ borderRadius: 10 }} src={process.env.PUBLIC_URL + "/assets/img/br-logo.png"} /></div>
//                                     <div><QRCode size='100' value={FbrNumber} /></div>
//                                 </div>
//                             </>
//                         }
//                         <p className="text-center mt-3 mb-0 change_color">Baskin Robins</p>
//                         <p className="text-center mt-3 change_color">www.webiste.com</p>
//                         <h6 className="text-center mt-3 change_color">{data.orderDetail.contact1}</h6>
//                         {/* <footer className="invoicefooter">
//                             "Verify this invoice through FBR TaxAsan Mobile App or SMS at 9966 and win excisting prize in draw"
//                         </footer> */}
//                     </main>
//                 </div>
//             </div>
//         )
//     }
// }
// export default Invoice;
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { priceunit,API_URL } from '../../../client-config'

// import './invoice.css'
import './fbrInvoice.css'
var QRCode = require('qrcode.react');
class Invoice extends Component<{ data: any, orderId: any }, {}> {
    componentRef: any;
    calcSubTotalTotal = (orderItems: any) => {
        let sum = "";
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return parseFloat(sum).toFixed(2);
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += item.order_item_grossprice;
        });
        return sum;
    };
    // setBrandLogo = (brandId: number) => {
    //     switch (brandId) {
    //         case 1:
    //             return process.env.PUBLIC_URL + "/assets/img/ky.png";
    //         case 2:
    //             return process.env.PUBLIC_URL + "/assets/img/BR.png";
    //         case 3:
    //             return process.env.PUBLIC_URL + "/assets/img/halla-shawarmaa.jpg";
    //         default:
    //             return process.env.PUBLIC_URL + "/assets/img/ky.png";
    //     }
    // }
    render() {
        let currency = priceunit
        let token:any = sessionStorage.getItem("token")
        let decoded:any = jwtDecode(token)
        let { data, orderId } = this.props;
        // console.log("data.orderDetail.fbr_invoice_number",data.orderDetail.fbr_invoice_number);
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        let FbrNumber: any = "";
        let FbrNumberComplete: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];
            if (data.orderDetail.fbr_invoice_number != undefined) {
                FbrNumberComplete = data.orderDetail.fbr_invoice_number;
                FbrNumber = FbrNumberComplete.split(':');
                FbrNumber = FbrNumber[1];
                console.log("FbrNumber", FbrNumber);
            }
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        return (
            <div>
                <div className="d-flex justify-content-end ">
                {
                        // data.orderDetail.order_status_code !== 1 &&
                        data.orderDetail.order_status_code == 1  &&
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    }
                </div>
                <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            {/* <img width={150} height={80} src={process.env.PUBLIC_URL + "/assets/img/tortilla.svg"} /> */}
                            {/* <img width={150} height={80} src={`${data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : this.setBrandLogo(selectedBrandId)}`} /> */}
                            <img width={150} height={80} src={`${data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/ky.png"}`} />
                        </div>
                        {/* <h6 className="text-center mt-3"><b>{decoded && decoded.brand && decoded.brand.brand_name}</b></h6> */}
                        <h6 className="text-center mt-3">
                            <b>
                                {/* {data.orderDetail && data.orderDetail.brand_name} */}
                                {data.orderDetail && data.orderDetail.brand_name}
                            </b>
                        </h6>
                        <h6 className="text-center mt-3">{data.orderDetail.city}</h6>

                        <div id="project" className="invoiceh1">
                            {/* <h3>Customer Information</h3> */}
                            <div style={{ display: 'flex' }}><span><b>ORDER NO:</b><br/><b>رقم الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {orderId}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Order Date:</b><br/><b>تاريخ الطلب</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                            <div style={{ display: 'flex' }}><span><b>Customer:</b><br/><b>اسم العميل</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> 
                                {/* {data.orderDetail && data.orderDetail.login_name} */}
                                {data.orderDetail && data.orderDetail.login_name? data.orderDetail.login_name: 'N/A' }
                            </span></div>
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span><b>Phone:</b><br/><b>هاتف</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Store:</b><br/><b>متجر</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                            <div style={{ display: 'flex' }}><span><b>From:</b><br/><b>عنوان الفرع</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span><b>Delivery at:</b><br/><b>عنوان التسليم</b> </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address}</span></div>}
                            <div style={{ display: 'flex' }}><span><b>Special Ins:</b><br/><b>تعليمات خاصة</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst}</span></div>
                            {utcDateTime._isValid && <div><span><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time:</b><br/><b>زمن</b></span><br /><span>{FutureDate}</span></div>}
                            {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b><br/><b>فاتورة FBR</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                            {/* {(data.orderDetail.fbr_invoice_number!=undefined) ? 
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left", fontWeight:'bold' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}> <QRCode size='50' value={FbrNumber}/></span></div>
                            :
                            <div></div>
                            } */}
                        </div>
                        <div id="secProject">
                            <div id="project2">
                                <div style={{paddingRight: '10px'}}><h6 style={{ fontSize: 13,padding:'4px' }}>Payment Type</h6><h6>طريقة الدفع</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13,padding:'4px' }}>Ordering Channel</h6><h6>تم الطلب خلال</h6>{data.orderDetail && data.orderDetail.order_channel}
                                </div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{ fontSize: 13,padding:'4px' }}>Ordering Mode</h6><h6>نوع الطلب</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                    <table className="invoicetable">
                        <thead>
                            <tr>
                                {/* <th className="service" style={{ width: "5vw" }}></th> */}
                                <th className="desc">Name <b>الاسم</b></th>
                                {/* <th className="desc">DESCRIPTION</th> */}
                                {/* <th>PRICE</th> */}
                                {/* <th style={{ width: 50 }}>QTY</th> */}
                                <th style={{ width: 100 }}>TOTAL <br/><b>الإجمالي</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.Items.map((obj: any, index: any) => {
                                let AddOnsPrice: any = 0;
                                const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                    return <li><b>{element.quantity*obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${currency} ${element.extra_price*obj.order_item_quantity}`}</li>
                                })
                                return (
                                    <>
                                        <tr key={index}>
                                            {/* <td className="service">{index + 1}</td> */}
                                            <td className="desc" title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}><b>{(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)} x {obj.order_item_quantity}</b>
                                                <ul>
                                                    {ingredients}
                                                    <br />
                                                    <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                </ul>
                                            </td>
                                            {/* <td className="desc" title={modifiers}>{modifiers}</td> */}
                                            {/* <td className="unit">{Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td> */}
                                            {/* <td className="qty">{obj.order_item_quantity}</td> */}
                                            <td className="total">{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                AddOnsPrice += (element.quantity * element.modifier_sale_price);
                                            })}
                                                {currency} {data.orderDetail.order_channel=='web' || data.orderDetail.order_channel=='mobile' || data.orderDetail.order_channel=='callcenter' ? (obj.order_item_grossprice - (AddOnsPrice * obj.order_item_quantity)).toFixed(2) : (obj.order_item_netprice)}</td>
                                        </tr>
                                        {
                                            obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                console.log("Total Item-PriceData9999999", obj)
                                                let quantity:any = 0
                                                if(element.modGroup_max_quantity > 1){
                                                    
                                                    quantity =  element.quantity > 1 && obj.order_item_quantity> 1 ? element.quantity + obj.order_item_quantity : element.quantity
                                                }else{
                                                    quantity =   obj.order_item_quantity> 1 ? obj.order_item_quantity : element.quantity
                                                }
                                                return <tr><td style={{ textAlign: "left" }}><b>{element.quantity* obj.order_item_quantity} </b>{element.modifier_name}</td><td className='total'>{currency} {element.modifier_sale_price * quantity}</td></tr>
                                            })
                                        }
                                        <tr><td style={{border: 'none'}}></td><td style={{border: 'none'}}></td></tr>
                                        <tr>
                                            <td style={{paddingLeft: '30%'}}>Total <b> الإجمالي</b></td>
                                            <td className='total'>{currency} {parseFloat(obj.order_item_grossprice).toFixed(2)}</td>
                                            {/* <td className='total'>{currency} {parseFloat(obj.order_item_netprice).toFixed(2)}</td> */}
                                        </tr>
                                    </>
                                )
                            })}
                            <tr>
                            <td colSpan={1} style={{paddingLeft: '30%'}}>Sub Total <b>المبلغ الاجمالي</b></td>
                                {/* <td className="total">{currency} {data.Items.length > 0 && parseFloat(data.orderDetail.order_grosspricewd).toFixed(2)}</td> */}
                                <td className="total" title={`${currency} ${data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}`}>{currency} { data.orderDetail && data.Items.length > 0 &&  data.orderDetail.order_channel === 'call center' ? ( parseFloat(data.orderDetail.order_grossprice) - parseFloat(data.orderDetail.order_grossprice) * (parseFloat(data.orderDetail.tax_percent) / 100)).toFixed(2) : data.Items.length > 0 && parseFloat(data.orderDetail.order_grosspricewd).toFixed(2) }</td>
                            </tr>
                            {(data.orderDetail && data.orderDetail.radeem_cash>0) &&
                            <tr>
                                <td style={{paddingLeft: '30%'}} colSpan={1}>Redeem Cash <b> استرداد النقود</b></td>
                                {/* <td className="total">- {currency}  {data.orderDetail && data.orderDetail.radeem_cash.toFixed(2)}</td> */}
                                <td className='total'> {`${currency} ${ data.orderDetail.tax_amount ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : data.orderDetail.tax_amount }`} </td>
                            </tr>
                            }
                            {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                <td style={{paddingLeft: '30%'}} colSpan={1}>Delivery Charges <b> رسوم توصيل </b></td>
                                <td className="total">{currency} {data.orderDetail && data.orderDetail.delivery_fee?.toFixed(2)}</td>
                            </tr>
                            }
                            {data.orderDetail.coupon &&
                                <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1} className="discount">Your Discount <b> الخصم</b></td>
                                    <td className="discount">{currency} {data.orderDetail && data.orderDetail.coupon_discount_value} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                </tr>
                            }

                            <tr>
                                <td style={{paddingLeft: '30%'}} colSpan={1}>{data.orderDetail.tax_percent}% VAT <b> الضريبة</b></td>
                                {/* <td className='total'>{currency} {data.orderDetail.tax_amount ? data.orderDetail.tax_amount : 0} </td>  */}
                                {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                <td className='total' title={`${parseInt(data.orderDetail.tax_amount).toFixed(2)}`}>{`${currency} ${ data.orderDetail.tax_amount ? ((parseInt(data.orderDetail.order_grossprice) * parseInt(data.orderDetail.tax_percent)) / 100 ).toFixed(2) : data.orderDetail.tax_amount }`} </td>
                            </tr>
                            {
                                data.orderDetail.discount > 0 &&
                                <tr>
                                    <td style={{paddingLeft: '30%'}} colSpan={1} className="discount">Your Discount <b> خصمك</b></td>
                                    <td className="discount">{currency} {data.orderDetail && data.orderDetail.discount} </td>
                                </tr>
                            }
                            <tr>
                                <td style={{paddingLeft: '30%'}} colSpan={1} className="grand total"><b>GRAND TOTAL</b> <b> المبلغ الإجمالي للطلب</b></td>
                                <td className="grand total"><b>{currency} {(data.Items.length > 0 && data.orderDetail) && parseFloat(data.orderDetail.order_grossprice).toFixed(2)}</b> </td>
                                {/* <td className="grand total"><b>{currency} {(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + parseFloat(data.orderDetail.tax_amount)}</b> </td> */}
                            </tr>
                        </tbody>
                    </table>
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                        <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                        {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                        <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                        <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                        <footer className="invoicefooter mb-3">
                            {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                            "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                        </footer>
                    </main>
                </div>
            </div>
        )
    }
}
export default Invoice;