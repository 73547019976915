import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
// import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState } from '../../redux'
import { AgentTargetsProps, AgentTargetsState } from '../../../interfaces/settings';
import { logoutUser } from '../../../redux/actions/userAction';
import Select from 'react-select';
import { agentsList } from '../../../redux/actions/agentAction';
import { activeAgentsList, agentsTargetList, saveAgentTarget } from '../../../redux/actions/settingsAction';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

class AgentTargets extends Component<AgentTargetsProps, AgentTargetsState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            country: "",
            state: "",
            tax_value: "",
            delivery_fee: "",
            multiJson: [],
            targetType:"",
            agent_id:'',
            target_value: ""
        }
        this.handleTargetSubmit = this.handleTargetSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }
    componentDidMount() {
        // this.props.getTaxValue();
        // this.props.countryList();
        // this.props.statesList();
        // this.props.discountsList();
        this.props.activeAgentsList();
        this.props.agentsTargetList();
        document.title = "SimplexCRM | Settings"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        if (event.target.name === 'targetType') {
            // Reset targetType when the select input changes
            this.setState({
                targetType: event.target.value
            });
        } else if (event.target.name === 'country') {
            // Handle country-related logic here if needed
            if (event.target.value === "") {
                this.setState({ state: "", tax_value: "" });
            } else {
                if (event.target.value !== this.state.country) {
                    this.setState({ state: "", tax_value: "" });
                }
            }
        } else {
            // Handle other input fields as needed
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }
    
    handleStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ state: data.state_id, tax_value: data.tax_percent ? data.tax_percent : "" });
        } else {
            this.setState({ state: "", tax_value: "" });
        }
    }
    isTaxSubmitReady = () => {
        let { target_value,targetType, agent_id } = this.state;
        return (target_value !== "" && targetType !== "" && agent_id !== "");
    }
    handleTargetSubmit = (event: any) => {
         // Extract the default values for your fields
        const defaultTargetValue = ""; // Replace with the actual default value
        const defaultTargetType = ""; // Replace with the actual default value
        const defaultAgentId = ""; // Replace with the actual default value
        let { target_value,targetType, agent_id } = this.state;
        let data:any = {
            agent_id: agent_id,
        }
        if(targetType === 'daily'){
            data.daily_target = target_value
        }
        else if(targetType === 'monthly'){
            data.monthly_target = target_value
        }
        if(targetType === 'weekly'){
            data.weekly_target = target_value
        }
        this.props.saveAgentTarget(data);
        event.preventDefault()
          // Reset the state to default values
        this.setState({
            target_value: defaultTargetValue,
            targetType: defaultTargetType,
            agent_id: defaultAgentId,
        })
    }
    handleDeliveryFeeSubmit = (event: any) => {
        let { country, delivery_fee } = this.state;
        let obj = {
            country_code: country,
            delivery_fee: delivery_fee
        }
        this.props.saveDeliveryFee(obj);
        this.props.agentsTargetList()
        event.preventDefault()
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    render() {
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: "Agents Not Found",
          };
        // if (sessionStorage.token) {
        //     jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        //         if (err) {
        //             this.props.logoutUser();
        //         }
        //     });
        // } else {
        //     return <Redirect to="/" />
        // }
        let msg, discmsg;
        let taxcolor, disccolor;
        let deliverymsg, deliverycolor;
        let { country, target_value } = this.state;
        const { isSaved, message, states, countries, deliveryMsg, saveDelivery,activeAgentlist,agentsTargetlist } = this.props;
        if (!isSaved) { msg = message; taxcolor = 'red' } else { msg = message; taxcolor = 'green' }
        if (!saveDelivery) { deliverymsg = deliveryMsg; deliverycolor = 'red' }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Agent Sales Target Settings</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="card shadow rounded">
                                    <div className="row p-3">
                                        <div className="col-lg-12">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Sales Target</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Target Type<span className="text-danger">*</span></label>
                                                                        <select name="targetType" className="form-control mt-2" required data-msg="Please enter Country" value={this.state.targetType} onChange={this.handleInputChange}>
                                                                            <option value="" >Select Target Type</option>
                                                                            <option value="monthly">Monthly</option>
                                                                            <option value="weekly">Weekly</option> 
                                                                            <option value="daily">Daily</option> 
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                <div className="form-group">
                                                                        <select name="agent_id" className="form-control mt-2" required data-msg="Please enter Country" value={this.state.agent_id} onChange={this.handleInputChange}>
                                                                            <option>Select Agent</option>
                                                                            {activeAgentlist &&
                                                                                activeAgentlist.map((agent: any, index: any) => (
                                                                                    <option key={index} value={agent.user_id}>{agent.user_name} </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Target Value <span className="text-danger">*</span></label>
                                                                        <input id="targetValue" type="number" name="target_value" value={target_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTargetSubmit} disabled={!this.isTaxSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: deliverycolor }}>
                                                            {deliverymsg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option>Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_code}>{country.country_name} ({country.delivery_fee})</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <input id="deliveryFee" type="number" name="delivery_fee" placeholder="delivery Fee" required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end mt-4">
                                                                <button onClick={this.handleDeliveryFeeSubmit} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
            <section className="tables no-padding">   
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <BootstrapTable
                            version="4"
                            data={agentsTargetlist}
                            search={true}
                            options={options}
                            exportCSV={true}
                            csvFileName="agentsTarget.csv"
                            hover
                          >
                            <TableHeaderColumn
                              dataField="id"
                              csvHeader="#"
                              width="60"
                              dataSort={true}
                              isKey
                            >
                              #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="user_name"
                              csvHeader="Name"
                              width="100"
                              columnTitle
                            >
                              Agent Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="daily_target"
                              csvHeader="Email"
                              width="100"
                              columnTitle
                            >
                              Daily Target
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="weekly_target"
                              csvHeader="Phone Number"
                              width="100"
                              columnTitle
                            >
                              Weekly Target
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="monthly_target"
                              csvHeader="City"
                              width="100"
                              columnTitle
                            >
                              Monthly Target
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                              dataField="address"
                              csvHeader="Address"
                              width="100"
                              columnTitle
                            >
                              Address
                            </TableHeaderColumn> */}
                            {/* <TableHeaderColumn
                              dataField="action"
                              width="180"
                              dataFormat={actionFormatter}
                              formatExtraData={this.props}
                              export={false}
                            >
                              Action
                            </TableHeaderColumn> */}
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </section>
  
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        activeAgentlist: state.settings.activeAgentList,
        agentsTargetlist: state.settings.agent_target_list,
        // discounts: state.setting.discounts,
        // discountMsg: state.setting.discMsg,
        // states: state.setting.states,
        // countries: state.store.countries,
        // isSaved: state.setting.isSaved,
        // isInserted: state.setting.isInserted,
        // tax: state.setting.taxData,
        // deliveryMsg: state.setting.deliveryMsg,
        // saveDelivery: state.setting.saveDelivery
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        // logoutUser: function () {
        //     dispatch(logoutUser());
        // },
        // countryList: function () {
        //     dispatch(countryList())
        // },
        // discountsList: function () {
        //     dispatch(discountsList())
        // },
        // statesList: function () {
        //     dispatch(statesList())
        // },
        saveAgentTarget: function (data: any) {
            dispatch(saveAgentTarget(data))
        },
        agentsTargetList: function () {
            dispatch(agentsTargetList());
        },
        // saveDiscountValue: function (data: any) {
        //     dispatch(saveDiscountValue(data))
        // },
        // saveDeliveryFee: function (data: any) {
        //     dispatch(saveDeliveryFee(data))
        // }
        activeAgentsList: function () {
            dispatch(activeAgentsList());
          },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgentTargets);