import { RECENT_PAYMENTS, RECENT_ORDERS, RECENT_ITEMS, HOME_COUNTER, MONTHLY_ORDERS,MONTHLY_SALES,CUSTOMERS_LIST } from './homeType'
import Api from '../../components/Api';
import jwt_decode from "jwt-decode";
// export const homeCounter = () => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');
//         Api.get(`call-portal/home_counter`, {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     console.log(response.data, "response-data-success99999")
//                     dispatch({
//                         type: HOME_COUNTER,
//                         payload: response.data.successResponse[0]
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }
// export const monthlyOrdersForLine = () => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');
//         Api.get('/call-portal/monthly_orders', {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     dispatch({
//                         type: MONTHLY_ORDERS,
//                         payload: response.data.successResponse
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }
// export const monthlySalesForBar = (obj: any) => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');
//         Api.get('/call-portal/monthly_sales', {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     dispatch({
//                         type: MONTHLY_SALES,
//                         payload: response.data.successResponse
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }
// export const recentOrders = () => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');
//         Api.get(`/call-portal/recent_orders`, {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     dispatch({
//                         type: RECENT_ORDERS,
//                         payload: response.data.successResponse
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }
// export const recentItems = () => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');
//         Api.get('/call-portal/recent_menu_items', {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     dispatch({
//                         type: RECENT_ITEMS,
//                         payload: response.data.successResponse
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// }
// export const customersList = () => {
//     return function (dispatch: any) {
//         let token: any = sessionStorage.getItem('token');  
//         Api.get(`/call-portal/top_customers`, {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//             .then((response) => {
//                 if (response.data.success) {
//                     console.log(response.data.successResponse)
//                     dispatch({
//                         type: CUSTOMERS_LIST,
//                         payload: response.data.successResponse
//                     })
//                 }
//             }).catch(err => {
//                 if (err.response) {
//                     console.log(err.response.data.message)
//                 } else {
//                     alert(err.message)
//                 }
//             });
//     }
// } 

// New Routes with MultiBranding
export const homeCounter = () => {
    // console.log("days999", days)
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/call-portal/home_counter', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {

                if (response.data.success) {
                    dispatch({
                        type: HOME_COUNTER,
                        payload: response.data.successResponse[0]
                    })
                    //console.log("In action: ", response.data.successResponse[0]);
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const monthlyOrdersForLine = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/call-portal/monthly_orders', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MONTHLY_ORDERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const monthlySalesForBar = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/call-portal/monthly_sales', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MONTHLY_SALES,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const recentOrders = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/call-portal/recent_orders', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if(response.data.successResponse.length > 0){
                        dispatch({
                            type: RECENT_ORDERS,
                            payload: response.data.successResponse
                        })
                    }
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const recentItems = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/call-portal/recent_menu_items', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: RECENT_ITEMS,
                    payload: response.data.successResponse
                })
            }
        }).catch(err => {
            if (err.response) {
                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}

export const customersList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');  
        Api.get(`/call-portal/top_customers`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse)
                    dispatch({
                        type: CUSTOMERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
} 