import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import _ from 'lodash'
import NumericInput from "react-numeric-input";
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { clearCart, customerDetail, getCart, getStores, getTaxValue, logoutUser, orderDetail, saveCart } from '../../redux'
import BeautyStars from 'beauty-stars';
import { CartProps } from '../../interfaces/cart';

class Cart extends Component<CartProps, { phone: any, isValidPhone: any }> {
    cartTotal: number;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            phone: "",
            isValidPhone: ""
        }
        this.cartTotal = 0
    }
    componentDidMount() {
        this.props.storesList();
        document.title = "SimplexCRM | Cart"
    }
    changeQuantity = (carts: any, currentIndex: any) => {
        let cart: any = sessionStorage.getItem("cart");
        console.log("increase cart")
        let Cart: any = cart;
        let dataCart = JSON.parse(Cart);
        let price = dataCart[currentIndex].price;
        let actualPrice = dataCart[currentIndex].actualPrice;
        dataCart.map((obj: any, index: any) => {
            if (currentIndex == index) {
                if (carts) {
                    obj.totalItemPrice = carts * obj.totalItemPrice;
                    obj.subTotal = carts * actualPrice;
                    obj.quantity = carts;
                } else {
                    dataCart.splice(currentIndex, 1);
                    this.props.saveCart(dataCart);
                    this.props.getCart();
                    this.cartTotal = 0;
                }
            }
        });
        this.props.saveCart(dataCart);
        setTimeout(() => {
            this.props.getCart();
        }, 500);
    };



    calcItemTotal = (carts: any) => {
        let sum = 0;
        // cart.forEach((item: any) => {
        sum += parseInt(carts.totalItemPrice);
        // });
        return sum;
    };

    calcTotal = (data: any) => {
        let { deliveryfee } = this.props;
        let sum = 0;
        data.forEach((item: any) => {
            sum += parseInt(item.totalItemPrice);
        });
        let discountValue: any = 0;
        // if (!_.isEmpty(coupon)) {
        // this.discount = coupon.coupon_value;
        // discountValue = (sum * coupon.coupon_value) / 100;
        // } else {
        // this.discount = 0;
        // this.cartTotal = 0;
        // }
        if (this.props.orderType == 'Delivery') {
            sum = sum - discountValue + deliveryfee;
        } else if (this.props.orderType == 'Pickup') {
            sum = sum - discountValue;
        }
        this.cartTotal = Math.round(sum);
        return Math.round(sum);
    };

    decrementCartQuantity(currentIndex: any) {
        let cart: any = sessionStorage.getItem("cart");
        let Cart: any = cart;
        let dataCart = JSON.parse(Cart);
        if (dataCart[currentIndex].item) {
          let price = dataCart[currentIndex].selectedsize.discount_price ? dataCart[currentIndex].selectedsize.discount_price : dataCart[currentIndex].selectedsize.mrp;
          let actualPrice = dataCart[currentIndex].actualPrice;
          dataCart.map((obj: any, index: any) => {
            if (currentIndex == index) {
              if (obj.quantity == 1) {
                dataCart.splice(currentIndex, 1);
                this.props.saveCart(dataCart);
                this.props.getCart();
                this.cartTotal = 0;
              }
              obj.totalItemPrice = obj.totalItemPrice - price;
              obj.subTotal -= actualPrice;
              obj.quantity -= 1;
            }
          });
          this.props.saveCart(dataCart);
          setTimeout(() => {
            this.props.getCart();
          }, 500);
        }
        else {
          let price = dataCart[currentIndex].combo.discount_price ? dataCart[currentIndex].combo.discount_price : dataCart[currentIndex].combo.combo_mrp_price;
          let extra_price = 0
          let actualPrice = dataCart[currentIndex].actualPrice;
          let comboDetails = dataCart[currentIndex].combo;
          if(typeof comboDetails.choices == 'object') {
          Object.keys(comboDetails.choices).map((key: any) => {
            comboDetails.choices[key].map((choice: any) => {
              if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
                extra_price += choice.extra_price*choice.quantity;
              }
            })
          })
        }
        else if(typeof comboDetails.choices == 'string'){
          let choicesData: any = [];
          choicesData = JSON.parse(comboDetails.choices);
                choicesData.map((choice:any)=> {
                  if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
                    extra_price += choice.extra_price*choice.quantity;
                  }
                })
        }
          dataCart.map((obj: any, index: any) => {
            if (currentIndex == index) {
              if (obj.quantity == 1) {
                dataCart.splice(currentIndex, 1);
                this.props.saveCart(dataCart);
                this.props.getCart();
                this.cartTotal = 0;
              }
              obj.totalItemPrice = obj.totalItemPrice - price - extra_price;
              obj.subTotal -= actualPrice;
              obj.quantity -= 1;
            }
          });
          this.props.saveCart(dataCart);
          setTimeout(() => {
            this.props.getCart();
          }, 500);
        }
      }
    
      incrementCartQuantity(currentIndex: any) {
        let cart: any = sessionStorage.getItem("cart");
        let Cart: any = cart;
        let dataCart = JSON.parse(Cart);
        if (dataCart[currentIndex].item) {
          let price = dataCart[currentIndex].selectedsize.discount_price ? dataCart[currentIndex].selectedsize.discount_price : dataCart[currentIndex].selectedsize.mrp;
          let actualPrice = dataCart[currentIndex].actualPrice;
          dataCart.map((obj: any, index: any) => {
            if (currentIndex == index) {
              obj.totalItemPrice = obj.totalItemPrice + price;
              obj.subTotal += actualPrice;
              obj.quantity += 1;
            } else {
              //dataCart.splice(currentIndex, 1);
              this.props.saveCart(dataCart);
              this.props.getCart();
              this.cartTotal = 0;
            }
          });
          this.props.saveCart(dataCart);
          setTimeout(() => {
            this.props.getCart();
          }, 500);
        }
        else {
          let price = dataCart[currentIndex].combo.discount_price ? dataCart[currentIndex].combo.discount_price : dataCart[currentIndex].combo.combo_mrp_price;
          let extra_price = 0
          let actualPrice = dataCart[currentIndex].actualPrice;
          let comboDetails = dataCart[currentIndex].combo;
          if(typeof comboDetails.choices == 'object') {
            Object.keys(comboDetails.choices).map((key: any) => {
              comboDetails.choices[key].map((choice: any) => {
                if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
                  extra_price += choice.extra_price*choice.quantity;
                }
              })
            })
            dataCart.map((obj: any, index: any) => {
              if (currentIndex == index) {
                obj.totalItemPrice = obj.totalItemPrice + price + extra_price;
                obj.subTotal += actualPrice;
                obj.quantity += 1;
              } else {
                //dataCart.splice(currentIndex, 1);
                this.props.saveCart(dataCart);
                this.props.getCart();
                this.cartTotal = 0;
              }
            });
          }
          else if(typeof comboDetails.choices == 'string'){
            let choicesData: any = [];
            choicesData = JSON.parse(comboDetails.choices);
                  choicesData.map((choice:any)=> {
                    if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
                      extra_price += choice.extra_price*choice.quantity;
                    }
                  })
                  dataCart.map((obj: any, index: any) => {
                    if (currentIndex == index) {
                      obj.totalItemPrice = obj.totalItemPrice + price + extra_price;
                      obj.subTotal += actualPrice;
                      obj.quantity += 1;
                    } else {
                      //dataCart.splice(currentIndex, 1);
                      this.props.saveCart(dataCart);
                      this.props.getCart();
                      this.cartTotal = 0;
                    }
                  });
          }  
       
          this.props.saveCart(dataCart);
          setTimeout(() => {
            this.props.getCart();
          }, 500);
        }
      }
    

     

    cartItem = (data: any) => {
        // let { cart } = this.props
        if (!_.isEmpty(data)) {
            let cart: any[] = JSON.parse(data);
            if (cart.length > 0) {
                return (
                    <section className="projects no-padding-top">
                        <div className="container-fluid">
                            {cart.map((carts, indexes) => (
                                <div className="project">
                                    <div className="row bg-white has-shadow">
                                        <div className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                                            <div className="project-title d-flex align-items-center">
                                                <div className="image has-shadow">
                                                    <img
                                                        // src={`https://yum.simplexts.net:3000/${carts.image}`}
                                                        src={process.env.PUBLIC_URL + "assets/img/c3.jpg"}
                                                        alt="product"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="text">
                                                    <h3 className="h4">{(!_.isEmpty(carts.item) &&
                                                        carts.item.item_name) ||
                                                        (!_.isEmpty(carts.combo) &&
                                                            carts.combo.combo_name)}
                                                    </h3><small>{carts.selectedsize && `(${carts.selectedsize.size})`}</small>
                                                </div>
                                            </div>
                                            <div className="project-date">
                                                <span className="hidden-sm-down">
                                                <div style={{ display: "flex", width: '9rem', justifyContent: "space-between" }}>
                                                <div className="decrement-btn" onClick={() => this.decrementCartQuantity(indexes)}><a className="dec-icon">-</a></div>
                                                <div style={{ fontWeight: "bold", fontSize: 18 }}>{carts.quantity}</div>
                                                <div className="increment-btn" onClick={() => this.incrementCartQuantity(indexes)}><a className="inc-icon">+</a></div>
                                            </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="right-col col-lg-6 d-flex align-items-center flex-row-reverse">
                                            <div><strong>{carts.totalItemPrice} PKR</strong></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                );
            } else {
                return (
                    <section className="projects no-padding-top">
                        <div className="container-fluid">
                            <div className="project">
                                <div className="row bg-white has-shadow">
                                    <div className="col-lg-12 d-flex align-items-center justify-content-center">
                                        Empty Cart
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
        } else {
            return (
                <section className="projects no-padding-top">
                    <div className="container-fluid">
                        <div className="project">
                            <div className="row bg-white has-shadow">
                                <div className="col-lg-12 d-flex align-items-center justify-content-center">
                                    Empty Cart
                            </div>
                            </div>
                        </div>
                    </div>
                </section>

            )
        }
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { cart } = this.props;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">

                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Cart Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* <div className="breadcrumb-holder container-fluid"> */}
                        {/* <ul className="breadcrumb"> */}
                        <div className="d-flex justify-content-end px-5 pr-2 pt-4">
                            <input type="submit" value="Clear Cart" className="btn btn-warning" onClick={()=>{this.props.clearCart()}} />
                        </div>
                        <section className="tables pt-3">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        {this.cartItem(cart)}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
};
function ratingsFormatter(cell: any, row: any) {
    return (
        <BeautyStars
            value={cell}
            size="16"
            inactiveColor="#b7b7b7"
        />
    )
}
const mapStateToProps = (state: any) => {
    return {
        cart: state.cart.cartData,
        stores: state.customer.storesList,
        tax: state.cart.taxData,
        deliveryfee: state.customer.deliveryfee,
        message: state.customer.message,
        isUpdated: state.customer.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        orderDetail: function (orderId: any) {
            dispatch(orderDetail(orderId));
        },
        customerDetail: (data: any) => {
            dispatch(customerDetail(data))
        },
        storesList: function () {
            dispatch(getStores());
        },
        getTaxValue: (state_id: any) => {
            dispatch(getTaxValue(state_id))
        },
        getCart: function () {
            dispatch(getCart());
        },
        saveCart: function (cart: any[]) {
            dispatch(saveCart(cart));
        },
        clearCart: function () {
            dispatch(clearCart());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);