import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from '../components/footer/login'
import { LoginState, LoginProps } from '../interfaces/user'
import { connect } from 'react-redux'
import { loginUser } from '../redux'

class Login extends Component<LoginProps, LoginState> { 
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            password: "",
            lat:0,
            lng:0
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCRM | Login"
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { email, password,lat,lng } = this.state;
        this.props.loginUser(email, password,lat,lng);
    }
    render() {
        let msg;
        let messcolor;
        const { isLoggedIn, message } = this.props;
        if (isLoggedIn === false) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page login-page">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* Logo & Information Panel */}
                            <div className="col-lg-6">
                                <div style={{background:'#d9b165'}} className="info d-flex align-items-center justify-content-center">
                                    <div className="content">
                                        <div className="logo">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} width="235" height="235" alt="..." className="img-fluid ml-3" />
                                            <h1 className="text-center mt-3">Simplex CRM</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* htmlForm Panel  */}
                            <div className="col-lg-6 bg-white">
                                <div className="form d-flex align-items-center">
                                    <div className="content">
                                        <strong><p className="text-center font-weight-light" style={{ color: messcolor }}>
                                            {msg}
                                        </p></strong>
                                        <form className="form-validate">
                                            <div className="form-group">
                                            <label className="form-control-label">Email</label>
                                                <input id="login-username" type="text" name="email" required data-msg="Please enter your email" className="input-material" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-group-material">
                                            <label className="form-control-label">Password</label>
                                                <input id="login-password" type="password" name="password" required data-msg="Please enter your password" className="input-material" onChange={this.handleInputChange} />
                                            </div>
                                            <button style={{background:'#d9b165',border:"unset"}} id="login" onClick={this.handleSubmit} className="btn btn-block btn-warning">Login</button>
                                        </form>
                                        <Link style={{color:'#d9b165'}} to="forgot" className="">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        message: state.user.message,
        isLoggedIn: state.user.isLoggedIn
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        loginUser: function (email: any, password: any,lat:any,lng:any) {
            dispatch(loginUser(email, password,lat,lng));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
