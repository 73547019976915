import React, { Component } from "react";
import { Redirect, Prompt } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import _ from "lodash";
import NumericInput from "react-numeric-input";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import axios from 'axios';
import { brandsList, saveBrand } from "../../redux/actions/brandAction";
import {
  allGroupsList,
  clearCart,
  getStoreByCity,
  findLocalStores,
  getCart,
  getComboDetail,
  getItemDetail,
  getStores,
  getTaxValue,
  handleAddressInput,
  handleMenuInput,
  setTradeAreas,
  logoutUser,
  setStoreByTradeZone,
  menuItemsListByGroupsID,
  ordersCounter,
  saveCart,
  saveOrder,
  saveStore,
  customerDetail,
  getStoreByTradeZone,
  getTradeAreasByTradeZone,
  menuSet,
  setStoreByCity,
} from "../../redux";
import Clock from "../../components/clock";
import { MenuProps, MenuState } from "../../interfaces/menu";
import CheckChanges from "../../components/confirmOnLeave";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { url } from "inspector";
import classes from "*.module.sass";
import "./menu.css";
import Geocode from "react-geocode";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import { stopTimer } from "../../redux/actions/headerAction";
import { applyCoupon, findPickupStores, handleGeoCodeAddress, saveBackupStores, SaveCartTotal, SaveDiscountValue, saveOrderPeriod, saveSelectStoreId, SaveTaxValue, storesListForMultiSelect, UpdateMenuItems, handleHouseNo, findLocalStoreWithoutMap, getTimezone, getTimezoneForLater } from "../../redux/actions/menuAction";
import store from "../../redux/store";
import { BASE_URL, GOOGLE_MAPS_API_KEY, priceunit } from "../../client-config";
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import moment from "moment";
import Select from 'react-select';
import Loader from "react-loader-spinner";
import { getCitiesWeb, getTradeZonesWeb, getTradeAreas, setTradeZoneName, setCityName, setTradeAreaName } from "../../redux/actions/trade_zones_areas_Action";
import { getAllStores } from "../../redux/actions/customerAction";
import { tradeZoneWiseStoresList } from "../../redux/actions/storeAction";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();
toast.configure();

class Map extends Component<{
  handleGeoCodeAddress: (address: any) => {}, handleAddressInput: (address: any) => {}, findLocalStores: (lat: any, lng: any) => {};
}, { lat: any, lng: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
    }
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
  * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
  *
  * @param nextProps
  * @param nextState
  * @return {boolean}
  */
  shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {

    return false;
  }
  onPlaceSelected = (place: any) => {
    if (place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      this.setState({ lat: latValue, lng: lngValue })
      this.props.handleGeoCodeAddress(address);
      // this.props.findLocalStores(latValue, lngValue);
    }
  };
  auto = () => {
    const AsyncMap: any = withScriptjs(
      withGoogleMap((props: any) => (
        <Autocomplete
          style={{
            width: "100%",
            height: "40px",
            marginBottom: "15px",
            paddingLeft: "16px",
            marginTop: "2px",
          }}
          onPlaceSelected={this.onPlaceSelected}
          types={["address"]}
          componentRestrictions={{ country: "pk" }}
        />
      ))
    );
    let map;
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "0px" }} />}
        containerElement={<div style={{ height: this.props.height }} />}
        mapElement={<div style={{ height: "0px" }} />}
      />
    );
    return map;
  };
  render() {
    return (
      <div>
        {this.auto()}
      </div>
    )
  }
}

class DriverEditor extends React.Component<
  { data: any; row: any },
  { [x: number]: any; deliveryboy: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      deliveryboy: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props;
    this.setState({ deliveryboy: row.delivery_boy });
  }
  handleSave = () => {  
    const { row } = this.props;
    const { deliveryboy } = this.state;
    const data = {
      delivery_boy: deliveryboy
    };
    this.props.data.updateOrder(row.order_id, data);
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  render() {
    const { data, row } = this.props;
    const { deliveryboy } = this.state;
    return (
      <div>
        {row.order_status_code === 4 || row.order_status_code === 5 ? (
          <p>{row.delivery_boy}</p>
        ) : (
          <div>
            <select
              name="deliveryboy"
              value={deliveryboy || ""}
              onChange={this.handleInputChange}
              className="text-capitalize"
            >
              <option value="">Select Driver</option>
              {data.drivers &&
                data.drivers.map((driver: any, index: any) => (
                  <option key={index} value={driver.user_name}>
                    {driver.user_name}
                  </option>
                ))}
            </select>
            <br />
            <span
              className="badge badge-pill badge-info"
              style={{ cursor: "pointer" }}
              onClick={this.handleSave}
            >
              save
            </span>
          </div>
        )}
      </div>
    );
  }
}
class StatusEditor extends React.Component<
  { data: any; row: any },
  { [x: number]: any; orderstatus: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      orderstatus: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props;
    this.setState({ orderstatus: row.order_status_code });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  render() {
    const { data, row } = this.props;
    const { orderstatus } = this.state;
    return (
      <div>
        {row.order_status_code === 4 || row.order_status_code === 5 ? (
          <span
            {...((row.order_status_code === 4 && {
              className: "badge badge-success text-capitalize p-1"
            }) ||
              (row.order_status_code === 5 && {
                className: "badge badge-danger text-capitalize p-1"
              }))}
          >
            {row.order_status_description}
          </span>
        ) : (
          <div>
            <select
              name="orderstatus"
              value={orderstatus}
              onChange={this.handleInputChange}
              className="text-capitalize"
            >
              {data.statusList &&
                data.statusList.map((status: any, index: any) => (
                  <option key={index} value={status.order_status_code}>
                    {status.order_status_description}
                  </option>
                ))}
            </select>
            <br />
            <span
              className="badge badge-pill badge-info text-capitalize"
              style={{ cursor: "pointer" }}
            >
              save
            </span>
          </div>
        )}
      </div>
    );
  }
}
const divStyle = {
  margin: "0px",
  padding: "15px"
};

class ActionFormatter extends Component<
  { row: any },
  { selectedOrderId: any; tax: any }
> {
  constructor(readonly props: any) {
    super(props);

    this.state = {
      selectedOrderId: "",
      tax: ""
    };
  }

  showorderItems = (id: any) => {
    let storeId: any = "";
    let stateId: any = "";
    let tax: any = "";
    this.props.data.showorderItems(id);
    storeId = this.props.data.orders.find((obj: any) => {
      return obj.order_id == id;
    }).store_id;
    if (storeId && storeId !== "") {
      stateId = this.props.data.stores.find((obj: any) => {
        return obj.store_id == storeId;
      }).state_id;
    }
    if (stateId !== "") {
      tax = this.props.data.states.find((obj: any) => {
        return obj.state_id == stateId;
      }).tax_percent;
    }
    this.setState({ selectedOrderId: id, tax: tax });
  };
  render() {
    const { row, data } = this.props;
    return (
      <div>
        <button
          title="View Order Items"
          data-toggle="modal"
          data-target={`#viewitems${row.order_id}`}
          className="btn btn-outline-info"
          onClick={() => this.showorderItems(row.order_id)}
        >
          <i className="fa fa-list"></i>
        </button>
        <button
          title="View Invoice"
          data-toggle="modal"
          data-target={`#viewinvoice${row.order_id}`}
          className="btn btn-outline-info ml-2"
          onClick={() => this.showorderItems(row.order_id)}
        >
          <i className="fa fa-file-pdf-o"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`viewitems${row.order_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Order Items
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="row" style={divStyle}>
                <div className="col-6 to">
                  <h3>Customer Information</h3>
                  <span className="d-block font-weight-light">
                    Customer Name:{" "}
                  </span>
                  <span className="d-block font-weight-light">
                    Phone Number:{" "}
                  </span>
                  <span className="d-block font-weight-light">
                    Email Address:{" "}
                  </span>
                  {data.Items.length > 0
                    ? data.Items[0].address_line_1 && (
                      <span className="d-block font-weight-light">
                        Address 1:{" "}
                      </span>
                    )
                    : ""}
                </div>
                <div className="col-6 to">
                  <h3>
                    <br />
                  </h3>
                  <span className="d-block font-weight-light">
                    {data.Items.length > 0 && data.Items[0].first_name}{" "}
                    {data.Items.length > 0 && data.Items[0].last_name}
                  </span>
                  <span className="d-block font-weight-light">
                    {data.Items.length > 0 && data.Items[0].phone_number}
                  </span>
                  <span className="d-block font-weight-light">
                    {data.Items.length > 0 && data.Items[0].email_address}
                  </span>
                  <span className="d-block font-weight-light">
                    {data.Items.length > 0 && data.Items[0].address_line_1}
                  </span>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      {data.Items.length > 0 ? (
                        <BootstrapTable data={data.Items} hover>
                          <TableHeaderColumn
                            dataField="order_item_id"
                            dataAlign="center"
                            width="80"
                            columnTitle
                            isKey
                          >
                            Order Item Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_item_quantity"
                            width="100"
                            columnTitle
                          >
                            Quantity
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="item_name"
                            width="150"
                            columnTitle
                          >
                            Item Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="combo_name"
                            width="150"
                            columnTitle
                          >
                            Combo Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_item_grossprice"
                            width="60"
                            columnTitle
                          >
                            Amount
                          </TableHeaderColumn>
                        </BootstrapTable>
                      ) : (
                        <p className="text-center">Order Items Not Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-warning"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Invoice Modal */}
        <div
          id={`viewinvoice${row.order_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Invoice
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function priceFormatter(cell: any) {
  return `PKR ${cell}`;
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}

class Menu extends Component<MenuProps, MenuState> {
  cartTotal: number;
  itemTotal: number;
  totalTax: any;
  discount: number;
  quantity: number;
  couponDiscount: number;
  validTimeFlag: any;
  optionValue: any;
  selectRef: any;
  selectRef2: any;
  selectRef3: any;
  codeExeCount: number;

  constructor(readonly props: any) {
    super(props);
    this.state = {
      emptyCart: false,
      selectedsize: {},
      changeLocation: true,
      size: [],
      itemPrice: "",
      googlesearch: '',
      brand_id: "",
      placesSuggestion: [],
      actualPrice: "",
      netPrice: "",
      initialPrice: "",
      modifiers: {},
      comboDetails: {},
      phone: "",
      isValidPhone: "",
      orderType: "Delivery",
      active_group_id: "",
      showCutomerPanel: true,
      fullAddress: "",
      lat: 0,
      lng: 0,
      prevModPrice: 0,
      prevMod: "",
      prevIndex: 0,
      prevKey: '',
      discountFlag: false,
      storeOpenFlag: true,
      promiseTime: 0,
      coupanCode: '',
      backupStorePromiseTime: 0,
      laterdatetime: new Date(),
      comboQuantityReached: true,
      open: false,
      validTime: false,
      netWork: false,
      isClicked: false,
      isPickupClicked: true,
      isPickUpSubmitClicked: false,
      area: '',
      building: "",
      room: "",
      paymentMethod: "Cash",
      city: '',
      store: '',            // User input
      selectedStore: '',    // Selected store
      suggestions: [],      // Matching store suggestions
      showSuggestions: false,
      cityClicked: false,
      selectedStores: [],
      SelectedBrand: '',
      pickupCity: '',
      delivery_zone_id: [],
    };
    this.handleSaveStore = this.handleSaveStore.bind(this);
    this.handleStoresInputChange = this.handleStoresInputChange.bind(this)
    this.menuItemsListByGroupsID = this.menuItemsListByGroupsID.bind(this);
    this.FormatAddress = this.FormatAddress.bind(this);
    this.pickUpFormatAddress = this.pickUpFormatAddress.bind(this);
    this.handleChangeCoupan = this.handleChangeCoupan.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this)
    this.cartTotal = 0;
    this.itemTotal = 0;
    this.totalTax = 0;
    this.discount = 0;
    this.quantity = 0;
    this.couponDiscount = 0;
    this.validTimeFlag = false;
    this.selectRef = null;
    this.selectRef2 = null;
    // this.selectRef3 = null;
    this.codeExeCount = 0

  }
  async componentDidMount() {
    const { delivery_address } = this.props;    
    if (this.props.selectedAddress) {
      this.setState({ building: this.props.selectedAddress.extra_details })
      this.props.setCityName(this.props.selectedAddress.city_id?.name)
      this.props.getTradeZones(this.props.selectedAddress.city_id?.id)
      this.props.setTradeZoneName(this.props.selectedAddress.zone_id?.delivery_zone_name, this.props.selectedAddress.zone_id?.id)
      this.props.setTradeAreaName(this.props.selectedAddress.area?.area_id.area_name,this.props.selectedAddress.area?.area_id.id)
      setTimeout(() => {
        let areaa: any = []
        this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
          if ((this.props.selectedAddress.zone_id && this.props.selectedAddress.zone_id.id == zone.id)) {
            this.props.stores.map((store: any) => {
              if(this.props.selectedAddress.zone_id.id == store.delivery_zone_id?.id){
                areaa.push(store);
              }
            })
          }
        })
        if(areaa.length > 0){
          this.props.tradeZoneWiseStoresList(areaa);
        }
      }, 500);
    }
    sessionStorage.removeItem("flag")

    this.props.getCart();
    this.props.storesList();
    this.props.brandsList();
    this.props.getTradeAreasByTradeZone(this.props.selectedAddress.zone_id?.id)
    this.props.setStoreByTradeZone()
    this.props.ordersCounter();
    this.renderItemPrice = this.renderItemPrice.bind(this);
    let brand_id: any = sessionStorage.getItem("brand_id")
    if (brand_id) {
      this.setState({ brand_id: brand_id })
    }
    // if(delivery_address !== "" ){
    //   Geocode.fromAddress(delivery_address)
    //   .then((json: any) => {
    //     var location = json.results[0].geometry.location;
    //     this.setState({ lat: location.lat, lng: location.lng })
    //     this.props.findLocalStores(location.lat, location.lng);
    //   })
    //   .catch((error: any) => {
    //   });
    // }
    if (this.props.is_saved_address_selected) {
      this.setState({ changeLocation: false })
    }
    this.props.getCities()
    document.title = "SimplexCRM | Menu";
  }

  componentWillReceiveProps(nextProps: any) {
    if (this.props.callduration === nextProps.callduration) {
      if (!_.isEmpty(nextProps.itemData)) {
        // console.log("2")
        if (nextProps.itemData.menu_item_id) {
          let sizearray = JSON.parse(nextProps.itemData.item_size);
          this.setState({
            size: sizearray,
            selectedsize: sizearray[0],
            itemPrice: (sizearray[0].discount_price && (sizearray[0].order_channel == 'callcenter')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
            actualPrice: Math.round(sizearray[0].mrp),
            modifiers: nextProps.itemData.modifiers
          });
        } else if (nextProps.itemData.combo_name) {
          this.setState({
            itemPrice: (nextProps.itemData.discount_price && (nextProps.itemData.order_channel == 'callcenter')) ? Math.round(nextProps.itemData.discount_price) : Math.round(nextProps.itemData.combo_mrp_price),
            netPrice: (nextProps.itemData.discount_price && (nextProps.itemData.order_channel == 'callcenter')) ? Math.round(nextProps.itemData.discount_price) : Math.round(nextProps.itemData.combo_mrp_price),
            actualPrice: Math.round(nextProps.itemData.combo_sales_price),
            modifiers: nextProps.itemData.modifiers,
            comboDetails: nextProps.itemData.choices
          });
        }
      }
    }
      if(this.state.paymentMethod === 'Card On Delivery'){
        if(this.state.orderType === 'Delivery'){
          this.setState({paymentMethod: 'Card On Delivery'});
        }
        else if(this.state.paymentMethod === 'Card On Pickup'){
          this.setState({paymentMethod: 'Card On Pickup'});
        }
      }
  }

  componentWillUnmount() {
    this.props.saveStore()
    this.props.setCityName('')
    this.props.setTradeAreaName('', null)
    this.props.setTradeZoneName('', null)
    this.props.setHouseNo('')
    this.props.handleAddressInput('')
    this.props.saveCart([])
    sessionStorage.removeItem("cart")
    // this.props.customerDetail({});
  }
  handleBrand = (e: any) => {

    let answer = null;
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(e)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer == true) {
        this.props.saveStore({})
        sessionStorage.removeItem("cart")
        this.props.saveCart([])
        this.props.setStoreByTradeZone()
        this.props.saveStore({})
        this.props.setStoreByCity()
        //this.props.getStoreByCity(filteredCities[0].name)
        // console.log("handle zone is triggered", e)
      }
    }
    else if(e.target.value) {
      this.setState({
        brand_id: e.target.value,
        SelectedBrand: e.target.value,
      })
      sessionStorage.setItem("brand_id", e.target.value)
      this.props.saveBrand(e.target.value)
      this.props.storesList()
      this.props.saveStore()
      this.props.setStoreByTradeZone()
      this.props.saveStore({})
      this.props.setStoreByCity()
      // get the stores list for the brand change
    }
  }


  handleValidTime(event: { target: { name: any; value: any } }) {
    let obj = document as any
    this.props.saveStore({})
    var dropDown: any = obj.getElementById("clearPickupStore");
    var deliveryStore = obj.getElementById("clear")
    let o = ''
    dropDown && dropDown.selectedIndex ? dropDown.selectedIndex = 0 : o = '';
    deliveryStore && deliveryStore.selectedIndex ? deliveryStore.selectedIndex = 0 : o = '';
    this.selectRef && this.selectRef.select.clearValue();
    this.setState({
      [event.target.name]: event.target.value
    });

    let validMinDateTime = new Date();
    validMinDateTime.setHours(validMinDateTime.getHours() + 1);
    let futureDate = moment(event.target.value).startOf('day')
    let currentDate = moment(new Date()).startOf('day');

    if (futureDate.isSame(currentDate)) {
      let futureDatetime = new Date(event.target.value)
      if (futureDatetime >= validMinDateTime) {
        this.validTimeFlag = true;
        this.setState({ validTime: true })
      } else {
        toast.error("Future order can be placed minimum 1 hour ahead of current time");
        this.validTimeFlag = false;
        this.setState({ validTime: false })

      }
    } else {
      this.validTimeFlag = true;
      this.setState({ validTime: true })

    }

  }


  getItemDetail = (obj: any) => {
    if (obj.menu_item_id) {
      this.props.getItemDetail(obj.menu_item_id);
    } else if (obj.combo_name) {
      this.props.getComboDetail(obj.combo_id);
    }
  };

  handleChangeChk = (key: any, modName: any) => {
    //To check/uncheck the modifier checkbox
    let { modifiers, itemPrice, actualPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (item.selected == false) {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: parseInt(itemPrice) + item.modifier_sale_price,
            actualPrice: actualPrice + item.modifier_sale_price
          });
        } else {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: itemPrice - item.modifier_sale_price,
            actualPrice: actualPrice - item.modifier_sale_price
          });
        }
      }
    });
  };

  handleRadio = (key: any, modName: any) => {
    let { modifiers, itemPrice, actualPrice, prevMod, prevModPrice, prevIndex } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (prevMod !== "") {
          if (modName !== prevMod) {
            item.selected = true;
            modifiers[key][prevIndex].selected = false;
            this.setState({
              modifiers: modifiers,
              itemPrice: (parseInt(itemPrice) - prevModPrice) + item.modifier_sale_price,
              actualPrice: (actualPrice - prevModPrice) + item.modifier_sale_price,
              prevMod: modName,
              prevModPrice: item.modifier_sale_price,
              prevIndex: index
            });
          }
        }
        else {
          item.selected = true;
          this.setState({
            modifiers: modifiers,
            itemPrice: (parseInt(itemPrice) - prevModPrice) + item.modifier_sale_price,
            actualPrice: (actualPrice - prevModPrice) + item.modifier_sale_price,
            prevMod: modName,
            prevModPrice: item.modifier_sale_price,
            prevIndex: index
          });
        }
      }
    });
  };

  // decrementChoiceQuantity(key:any, modName:any, limit:any,itemsize:any){
  // 	let { comboDetails,itemPrice } = this.state;
  // 	console.log(limit)
  // 	let maxLimit = parseInt(limit);
  // 	comboDetails[key].map((item: any, index: any) => {
  // 		console.log(item)
  // 		if (item.item_name == modName && JSON.parse(item.size).size == itemsize  && item.quantity > 0) {
  // 			item.quantity -= 1;
  // 			this.setState({
  // 				comboDetails: comboDetails,
  // 				itemPrice: itemPrice - item.extra_price
  // 			});
  // 		}
  // 		else if((item.item_name != modName || item.size != itemsize) && item.quantity < maxLimit){
  // 			item.quantity += 1;
  // 			this.setState({
  // 				comboDetails: comboDetails,
  // 				itemPrice: itemPrice + item.extra_price
  // 			});
  // 		}
  // 	});	
  // }

  // incrementChoiceQuantity(key:any, modName:any, limit:any,itemsize:any){
  // 	let { comboDetails,itemPrice } = this.state;
  // 	console.log(limit)
  // 	let maxLimit = parseInt(limit);
  // 	comboDetails[key].map((item: any, index: any) => {
  // 		if (item.item_name == modName && JSON.parse(item.size).size == itemsize && item.quantity < maxLimit) {
  // 			item.quantity += 1;
  // 			this.setState({
  // 				comboDetails: comboDetails,
  // 				itemPrice: itemPrice + item.extra_price
  // 			});
  // 		}
  // 		else if((item.item_name != modName || JSON.parse(item.size).size != itemsize) && item.quantity > 0){
  // 			item.quantity -= 1;
  // 			this.setState({
  // 				comboDetails: comboDetails,
  // 				itemPrice: itemPrice - item.extra_price
  // 			});
  // 		}
  // 	});	
  // }

  incrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
    let { comboDetails, itemPrice } = this.state;
    let maxLimit = parseInt(limit);
    let reachedLimit: any = 0;
    const found = comboDetails[key].findIndex((element: any) => element.quantity == limit);
    if (found === -1) {
      comboDetails[key].map((item: any, index: any) => {
        reachedLimit += parseInt(item.quantity);
      });
      if (reachedLimit < maxLimit) {
        comboDetails[key].map((item: any, index: any) => {
          if (item.item_name == modName && JSON.parse(item.size).size == itemsize) {
            item.quantity += 1;
            reachedLimit += 1;
            this.setState({
              comboDetails: comboDetails,
              itemPrice: itemPrice + item.extra_price
            });
          }
        });
      }
      else {
        toast.error("Maximum quantity selected for burgers", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
      }
    }
    else if (found >= 0) {
      toast.error("Maximum quantity selected for burgers", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
    }
    if (reachedLimit === maxLimit) {
      this.setState({ comboQuantityReached: true });
    }
    else {
      this.setState({ comboQuantityReached: false });
    }
  }

  decrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
    let { comboDetails, itemPrice } = this.state;
    let reachedLimit: any = 0;
    let maxLimit = parseInt(limit);
    comboDetails[key].map((item: any, index: any) => {
      reachedLimit += parseInt(item.quantity);
    });
    comboDetails[key].map((item: any, index: any) => {
      if (item.item_name == modName && JSON.parse(item.size).size == itemsize && item.quantity > 0) {
        item.quantity -= 1;
        reachedLimit -= 1;
        this.setState({
          comboDetails: comboDetails,
          itemPrice: itemPrice - item.extra_price
        });
      }
      if (reachedLimit === maxLimit) {
        this.setState({ comboQuantityReached: true });
      }
      else {
        this.setState({ comboQuantityReached: false });
      }
    });
  }



  handleChangeCoupan(event: { target: { name: any; value: any } }) {
    this.setState({
      coupanCode: event.target.value
    });
  }

  isCoupanReady = () => {
    let { coupanCode } = this.state;
    let { orderType, cart } = this.props;
    return coupanCode !== '';
  };

  ApplyCoupan = () => {
    let { coupanCode, discountFlag } = this.state;
    let { orderType, cart } = this.props;
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          this.setState({ discountFlag: true })
        }
      }
      else {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true })
        }
      }
    })
    if (coupanCode !== "" && discountFlag == false) {
      this.props.getCart();
      let data = {
        coupon_code: coupanCode,
        order_mode: "call center",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup"
      };

      this.props.applyCoupon(data);
    } else {
      toast.error("Coupon can not be applied on discounted items");
    }

  };


  addVariation = (key: any, variation: any, itemsize: any) => {
    let { itemPrice, prevMod, prevModPrice, prevKey, comboDetails } = this.state;
    let { comboData } = this.props;
    let prevPrice = 0;
    comboDetails[key].map((element: any) => {
      if (element.selected == true) {
        prevPrice = element.extra_price;
      }
      element.selected = false;
    })

    comboDetails[key].map((item: any, index: any) => {
      if (variation == item.item_name && itemsize == JSON.parse(item.size).size) {
        if (prevMod == '') {
          this.setState({
            comboDetails: comboDetails,
            itemPrice: parseInt(itemPrice) + item.extra_price,
            prevMod: variation,
            prevKey: key
          })
          item.selected = true
        }
        else {
          if (prevKey == key) {
            item.selected = true
            this.setState({
              comboDetails: comboDetails,
              itemPrice: parseInt(itemPrice) - prevPrice + item.extra_price,
              prevMod: variation,
              prevKey: key
            })
          }
          else {
            item.selected = true;
            this.setState({
              comboDetails: comboDetails,
              itemPrice: parseInt(itemPrice) - prevPrice + item.extra_price,
              prevMod: variation,
              prevKey: key
            })
            // console.log(itemPrice)
          }
        }
      }
    })
  }

  // addModifier = (key: any, modifier_name: any) => {
  //   let { itemPrice, prevMod, prevModPrice, prevKey, modifiers } = this.state;
  //   let { comboData } = this.props;
  //   let prevPrice = 0;
  //   modifiers[key].map((element: any) => {
  //     if (element.selected == true) {
  //       prevPrice = element.modifier_sale_price;
  //     }
  //     element.selected = false;
  //   })
  //   modifiers[key].map((item: any, index: any) => {
  //     if (modifier_name == item.modifier_name) {
  //       if (prevMod == '') {
  //         item.selected = true
  //         this.setState({
  //           modifiers: modifiers,
  //           itemPrice: parseInt(itemPrice) + item.modifier_sale_price,
  //           prevMod: modifier_name,
  //           prevKey: key
  //         })
  //       }
  //       else {
  //         if (prevKey == key) {
  //           item.selected = true;
  //           this.setState({
  //             modifiers: modifiers,
  //             itemPrice: parseInt(itemPrice) - prevPrice + item.modifier_sale_price,
  //             prevMod: modifier_name,
  //             prevKey: key
  //           })
  //         }
  //         else {
  //           item.selected = true;
  //           this.setState({
  //             modifiers: modifiers,
  //             itemPrice: parseInt(itemPrice) - prevPrice + item.modifier_sale_price,
  //             prevMod: modifier_name,
  //             prevKey: key
  //           })
  //         }
  //       }
  //     }
  //   })
  // }

  addModifier = (key: any, modifier_name: any) => {
    let { itemPrice, prevMod, prevModPrice, prevKey, modifiers } = this.state;
    let prevPrice = 0;
    let max_quantity = 0;
    let allowed_quantity = 0;
    modifiers[key].map((element: any) => {
      if (element.selected == true) {
        prevPrice = element.modifier_sale_price;
        max_quantity = element.quantity;
        allowed_quantity = element.max;
      }
      if (element.modifier_name !== modifier_name) {
        element.selected = false;
      }
    })
    if (max_quantity < allowed_quantity) {
      modifiers[key].map((item: any, index: any) => {
        if (modifier_name == item.modifier_name) {
          if (prevMod == '') {
            this.setState({
              itemPrice: itemPrice + item.modifier_sale_price,
              prevMod: modifier_name,
              prevKey: key
            })
            item.selected = true
          }
          else {
            if (prevKey == key) {
              item.selected = !item.selected;
              if (prevMod === modifier_name) {
                if (item.selected == true) {
                  this.setState({
                    itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                    prevMod: modifier_name,
                    prevKey: key
                  })
                }
                else {
                  this.setState({
                    itemPrice: itemPrice - prevPrice,
                    prevMod: modifier_name,
                    prevKey: key
                  })
                }
              }
              else {
                this.setState({
                  itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                  prevMod: modifier_name,
                  prevKey: key
                })
              }
            }
            else {
              item.selected = true;
              this.setState({
                itemPrice: itemPrice - prevPrice + item.modifier_sale_price,
                prevMod: modifier_name,
                prevKey: key
              })
            }
          }
        }
      })
    }
  }

  handleCheck = (key: any, modName: any) => {
    let { modifiers, itemPrice, actualPrice } = this.state;
    let max_quantity = 0;
    let allowed_quantity = 0;
    modifiers[key].map((element: any) => {
      if (element.selected == true) {
        max_quantity += element.quantity;
      }
      else {
        allowed_quantity = element.modGroup_max_quantity;
      }
    })
    modifiers[key].map((item: any, index: any) => {
      // console.log(item,modName)
      if (item.modifier_name == modName) {
        if (item.selected == false) {
          if (max_quantity < allowed_quantity) {

            item.selected = !item.selected;
            this.setState({
              modifiers: modifiers,
              itemPrice: parseFloat(itemPrice) + parseFloat(item.modifier_sale_price) * parseFloat(item.quantity),
              actualPrice: parseFloat(actualPrice).toFixed(2) + parseFloat(item.modifier_sale_price).toFixed(2),
            });
          }
        } else {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: itemPrice - item.modifier_sale_price * item.quantity,
            actualPrice: actualPrice - item.modifier_sale_price
          });
        }
      }
    });

  };

  handleCheckRadio = (key: any, modName: any) => {
    let { modifiers, itemPrice, actualPrice } = this.state;
    let prevPrice = "0.00";
    modifiers[key].map((element: any) => {
      if (element.selected == true) {
        prevPrice = parseFloat(element.modifier_sale_price).toFixed(2);
      }
      element.selected = false;
    })
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (item.selected == false) {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: (parseFloat(itemPrice) + parseFloat(item.modifier_sale_price) - parseFloat(prevPrice) * parseFloat(item.quantity)),
            actualPrice: parseFloat(actualPrice) + parseFloat(item.modifier_sale_price) - parseFloat(prevPrice)
          });
        } else {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: parseFloat(itemPrice) - parseFloat(prevPrice) * parseFloat(item.quantity),
            actualPrice: parseFloat(actualPrice) - parseFloat(prevPrice)
          });
        }
      }
    });

  };

  renderItemPrice(e: any) {
    let item = JSON.parse(e.target.value);
    if (item.discount_price && (item.order_channel == 'callcenter')) {
      this.setState({
        selectedsize: item,
        itemPrice: item.discount_price,
        actualPrice: item.mrp,
        netPrice: item.mrp
      });
    }
    else {
      this.setState({
        selectedsize: item,
        itemPrice: item.mrp,
        actualPrice: item.mrp,
        netPrice: item.mrp
      });
    }
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map(key => {
        this.state.modifiers[key].map((item: any, index: any) => {
          item.selected = false;
          this.setState({
            modifiers: this.state.modifiers
          });
        });
      });
    }
  }

  changeQuantity = (value: any, currentIndex: any) => {
    let Cart: any = this.props.cart;
    let dataCart = JSON.parse(Cart);
    let price = parseFloat(dataCart[currentIndex].price);
    let actualPrice = parseFloat(dataCart[currentIndex].actualPrice);
    dataCart.map((obj: any, index: any) => {
      if (currentIndex == index) {
        if (value) {
          obj.totalItemPrice = value * price;
          obj.subTotal = value * actualPrice;
          obj.quantity = value;
        } else {
          dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
          this.cartTotal = 0;
        }
      }
    });
    this.props.saveCart(dataCart);
    setTimeout(() => {
      this.props.getCart();
    }, 500);
  };

  // decrementQuantity(key: any, modName: any) {
  //   let { modifiers, itemPrice, actualPrice } = this.state;
  //   modifiers[key].map((item: any, index: any) => {
  //     if (item.modifier_name == modName) {
  //       if (item.quantity == 1) {
  //         return;
  //       }
  //       else {
  //         item.quantity -= 1;
  //         this.setState({
  //           modifiers: modifiers,
  //           itemPrice: parseInt(itemPrice) - item.modifier_sale_price,
  //           actualPrice: actualPrice - item.modifier_sale_price
  //         });
  //       }
  //     }
  //   });
  // }
  decrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice, actualPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (item.quantity == 1) {
          return;
        }
        else {
          item.quantity -= 1;
          this.setState({
            modifiers: modifiers,
            itemPrice: itemPrice - item.modifier_sale_price,
            actualPrice: actualPrice - item.modifier_sale_price
          });
        }
      }
    });
  }
  // incrementQuantity(key: any, modName: any) {
  //   let { modifiers, itemPrice, actualPrice } = this.state;
  //   modifiers[key].map((item: any, index: any) => {
  //     if (item.modifier_name == modName) {
  //       item.quantity += 1;
  //       this.setState({
  //         modifiers: modifiers,
  //         itemPrice: parseInt(itemPrice) + item.modifier_sale_price,
  //         actualPrice: actualPrice + item.modifier_sale_price
  //       });
  //     }
  //   });
  // }
  incrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice, actualPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (item.quantity < item.max) {
          item.quantity += 1;
          this.setState({
            modifiers: modifiers,
            itemPrice: itemPrice + item.modifier_sale_price,
            actualPrice: actualPrice + item.modifier_sale_price
          });
        } else {
          // toast.warn(`${i18next.t('common.max_selected')} ${(lang == 'ar' && item.modifier_name_ar) ? item.modifier_name_ar : modName}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
        }
      }
    });
  }

  decrementCartQuantity(currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let price: any = dataCart[currentIndex].selectedsize.discount_price ? parseFloat(dataCart[currentIndex].selectedsize.discount_price) : parseFloat(dataCart[currentIndex].selectedsize.mrp);
      let actualPrice = parseFloat(dataCart[currentIndex].actualPrice);
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
          }
          obj.totalItemPrice = parseFloat(obj.totalItemPrice) - parseFloat(price);
          obj.subTotal -= actualPrice;
          obj.quantity -= 1;
          const modifiers = obj?.item?.modifiers;
          if (modifiers) {
              Object.values(modifiers).forEach(value => {
                  if(Array.isArray(value)){
                    value.map((v)=>{
                      if(v.selected){
                        v.quantity -= 1
                        obj.totalItemPrice = parseFloat(obj.totalItemPrice) - Number(v.modifier_sale_price);                        
                      }
                    })
                  }
              }); 
          }
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
    else {
      let price = dataCart[currentIndex].combo.discount_price ? dataCart[currentIndex].combo.discount_price : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      let actualPrice = dataCart[currentIndex].actualPrice;
      let comboDetails = dataCart[currentIndex].combo;
      if (typeof comboDetails.choices == 'object') {
        Object.keys(comboDetails.choices).map((key: any) => {
          comboDetails.choices[key].map((choice: any) => {
            if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
              extra_price += choice.extra_price * choice.quantity;
            }
          })
        })
      }
      else if (typeof comboDetails.choices == 'string') {
        let choicesData: any = [];
        choicesData = JSON.parse(comboDetails.choices);
        choicesData.map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
            extra_price += choice.extra_price * choice.quantity;
          }
        })
      }
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
          }
          obj.totalItemPrice = obj.totalItemPrice - price - extra_price;
          obj.subTotal -= actualPrice;
          obj.quantity -= 1;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
  }

  incrementCartQuantity(currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let price = dataCart[currentIndex].selectedsize.discount_price ? parseFloat(dataCart[currentIndex].selectedsize.discount_price) : parseFloat(dataCart[currentIndex].selectedsize.mrp);
      let actualPrice = parseFloat(dataCart[currentIndex].actualPrice);
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          obj.totalItemPrice = parseFloat(obj.totalItemPrice) + price;
          obj.subTotal += actualPrice;
          obj.quantity += 1;
          const modifiers = obj?.item?.modifiers;
          if (modifiers) {
              Object.values(modifiers).forEach(value => {
                  if(Array.isArray(value)){
                    value.map((v)=>{
                      if(v.selected){
                        v.quantity += 1                        
                        obj.totalItemPrice = parseFloat(obj.totalItemPrice) + Number(v.modifier_sale_price);
                      }
                    })
                  }
              }); 
          }
        } else {
          //dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
          this.cartTotal = 0;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
    else {
      let price = dataCart[currentIndex].combo.discount_price ? dataCart[currentIndex].combo.discount_price : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      let actualPrice = dataCart[currentIndex].actualPrice;
      let comboDetails = dataCart[currentIndex].combo;
      if (typeof comboDetails.choices == 'object') {
        Object.keys(comboDetails.choices).map((key: any) => {
          comboDetails.choices[key].map((choice: any) => {
            if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
              extra_price += choice.extra_price * choice.quantity;
            }
          })
        })
        dataCart.map((obj: any, index: any) => {
          if (currentIndex == index) {
            obj.totalItemPrice = obj.totalItemPrice + price + extra_price;
            obj.subTotal += actualPrice;
            obj.quantity += 1;
          } else {
            //dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
          }
        });
      }
      else if (typeof comboDetails.choices == 'string') {
        let choicesData: any = [];
        choicesData = JSON.parse(comboDetails.choices);
        choicesData.map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
            extra_price += choice.extra_price * choice.quantity;
          }
        })
        dataCart.map((obj: any, index: any) => {
          if (currentIndex == index) {
            obj.totalItemPrice = obj.totalItemPrice + price + extra_price;
            obj.subTotal += actualPrice;
            obj.quantity += 1;
          } else {
            //dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
          }
        });
      }

      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
  }


  calcSubTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };

  // calcTotal = (data: any) => {
  //   let { deliveryfee, coupon } = this.props;
  //   this.couponDiscount = 0;
  //   this.discount = 0
  //   let sum: any = 0;
  //   // let Coupon: string = coupon && coupon.channel;
  //   data.forEach((item: any) => {
  //     sum += parseInt(item.totalItemPrice);
  //   });
  //   let discountValue: any = 0;
  //   if (!_.isEmpty(coupon)) {
  //     // if (
  //     //   Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
  //     //   this.props.orderType
  //     // ) {
  //     if (coupon.type == 'menu') {
  //       if (coupon.coupon_value !== 0) {
  //         this.couponDiscount = coupon.coupon_value;
  //         discountValue += coupon.coupon_value;
  //       } else {
  //         discountValue = (sum * coupon.percent) / 100;
  //         this.couponDiscount += discountValue;
  //       }
  //     }
  //     else if (coupon.type == 'group') {
  //       data.forEach((item: any, index: any) => {
  //         if (item.combo) {
  //           if (item.combo.group_id == coupon.type_id) {
  //             if (coupon.coupon_value !== 0) {
  //               discountValue = discountValue + coupon.coupon_value * item.quantity;
  //               this.couponDiscount += discountValue;
  //               item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
  //             }
  //             else {
  //               discountValue = (item.totalItemPrice * coupon.percent) / 100;
  //               this.couponDiscount += discountValue;
  //               item.totalItemPrice = item.totalItemPrice - discountValue;
  //             }
  //           }
  //         }
  //         if (item.item) {
  //           if (item.item.item_group_id == coupon.type_id || item.item.group_id == coupon.type_id) {
  //             if (coupon.coupon_value !== 0) {
  //               discountValue = discountValue + coupon.coupon_value * item.quantity;
  //               this.couponDiscount += discountValue;
  //               item.totalItemPrice = item.totalItemPrice - discountValue;
  //             }
  //             else {
  //               discountValue = (item.totalItemPrice * coupon.percent) / 100;
  //               this.couponDiscount += discountValue;
  //               item.totalItemPrice = item.totalItemPrice - discountValue;
  //             }
  //           }
  //         }
  //       });
  //     }
  //     else if (coupon.type == 'item') {
  //       let CouponitemsArr = JSON.parse(coupon.items_json)
  //       data.forEach((item: any, index: any) => {
  //         CouponitemsArr.map((coupon_items: any) => {
  //           if (item.combo && coupon_items.combo_id) {
  //             if (item.combo.combo_id == coupon_items.combo_id) {
  //               if (coupon.coupon_value !== 0) {
  //                 discountValue = discountValue + coupon.coupon_value * item.quantity;
  //                 this.couponDiscount += discountValue;
  //                 item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
  //               }
  //               else {
  //                 discountValue = (item.totalItemPrice * coupon.percent) / 100;
  //                 this.couponDiscount += discountValue;
  //                 item.totalItemPrice = item.totalItemPrice - discountValue;
  //               }
  //             }
  //           }
  //           if (item.item && coupon_items.menu_item_id) {
  //             if (item.item.menu_item_id == coupon_items.menu_item_id) {
  //               if (coupon.coupon_value !== 0) {
  //                 discountValue = discountValue + coupon.coupon_value * item.quantity;
  //                 this.couponDiscount += discountValue;
  //                 item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
  //               }
  //               else {
  //                 discountValue = (item.totalItemPrice * coupon.percent) / 100;
  //                 this.couponDiscount += discountValue;
  //                 item.totalItemPrice = item.totalItemPrice - discountValue;
  //               }
  //             }
  //           }
  //         })
  //       });
  //     }
  //   }
  //   else {
  //     this.discount = 0;
  //     this.couponDiscount = 0;
  //     this.cartTotal = 0;
  //   }
  //   if (this.state.orderType === "Delivery") {
  //     sum = sum - this.couponDiscount + deliveryfee;
  //   } else if (this.state.orderType === "Pickup") {
  //     sum = sum - this.couponDiscount;
  //   }
  //   this.props.SaveDiscountValue(this.couponDiscount);
  //   this.cartTotal = sum;
  //   this.props.SaveCartTotal(this.cartTotal);
  //   this.props.SaveTaxValue(this.totalTax)
  //   console.log("sum before",sum)
  //   if(this.props.taxDataCash.tax_type == 0) {
  //     sum = parseFloat(sum);
  //   }

  //   if(this.props.taxDataCash.tax_type == 1) {
  //     sum = parseFloat(sum) + this.totalTax;
  //   }
  //   console.log("sum after",sum)
  //   return sum;
  // };

  calcTotal = (data: any) => {
    // console.log("this-props-data", this.state.orderType);
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    // let Coupon: string = coupon && coupon.channel;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    let discountValue: any = 0;
    if (!_.isEmpty(coupon)) {
      // if (
      //   Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
      //   this.props.orderType
      // ) {
      if (sum > coupon.min_amount) {
        if (coupon.free_delivery === 0) {
          if (coupon.type == "menu") {
            if (coupon.coupon_value !== 0) {
              this.couponDiscount = coupon.coupon_value;
              discountValue += coupon.coupon_value;
            } else {
              discountValue = (sum * coupon.percent) / 100;
              this.couponDiscount = discountValue;
            }
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "group") {
            let CouponGroupsArr = JSON.parse(coupon.groups_json);
            data.forEach((item: any, index: any) => {
              CouponGroupsArr.map((coupon_groups: any) => {
                if (item.combo) {
                  if (item.combo.group_id.group_id == coupon_groups.value) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
                if (item.item) {
                  // console.log("group coupon array here", CouponGroupsArr)
                  if (
                    item.item.item_group_id.group_id == coupon_groups.value ||
                    item.item.group_id == coupon_groups.value
                  ) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value;
                      this.couponDiscount = discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
              });
            });
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "item") {
            let CouponitemsArr = JSON.parse(coupon.items_json);
            data.forEach((item: any, index: any) => {
              CouponitemsArr.map((coupon_items: any) => {
                if (item.combo && coupon_items.combo_id) {
                  if (item.combo.combo_id == coupon_items.combo_id) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value * item.quantity;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
                if (item.item && coupon_items.menu_item_id) {
                  if (item.item.menu_item_id == coupon_items.menu_item_id) {
                    if (coupon.coupon_value !== 0) {
                      discountValue = coupon.coupon_value * item.quantity;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                    } else {
                      discountValue = (item.totalItemPrice * coupon.percent) / 100;
                      this.couponDiscount += discountValue;
                      // item.totalItemPrice = item.totalItemPrice - discountValue;
                    }
                  }
                }
              });
            });
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          } else if (coupon.type == "store") {
            if (coupon.coupon_value !== 0) {
              this.couponDiscount = coupon.coupon_value;
              discountValue += coupon.coupon_value;
            } else {
              discountValue = (sum * coupon.percent) / 100;
              this.couponDiscount += discountValue;
            }
            if (this.couponDiscount === 0 && this.codeExeCount == 0) {
              toast.error("Coupon can not be applied on these items")
              this.props.resetCoupon({});
              this.codeExeCount = 1
            }
          }
        } else {
          this.props.saveDeliveryFee(0)
        }
      }
      else {
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on cart value below " + coupon.min_amount)
          this.props.resetCoupon({});
          this.codeExeCount = 1
        }
      }
    } else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (this.couponDiscount > sum) {
      this.couponDiscount = sum;
      sum = 0;
    }
    if (this.state.orderType == "Delivery") {
      sum = this.couponDiscount > sum ? 0 + parseFloat(deliveryfee) : sum - this.couponDiscount + parseFloat(deliveryfee);
      // sum = sum - this.couponDiscount + parseFloat(deliveryfee);
    } else if (this.state.orderType == "Pickup") {
      // console.log("cart total for pickup case", this.cartTotal)
      sum = this.couponDiscount > sum ? 0 : sum - this.couponDiscount;
      // sum = sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = sum;
    if (this.state.paymentMethod == "Cash") {
      if (this.props.taxDataCash.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    } else if (this.state.paymentMethod == 'Card On Delivery' || this.state.paymentMethod == 'Card On Pickup') {
      if (this.props.taxDataCard.tax_type == 1) {
        sum = parseFloat(sum) + parseFloat(this.totalTax);
        // sum = parseInt(sum);
      } else {
        sum = parseFloat(sum);
      }
    }
    return sum;
  };



  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * item.combo.combo_mrp_price - item.combo.discount_price;
        }
      } else {
        if (item.selectedsize.discount_price) {
          discount += item.quantity * item.selectedsize.mrp - item.selectedsize.discount_price;
        }
      }
    })
    return discount;
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  GotoHome = () => {
    window.location.href = "/customerDetail";
  }

  // calcTotalTax = (cart_total: any) => {
  //   let totalTax = 0;
  //   totalTax = cart_total * this.props.taxDataCash.tax_percent / 100;
  //   this.totalTax = totalTax;
  //   this.props.SaveTaxValue(this.totalTax)
  //   return totalTax;
  // }

  // calcTotalTax = (cart_total: any) => {
  //   console.log("cart_total")
  //   let cart:any = sessionStorage.getItem("cart")
  //   let parsedCart:any = cart ? JSON.parse(cart) : null
  //   let total:any = 0
  //   parsedCart && parsedCart.map((cartitem:any)=>{
  //     total+=cartitem.totalItemPrice 
  //   })
  //   cart_total = total
  //   let totalTax = 0;
  //   let tax_rate = this.props.taxDataCash.tax_percent
  //   if (this.props.taxDataCash.tax_type == 1) {
  //     totalTax = cart_total * this.props.taxDataCash.tax_percent / 100;
  //     this.totalTax = totalTax;
  //   }  

  //   if(this.props.taxDataCash.tax_type == 0) {
  //     totalTax = ((cart_total - this.props.deliveryfee) * (parseInt(tax_rate) / (100 + parseInt(tax_rate))))
  //     this.totalTax = totalTax;
  //   }
  //   this.props.SaveTaxValue(this.totalTax)
  //   return totalTax;
  // }

  calcTotalTax = (taxAmount: any) => {
    let totalTax = 0;
    let hundredVal = '100';
    if (this.state.paymentMethod == 'Cash') {
      if (this.props.taxDataCash.tax_type == 0) {
        let tax_rate = this.props.taxDataCash.tax_percent;
        const totalWithoutTax = (this.itemTotal) * 100/((parseInt(tax_rate)) +(parseInt(hundredVal)));
        totalTax =this.itemTotal - totalWithoutTax
        this.totalTax = totalTax.toFixed(2);
      }
      else {
        totalTax = (this.itemTotal) * (this.props.taxDataCash.tax_percent / 100);
        this.totalTax = totalTax.toFixed(2);
      }
    }
    else if (this.state.paymentMethod == 'Card On Delivery' || this.state.paymentMethod == 'Card On Pickup') {
      // console.log("reached-here999999", this.props.taxDataCard.tax_percent);
      if (this.props.taxDataCard.tax_type == 0) {
        // console.log("reached-here999", this.props.taxDataCard.tax_type );
        let tax_rate = this.props.taxDataCard.tax_percent;
        // console.log("TotalTaxOnCard", tax_rate );
        // tax for subtotal
        totalTax = (this.itemTotal) * ((parseInt(tax_rate)) / (parseInt(hundredVal)));
        // totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (parseInt(hundredVal)));
        // totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        this.totalTax = totalTax.toFixed(2);
      }
      else if (this.props.taxDataCard.tax_percent == undefined) {
        this.totalTax = "0.00";
      }
      else {
        totalTax = (this.itemTotal) * (this.props.taxDataCard.tax_percent / 100);
        // totalTax = (this.cartTotal) * (this.props.taxDataCard.tax_percent / 100);
        this.totalTax = totalTax.toFixed(2);
      }
    }
    else if (this.props.paymentMethod == 'COD' || this.props.paymentMethod === 'COP') {
      if (this.props.taxDataCard.tax_type == 0) {
        let tax_rate = this.props.taxDataCard.tax_percent;
        // totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
        // for subtotal item
        totalTax = (this.itemTotal) * ((parseInt(tax_rate)) / (parseInt(hundredVal)));
        // totalTax = (this.cartTotal) * ((parseInt(tax_rate)) / (parseInt(hundredVal)));
        this.totalTax = totalTax.toFixed(2);
      }
      else {
         totalTax = (this.itemTotal) * (this.props.taxDataCard.tax_percent / 100);
        // totalTax = (this.cartTotal) * (this.props.taxDataCard.tax_percent / 100);
        this.totalTax = totalTax.toFixed(2);
      }
    }
    // console.log("totalTax==>", totalTax )
    this.props.SaveTaxValue(this.totalTax)
    return totalTax;
  }
  // calcTotalTax = (taxAmount: any) => {
  //   let totalTax = 0;
  //   const { paymentMethod, taxDataCash, taxDataCard } = this.props;
  
  //   if (paymentMethod === 'Cash' || paymentMethod === 'Card' || paymentMethod === 'COD' || paymentMethod === 'COP') {
  //     const selectedTaxData = paymentMethod === 'Cash' ? taxDataCash : taxDataCard;
      
  //     if (selectedTaxData.tax_type === 0) {
  //       console.log("selectedTotalTax::", selectedTaxData);
  //       const tax_rate = selectedTaxData.tax_percent;
  //       console.log("taxRate::", tax_rate);
  //       totalTax = (taxAmount) * ((parseInt(tax_rate)) / (100 + parseInt(tax_rate)));
  //     } else {
  //       totalTax = taxAmount * selectedTaxData.tax_percent / 100;
  //     }
      
  //     this.totalTax = totalTax.toFixed(2);
  //     this.props.SaveTaxValue(this.totalTax);
  //   }
  
  //   return totalTax;
  // }
  

  calcTotalDiscount = (discount: any) => {
    return discount;
  }

  cartItem = (data: any) => {
    let { deliveryfee, tax, coupon, open, order_id, selectedStore } = this.props;
    if (!_.isEmpty(data)) {
      let cart: any[] = JSON.parse(data);
      if (cart.length > 0) {
        return (
          <div className="daily-feeds card">
            <div className="card-header">
              <h3 className="h4">Cart</h3>
              <div className="d-flex justify-content-between pt-4">
                <button
                  className="btn btn-xs btn-warning"
                  disabled={this.state.netWork}
                  onClick={() => this.ValidateOrder()}
                >
                  Confirm
                </button>
                <button
                  className="btn btn-xs btn-warning"
                  onClick={() => {
                    this.props.clearCart();
                  }}
                >
                  Clear
                </button>
              </div>

            </div>
            <div className="card-body no-padding">
              {cart.map((carts, indexes) => (
                <div className="item">
                  <div className="feed d-flex justify-content-between">
                    <div className="feed-body d-flex justify-content-between">
                      {/* <a href="#" className="feed-profile">
                      {carts.item ? (
                                  <img
                                    style={{ width: "50px", height: "50px" }}
                                    src={`${BASE_URL}${
                                      JSON.parse(carts.item.item_size)[0].image_url
                                    }`}
                                  />
                                ) : (
                                  [
                                    carts.image ? (
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        src={`${BASE_URL}${carts.image}`}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/optp.png"
                                        }
                                        alt="product"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        className="img-fluid backgroud"
                                      />
                                    ),
                                  ]
                                )}

                      </a> */}
                      <div className="content">
                        <h5>
                          {(!_.isEmpty(carts.item) &&
                            carts.item.item_name) ||
                            (!_.isEmpty(carts.combo) &&
                              carts.combo.combo_name)}{" x "}{carts.quantity}
                          {carts.selectedsize &&
                            `(${carts.selectedsize.size})`}
                        </h5>

                        {(!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) && typeof carts.combo.choices == "string" ?
                          JSON.parse(carts.combo.choices).map((item: any) => {
                            return (
                              <div>
                                {item.size ?
                                  <>
                                    <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}
                                    </span>
                                  </>
                                  :
                                  <span>{item.item_name + ' x ' + item.quantity}</span>
                                }
                              </div>
                            )
                          })

                          :
                          (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) && typeof carts.combo.choices == 'object' &&
                          // itemData === null && undefined &&
                          Object.keys(carts.combo.choices).map(
                            (key: any, index) => {
                              return (
                                carts.combo.choices[key].map(
                                  (item: any, index: any) => {
                                    return (
                                      item.selected === true && item.quantity > 0 &&
                                      <div>
                                        {item.size ?
                                          <>
                                            <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}
                                            </span>
                                          </>
                                          :
                                          <span>{item.item_name + ' x ' + item.quantity}</span>
                                        }
                                      </div>
                                    )
                                  })
                              );
                            }
                          )
                        }
                        {
                          (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) && typeof carts.combo.modifiers == "string" ?
                            JSON.parse(carts.combo.modifiers).map((item: any) => {
                              return (
                                <div>

                                  <span>
                                    {
                                      item.modifier_name + ' x ' + item.quantity
                                    }
                                  </span>
                                  {/* <td className="col-4">
                                      {item.modifier_sale_price*item.quantity}{' PKR'}
                                    </td>
                                    <td className="col-4">
                                    </td> */}

                                </div>

                              )
                            }) :
                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                            // itemData === null && undefined &&
                            Object.keys(carts.combo.modifiers).map(
                              (key: any, index) => {
                                return (
                                  carts.combo.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true &&
                                        // item.modifier_name + ' x ' + item.quantity + " , "
                                        <div>

                                          <span>
                                            {
                                              item.modifier_name + ' x ' + item.quantity
                                            }
                                          </span>
                                          {/* <td className="col-4">
                                                {item.modifier_sale_price*item.quantity}{' PKR'}
                                              </td>
                                              <td className="col-4">
                                              </td> */}

                                        </div>
                                      )
                                    })
                                );
                              }
                            )
                        }
                        {
                          !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                            JSON.parse(carts.item.modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                            // itemData === null && undefined &&
                            Object.keys(carts.item.modifiers).map(
                              (key: any, index) => {
                                return (
                                  carts.item.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true &&
                                        // item.modifier_name + ' x ' + item.quantity + " , "
                                        <div>

                                          <span>
                                            {
                                              item.modifier_name + ' x ' + item.quantity
                                            }
                                          </span>
                                          {/* <td className="col-4 td-custom-padding">
                                                {item.modifier_sale_price*item.quantity}{' PKR'}
                                              </td>
                                              <td className="col-4 td-custom-padding">
                                              </td> */}

                                        </div>
                                      )
                                    })
                                );
                              }
                            )
                        }
                        <div>
                          <span>
                            Item total
                          </span>
                        </div>

                        <div className="full-date">
                          {/* <NumericInput
                            min={0}
                            max={100}
                            mobile
                            className="form-control"
                            value={carts.quantity}
                            onChange={(value: any) =>
                              this.changeQuantity(value, indexes)
                            }
                          /> */}
                          <div style={{ display: "flex", width: '9rem', justifyContent: "space-between" }}>
                            <div className="decrement-btn" onClick={() => this.decrementCartQuantity(indexes)}><a className="dec-icon">-</a></div>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>{carts.quantity}</div>
                            <div className="increment-btn" onClick={() => this.incrementCartQuantity(indexes)}><a className="inc-icon">+</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="date d-flex flex-column text-right">
                      {!_.isEmpty(carts.combo) &&
                        <strong className="price-unit">{priceunit + parseFloat(carts.price) * carts.quantity.toFixed(2)}</strong>
                      }
                      {!_.isEmpty(carts.item) &&
                        <strong className="price-unit">{priceunit}{carts.selectedsize.discount_price ? (parseFloat(carts.selectedsize.discount_price) * parseFloat(carts.quantity)).toFixed(2) : (parseFloat(carts.selectedsize.mrp) * carts.quantity).toFixed(2)}</strong>
                      }

                      {(!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                        typeof carts.combo.choices == "string" ?
                        JSON.parse(carts.combo.choices).map((item: any) => {
                          return (
                            <div>
                              {item.extra_price > 0 ?
                                <span className="price-unit">
                                  {priceunit + (parseFloat(item.extra_price) * item.quantity * carts.quantity).toFixed(2)}
                                </span>
                                :
                                <span className="price-unit">
                                  {priceunit + (parseFloat(item.extra_price) * item.quantity).toFixed(2)}
                                </span>
                              }
                            </div>
                          )

                        })


                        :
                        (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) && typeof carts.combo.choices == "object" &&
                        // itemData === null && undefined &&
                        Object.keys(carts.combo.choices).map(
                          (key: any, index) => {
                            return (
                              carts.combo.choices[key].map(
                                (item: any, index: any) => {
                                  return (
                                    item.selected === true && item.quantity > 0 &&
                                    <div>
                                      <>
                                        {item.extra_price > 0 ?
                                          <span className="price-unit">
                                            {priceunit + (parseFloat(item.extra_price) * item.quantity * carts.quantity).toFixed(2)}
                                          </span>
                                          :
                                          <span className="price-unit">
                                            {priceunit + (parseFloat(item.extra_price) * item.quantity).toFixed(2)}
                                          </span>
                                        }
                                      </>

                                    </div>
                                  )
                                })
                            );
                          }
                        )
                      }
                      {
                        !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                          JSON.parse(carts.combo.modifiers).map((item: any) => {
                            return (
                              <div>
                                <span className="price-unit">
                                  {priceunit + parseFloat(item.modifier_sale_price) * item.quantity}
                                </span>
                              </div>
                            )
                          }) :
                          (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                          // itemData === null && undefined &&
                          Object.keys(carts.combo.modifiers).map(
                            (key: any, index) => {
                              return (
                                carts.combo.modifiers[key].map(
                                  (item: any, index: any) => {
                                    return (
                                      item.selected === true &&
                                      // item.modifier_name + ' x ' + item.quantity + " , "
                                      <div>

                                        <span className="price-unit">
                                          {priceunit + parseFloat(item.modifier_sale_price) * item.quantity}

                                        </span>
                                        {/* <td className="col-4">
                                                {item.modifier_sale_price*item.quantity}{' PKR'}
                                              </td>
                                              <td className="col-4">
                                              </td> */}

                                      </div>
                                    )
                                  })
                              );
                            }
                          )
                      }

                      {
                        !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                          JSON.parse(carts.item.modifiers).map((item: any) => {
                            return (
                              `${item.modifier_name} * ${item.quantity}, `
                            )
                          }) :
                          (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                          // itemData === null && undefined &&
                          Object.keys(carts.item.modifiers).map(
                            (key: any, index) => {
                              return (
                                carts.item.modifiers[key].map(
                                  (item: any, index: any) => {
                                    return (
                                      item.selected === true &&
                                      // item.modifier_name + ' x ' + item.quantity + " , "
                                      <div>

                                        <span className="price-unit">
                                          {priceunit + parseFloat(item.modifier_sale_price) * item.quantity}

                                        </span>
                                        {/* <td className="col-4 td-custom-padding">
                                                {item.modifier_sale_price*item.quantity}{' PKR'}
                                              </td>
                                              <td className="col-4 td-custom-padding">
                                              </td> */}

                                      </div>
                                    )
                                  })
                              );
                            }
                          )
                      }
                      {carts.combo && carts.combo.choices && typeof carts.combo.choices == 'string' ?
                        <strong className="price-unit combo-choices">{priceunit + (parseFloat(carts.totalItemPrice)).toFixed(2)}</strong>

                        :
                        <strong className="price-unit combo-choices">{priceunit + (parseFloat(carts.totalItemPrice)).toFixed(2)}</strong>
                      }
                      {carts.item &&
                        <strong className="price-unit combo-choices">{priceunit + (parseFloat(carts.totalItemPrice)).toFixed(2)}</strong>
                      }

                    </div>
                  </div>
                </div>
              ))}

              {coupon && !coupon.coupon_id && (
                <div >
                  <div
                    className="item d-flex justify-content-between"
                    // action="#"
                    style={{ paddingTop: "10px", marginBottom: "-1px" }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter discount code"
                        name="coupanCode"
                        onChange={this.handleChangeCoupan}
                      />
                      <div className="input-group-append">
                        <button
                          id="roundb"
                          className="btn btn-sm btn-primary"
                          style={{
                            // backgroundColor: "#C00A27",
                            // borderColor: "#C00A27"
                            backgroundColor: "#d9b165",
                            borderColor: "#d9b165",
                          }}
                          disabled={!this.isCoupanReady()}
                          onClick={() => this.ApplyCoupan()}
                        // type="submit"
                        >
                          Apply Coupon
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              <div className="item d-flex justify-content-between">
                <div className="info d-flex">
                  <div className="title">
                    <h5>Subtotal</h5>
                  </div>
                </div>
                <div className="date text-right">
                  <span>{priceunit}</span>
                    <strong className="price-unit"> {Number(this.calcSubTotal(cart)).toFixed(2)}</strong>
                </div>
              </div>

              {this.props.taxDataCash &&
                <React.Fragment>
                  {this.props.taxDataCash.tax_type == 1 &&
                    <div className="item d-flex justify-content-between">
                      <div className="info d-flex">
                        <div className="title">
                        <h5>Tax: {this.state.paymentMethod == 'Delivery' ? this.props.taxDataCash.tax_percent : this.state.paymentMethod == 'Card On Delivery' || this.state.paymentMethod == 'Card On Pickup' ? this.props.taxDataCard.tax_percent ? this.props.taxDataCard.tax_percent : "0.00" : this.props.taxDataCash.tax_percent } %</h5>
                        </div>
                      </div>
                      <div className="text-right">
                        <span>{priceunit + Math.round(this.calcTotalTax(this.props.CartTotal))} </span>
                      </div>
                    </div>
                  }

                  {this.props.taxDataCash.tax_type == 0 &&
                    <div className="item d-flex justify-content-between">
                      <div className="info d-flex">
                        <div className="title">
                          {/* <h5>Tax: {this.props.taxDataCash.tax_percent}%</h5> */}
                          <h5>Tax: {this.state.paymentMethod == 'Delivery' ? this.props.taxDataCash.tax_percent : this.state.paymentMethod == 'Card On Delivery' || this.state.paymentMethod == 'Card On Pickup' ? this.props.taxDataCard.tax_percent ? this.props.taxDataCard.tax_percent : "0.00" : this.props.taxDataCash.tax_percent } %</h5>
                        </div>
                      </div>
                      <div className="text-right">
                        {/* <span>(Inclusive of Tax {priceunit + (this.calcTotalTax(this.props.CartTotal).toFixed(2))}) </span> */}
                        <span>(Inclusive of Tax {priceunit + (this.calcTotalTax(this.props.CartTotal) === 0 ? "0.00" : this.calcTotalTax(this.props.CartTotal).toFixed(2))}) </span>
                      </div>
                    </div>
                  }
                </React.Fragment>

              }

              {/* {!_.isEmpty(tax) && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Tax</h5>
                    </div>
                  </div>
                  <div className="text-right">
                    <span>Inclusive of tax {tax.tax_percent}%</span>
                  </div>
                </div>
              )} */}
              {this.state.orderType == "Delivery" && deliveryfee !== "" && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Delivery Fee</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong className="price-unit"> { deliveryfee !== "" ? parseFloat(deliveryfee).toFixed(2) : '0.00'}</strong>
                  </div>
                </div>
              )}
              {/* {this.state.orderType == "Delivery" && selectedStore.state_id.pos_service_fee !== "" && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>POS Fee</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong>{selectedStore.state_id.pos_service_fee}</strong>
                  </div>
                </div>
              )} */}
              {!_.isEmpty(coupon) &&
                // Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
                // this.props.orderType && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Discount</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong className="price-unit">{Math.round(this.calcTotalDiscount(this.props.discountAmount))}</strong>
                  </div>
                </div>
                // )
              }
              {this.calculateOrderDiscount(cart) > 0 &&
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Discount</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong className="price-unit"> {Math.round(this.calculateOrderDiscount(cart))}</strong>
                  </div>
                </div>
              }
              <div className="item d-flex justify-content-between">
                <div className="info d-flex">
                  <div className="title">
                    <h4>Total</h4>
                  </div>
                </div>
                <div className="date text-right">
                  <span>{priceunit}</span>
                  <strong className="price-unit"> {Number(this.calcTotal(cart)).toFixed(2)}</strong>
                </div>
              </div>


            </div>
          </div>
        );
      } else {
        return (
          <div className="daily-feeds card">
            <div className="card-header">
              <h3 className="h4">Empty Cart</h3>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="daily-feeds card">
          <div className="card-header">
            <h3 className="h4">Empty Cart</h3>
          </div>
        </div>
      );
    }
  };
  checkModGroupsQuantity = () => {
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map(key => {
        let ModifierQuantityCount = 0;
        for (let i = 0; i < this.state.modifiers[key].length; i++) {
          if (this.state.modifiers[key][i].modGroup_min_quantity > 0) {
            if (this.state.modifiers[key][i].selected && this.state.modifiers[key][i].quantity > 0) {
              ModifierQuantityCount += this.state.modifiers[key][i].quantity;
            }
            if (i == this.state.modifiers[key].length - 1) {
              if (ModifierQuantityCount < this.state.modifiers[key][i].modGroup_min_quantity) {
                // (document as any).getElementById(`${key}`).classList.add('required-label--error');
                // (document as any).getElementById(`req-${key}`).classList.add('required-label--error');
                // (document as any).getElementById(`choose-${key}`).classList.add('choose-label--error');
                // (document as any).getElementById(`choose ${key}`).classList.add('choose-label--error');
                toast.error(`Please select required quantity for modifiers`, {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000
                });
              }
            }
          }
        }
      })
    }
  }

  itemReady = () => {
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          for (let i = 0; i < this.state.modifiers[key].length; i++) {
            if (this.state.modifiers[key][i].modGroup_min_quantity > 0) {
              if (this.state.modifiers[key][i].selected && this.state.modifiers[key][i].quantity > 0) {
                ModifierQuantityCount += this.state.modifiers[key][i].quantity;
              }
              if (i == this.state.modifiers[key].length - 1) {
                if (ModifierQuantityCount < this.state.modifiers[key][i].modGroup_min_quantity) {
                  if (!foundModifier) {
                    // toast.warn("Please select max quantity for required option in " + this.state.modifiers[key][i].mod_group_name, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
                    foundModifier = true;
                    quantityFlag = false;
                    break;
                  }
                }
                else {
                  quantityFlag = true;
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  handleAdd = async (item: any) => {
    let cart: any = [];
    let itemReadyFlag = this.itemReady();

    let { itemData } = this.props;
    this.props.getCart();
    let data: any = this.props.cart;

    if (!_.isEmpty(data)) {
      cart = JSON.parse(data);
    }
    // console.log("cart",cart)
    if (!_.isEmpty(item)) {
      this.checkModGroupsQuantity()
      if (itemReadyFlag) {
        if (item.menu_item_id) {
          let sizearray: any = JSON.parse(item.item_size);
          //     //if Item is menu_item
          if (!_.isEmpty(cart)) {
            let found: any = "";
            let modifier_comparison = true;
            cart.forEach((element: any) => {
              if (element["item"]) {
                if (element["item"].menu_item_id == item.menu_item_id) {
                  if (this.state.selectedsize.size == element.selectedsize.size) {
                    let cartmodifiers = element['item'].modifiers
                    if (cartmodifiers !== undefined) {
                      Object.keys(cartmodifiers).map(key => {
                        cartmodifiers[key].map((obj: any, index: any) => {
                          Object.keys(this.state.modifiers).map(key => {
                            this.state.modifiers[key].map((item: any, index: any) => {
                              if (obj.modifier_name == item.modifier_name) {
                                if (obj.selected == item.selected) {
                                  return
                                }
                                else {
                                  return modifier_comparison = false;
                                }
                              }
                            });
                          });
                        })
                      });

                      if (modifier_comparison) {

                        Object.keys(cartmodifiers).map(key => {
                          cartmodifiers[key].map((obj: any, index: any) => {
                            Object.keys(this.state.modifiers).map(key => {
                              this.state.modifiers[key].map((item: any, index: any) => {
                                if (obj.modifier_name == item.modifier_name) {
                                  if (obj.selected == item.selected) {
                                    obj.quantity += item.quantity;
                                  }
                                }
                              });
                            });
                          })
                        });
                        element.quantity = element.quantity + 1;
                        element.totalItemPrice = this.state.itemPrice + element.totalItemPrice;
                        element.subTotal = Math.round(
                          element.actualPrice * element.quantity
                        );
                        return (found = 1);
                      }
                    }
                    else {

                    }
                  }
                }
              }
            });
            if (found == "") {
              const itemcart = {
                item: item,
                quantity: 1,
                selectedsize: this.state.selectedsize,
                price: Math.round(this.state.initialPrice),
                totalItemPrice: Math.round(this.state.itemPrice),
                actualPrice: Math.round(this.state.actualPrice),
                subTotal: Math.round(this.state.actualPrice),
                image: item.images && item.images.length > 0 ? item.images[0].image_url : ""
              };
              cart.push(itemcart);
            }
          } else {
            const itemcart = {
              item: item,
              quantity: 1,
              selectedsize: this.state.selectedsize,
              price: Math.round(this.state.initialPrice),
              totalItemPrice: Math.round(this.state.itemPrice),
              actualPrice: Math.round(this.state.actualPrice),
              subTotal: Math.round(this.state.actualPrice),
              image: item.images && item.images.length > 0 ? item.images[0].image_url : ""
            };

            cart.push(itemcart);
          }
        }
      }
      if (item.combo_id > 0) {
        // if Item is combo item
        if (this.state.comboQuantityReached) {
          let modifier_comparison = true;
          let choices_comparison = true;
          if (!_.isEmpty(cart)) {
            let found: any = "";
            cart.forEach((element: any) => {
              // console.log(element['combo'].combo_id)
              if (element["combo"]) {
                if (element["combo"].combo_id == item.combo_id) {
                  let cartmodifiers = element['combo'].modifiers
                  let cartchoices = element['combo'].choices;
                  if (cartmodifiers !== undefined && typeof cartchoices == 'object' && typeof cartmodifiers === 'object') {
                    Object.keys(cartmodifiers).map(key => {
                      cartmodifiers[key].map((obj: any, index: any) => {
                        Object.keys(this.state.modifiers).map(key => {
                          this.state.modifiers[key].map((item: any, index: any) => {
                            if (obj.modifier_name == item.modifier_name) {
                              if (obj.selected == item.selected) {
                                return
                              }
                              else {
                                return modifier_comparison = false;
                              }
                            }
                          });
                        });
                      })
                    });
                    Object.keys(cartchoices).map(key => {
                      cartchoices[key].map((obj: any, index: any) => {
                        Object.keys(this.state.comboDetails).map(key => {
                          this.state.comboDetails[key].map((item: any, index: any) => {
                            if (obj.item_name == item.item_name && obj.size == item.size) {
                              if (obj.selected == item.selected && obj.quantity == item.quantity) {
                                return
                              }
                              else {
                                return choices_comparison = false;
                              }
                            }
                          });
                        });
                      })
                    });

                    if (modifier_comparison && choices_comparison) {
                      Object.keys(cartmodifiers).map(key => {
                        cartmodifiers[key].map((obj: any, index: any) => {
                          Object.keys(this.state.modifiers).map(key => {
                            this.state.modifiers[key].map((item: any, index: any) => {
                              if (obj.modifier_name == item.modifier_name) {
                                if (obj.selected == item.selected) {
                                  obj.quantity += item.quantity;
                                }
                              }
                            });
                          });
                        })
                      });
                      // Object.keys(cartchoices).map(key => {
                      //   cartchoices[key].map((obj: any, index: any) => {
                      //     Object.keys(this.state.comboDetails).map(key => {
                      //       this.state.comboDetails[key].map((item: any, index: any) => {
                      //         if (obj.item_name == item.item_name && obj.size == item.size) {
                      //           if (obj.selected == true && item.quantity > 0) {
                      //             obj.quantity += item.quantity;
                      //           }
                      //         }
                      //       });
                      //     });
                      //   })
                      // });
                      element.quantity = element.quantity + 1;
                      element.totalItemPrice = this.state.itemPrice + element.totalItemPrice;
                      element.subTotal = Math.round(
                        element.actualPrice * element.quantity
                      );
                      return (found = 1);

                    }
                  }
                }
              }
            });

            if (found == "") {
              const itemcart = {
                combo: item,
                quantity: 1,
                price: Math.round(this.state.netPrice),
                totalItemPrice: Math.round(this.state.itemPrice),
                actualPrice: Math.round(this.state.actualPrice),
                subTotal: Math.round(this.state.actualPrice),
                image: item.images.length > 0 ? item.images[0].image_url : ''
              };
              //   console.log('comboDetail1',itemcart);

              cart.push(itemcart);
            }
          } else {
            const itemcart = {
              combo: item,
              quantity: 1,
              price: Math.round(this.state.netPrice),
              totalItemPrice: Math.round(this.state.itemPrice),
              actualPrice: Math.round(this.state.actualPrice),
              subTotal: Math.round(this.state.actualPrice),
              image: item.images.length > 0 ? item.images[0].image_url : ''
            };
            cart.push(itemcart);
            // console.log('comboDetail2',itemcart);
          }
        }
        else {
          toast.error(`Please select maximum quantity for burgers`, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000
          });
          return;
        }
      }
    }

    if (itemReadyFlag) {
      this.props.saveCart(cart);
      this.props.getCart();
      if (item.combo_id) {
        this.props.getComboDetail(itemData.combo_id);
        this.setState({
          itemPrice: (itemData.discount_price && (itemData.order_channel == 'callcenter')) ? Math.round(itemData.discount_price) : Math.round(itemData.combo_mrp_price),
        })
      }
      if (item.menu_item_id) {
        this.props.getItemDetail(itemData.menu_item_id);
        this.setState({
          itemPrice: (JSON.parse(itemData.item_size)[0].discount_price && (itemData.order_channel == 'callcenter')) ? Math.round(JSON.parse(itemData.item_size)[0].discount_price) : Math.round(JSON.parse(itemData.item_size)[0].mrp),
        })
      }
      toast.error(
        `${(item.item_name && item.item_name) ||
        (item.combo_name && item.combo_name)
        } added to cart`,
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000
        }
      );
    }
  };


  HoursArray(hourDiff: any, starttime: any) {
    if (String(hourDiff).indexOf('-') !== -1) {
      hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
      hour = starttime + i;
      let AmOrPm = '';
      hour >= 12 && hour < 24 ? (AmOrPm = 'pm') : (AmOrPm = 'am');
      hour = hour % 12 || 12;
      hours.push(hour + AmOrPm);
    }
    var string = hours.join(':');
    return string;
  };


  compareOpenClosingTime = (storeSelected: any) => {
    this.props.getTimezone(storeSelected.store_id).then((serverTime: any) => {
      let today = new Date();
      //convert the current time 24 hour to 12 hour
      // let CurrentHours: any = today.getHours();
      // let currentMinutes: any = today.getMinutes();
      let CurrentHours: any = serverTime.split(":")[0];
      let currentMinutes: any = serverTime.split(":")[1];
      let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
      CurrentHours = CurrentHours % 12 || 12;
      let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
      //define hours or minutes
      var timeStartMin = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getMinutes();
      var timeEndMin = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getMinutes();
      var timeStartHours = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getHours();
      var timeEndHours = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getHours();
      var hourDiff: any = timeEndHours - timeStartHours;
      let HourString = this.HoursArray(hourDiff, timeStartHours);
      let splitHourArray = HourString.split(":")

      if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
        if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
          if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
            //if Pick Hour with am/pm is exist in HourString at last index
            this.setState({ storeOpenFlag: false })
            toast.error("Sorry! Store is closed at this moment");
          } else {
            this.setState({ storeOpenFlag: true })
            this.props.saveStore(storeSelected);
            // result ? this.props.getTax(result.state_id) : this.props.getTax();
            this.props.saveSelectStoreId(storeSelected)
          }
        } else {
          if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
              if (currentMinutes < timeEndMin) {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected)
              } else {
                this.setState({ storeOpenFlag: false })
                toast.error("Sorry! Store is closed at this moment");
              }
            } else {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected)
            }
          } else { //if store opening or closing minute is not 00
            if (
              currentMinutes > timeStartMin ||
              currentMinutes < timeEndMin
            ) {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected)
            } else {
              this.setState({ storeOpenFlag: false })
              toast.error("Sorry! Store is closed at this moment");
            }
          }
        }
      } else {
        this.setState({ storeOpenFlag: false })
        toast.error("Sorry! Store is closed at this moment");
      }
    })

  }
  compareTimingbyEachDay = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)

      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
      let todayDay = new Date(serverDate).getDay();
      let dayName = days[todayDay];
      // let dayName = days[dayName]
      weekDaysTimingArr.map((storeDay: any) => {
        if (dayName == storeDay.day) {
          let today = new Date();
          //convert the current time 24 hour to 12 hour
          // let CurrentHours: any = today.getHours();
          // let currentMinutes: any = today.getMinutes();
          let CurrentHours: any = serverTime.time.split(":")[0]
          let currentMinutes: any = serverTime.time.split(":")[1]
          let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
          CurrentHours = CurrentHours % 12 || 12;
          let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
          //define hours or minutes
          var timeStartMin = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getMinutes();
          var timeEndMin = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getMinutes();
          var timeStartHours = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getHours();
          var timeEndHours = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getHours();
          var hourDiff: any = timeEndHours - timeStartHours;
          let HourString = this.HoursArray(hourDiff, timeStartHours);
          let splitHourArray = HourString.split(":")

          if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
            if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                //if Pick Hour with am/pm is exist in HourString at last index
                toast.error("Sorry! Store is closed at this moment");
                this.setState({ storeOpenFlag: false })
              } else {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected)
              }
            } else {
              if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                  if (currentMinutes < timeEndMin) {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected)
                  } else {
                    toast.error("Sorry! Store is closed at this moment");
                    this.setState({ storeOpenFlag: false })
                  }
                } else {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected)
                }
              } else { //if store opening or closing minute is not 00
                if (
                  currentMinutes > timeStartMin ||
                  currentMinutes < timeEndMin
                ) {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected)
                } else {
                  toast.error("Sorry! Store is closed at this moment");
                  this.setState({ storeOpenFlag: false })
                }
              }
            }
          } else {
            toast.error("Sorry! Store is closed at this moment");
            this.setState({ storeOpenFlag: false })
          }
          return;
        }
      })
    })


  }

  compareLaterOpenClosingTime = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      // console.log('this.state.laterdatetime.getFullYear()', this.state.laterdatetime);
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()

      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == clientDate.split("-")[2] || parseInt(serverDate.split("-")[2]) + 1 == clientDate.split("-")[2])
      // console.log('this.state.laterdatetime', this.state.laterdatetime.getHours());

      // console.log('validDate', validDate);

      if (validDate) {
        // console.log('ifff valid date');

        let userSystemTime = this.state.laterdatetime.split("T")[1]
        let server_time = moment(serverTime.time, "HH:mm:ss");
        let client_time = moment(userSystemTime, "HH:mm:ss");
        let addedTime: any = null;
        let today = new Date();
        let todayHours = today.getHours()
        let todayMinutes = today.getMinutes()
        // console.log('todayHours', todayHours, 'server hours', serverTime.time.split(":")[0]);


        if (todayHours != parseInt(serverTime.time.split(":")[0])) {
          // console.log('in second if');

          toast.error("Your device date or time is invalid!");
          this.setState({ netWork: false })
          return
        }

        let laterdatetime = new Date(this.state.laterdatetime);
        let futureHours: any = laterdatetime.getHours();
        let futureMinutes: any = laterdatetime.getMinutes();
        let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
        futureHours = futureHours % 12 || 12;
        let futuretime: any = futureHours + '' + futureAmOrPm;
        //define hours or minutes
        var timeStartMin = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getMinutes();
        var timeEndMin = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getMinutes();
        var timeStartHours = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getHours();
        var timeEndHours = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getHours();
        var hourDiff: any = timeEndHours - timeStartHours;
        let HourString = this.HoursArray(hourDiff, timeStartHours);
        let splitHourArray = HourString.split(":")
        if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
          if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
              //if Pick Hour with am/pm is exist in HourString at last index
              toast.error("Sorry! Store is closed at this moment");
            } else {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected)
            }
          } else {
            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                if (futureMinutes < timeEndMin) {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected)
                } else {
                  toast.error("Sorry! Store is closed at this moment");
                }
              } else {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected)
              }
            } else { //if store opening or closing minute is not 00
              if (
                futureMinutes > timeStartMin ||
                futureMinutes < timeEndMin
              ) {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected)
              } else {
                toast.error("Sorry! Store is closed at this moment");
              }
            }
          }
        } else {
          toast.error("Sorry! Store is closed at this moment");
        }
      } else {
        // console.log('else');
        toast.error("Your device date or time is invalid!");
        this.setState({ netWork: false })
      }


    })

  }

  compareLaterTimingbyEachDay = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      // console.log('this.state.laterdatetime.getFullYear()', this.state.laterdatetime);
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()

      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == clientDate.split("-")[2] || parseInt(serverDate.split("-")[2]) + 1 == clientDate.split("-")[2])
      // console.log('this.state.laterdatetime', this.state.laterdatetime.getHours());

      // console.log('validDate', validDate);

      if (validDate) {

        // console.log('ifff valid date');

        let userSystemTime = this.state.laterdatetime.split("T")[1]
        let server_time = moment(serverTime.time, "HH:mm:ss");
        let client_time = moment(userSystemTime, "HH:mm:ss");
        let addedTime: any = null;
        let today = new Date();
        let todayHours = today.getHours()
        let todayMinutes = today.getMinutes()
        // console.log('todayHours', todayHours, 'server hours', serverTime.time.split(":")[0]);


        if (todayHours != parseInt(serverTime.time.split(":")[0])) {
          // console.log('in second if');

          toast.error("Your device date or time is invalid!");
          this.setState({ netWork: false })
          return
        }
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
        let futureDay = new Date(this.state.laterdatetime).getDay();
        let dayName = days[futureDay];
        weekDaysTimingArr.map((storeDay: any) => {
          if (dayName == storeDay.day) {
            let laterdatetime = new Date(this.state.laterdatetime);
            let futureHours: any = laterdatetime.getHours();
            let futureMinutes: any = laterdatetime.getMinutes();
            let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
            futureHours = futureHours % 12 || 12;
            let futuretime: any = futureHours + '' + futureAmOrPm;
            //define hours or minutes
            var timeStartMin = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getMinutes();
            var timeEndMin = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getMinutes();
            var timeStartHours = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getHours();
            var timeEndHours = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getHours();
            var hourDiff: any = timeEndHours - timeStartHours;
            let HourString = this.HoursArray(hourDiff, timeStartHours);
            let splitHourArray = HourString.split(":")
            if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
              if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                  //if Pick Hour with am/pm is exist in HourString at last index
                  toast.error("Sorry! Store is closed at this moment");
                } else {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected)
                }
              } else {
                if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                  if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                    if (futureMinutes < timeEndMin) {
                      this.setState({ storeOpenFlag: true })
                      this.props.saveStore(storeSelected);
                      // result ? this.props.getTax(result.state_id) : this.props.getTax();
                      this.props.saveSelectStoreId(storeSelected)
                    } else {
                      toast.error("Sorry! Store is closed at this moment");
                    }
                  } else {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected)
                  }
                } else { //if store opening or closing minute is not 00
                  if (
                    futureMinutes > timeStartMin ||
                    futureMinutes < timeEndMin
                  ) {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected)
                  } else {
                    toast.error("Sorry! Store is closed at this moment");
                  }
                }
              }
            } else {
              toast.error("Sorry! Store is closed at this moment");
            }
          }
        })
      } else {
        // console.log('else');
        toast.error("Your device date or time is invalid!");
        this.setState({ netWork: false })
      }



    })

  }

  handleStoresInputChange = (e: any, index: any) => {
    // set condition for the pickup store
    // if (_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName)) {
    let { allStoresList } = this.props;
    if (e) {
      // this.setState({ isClicked: true })
     let result: any = allStoresList.find((x: any) => x.store_id == e.value); //find the store from the stores list
      // console.log("result", result)
      if (result) {
        this.props.saveStore(result)
      }
      else{
        toast.error("No menu found for selected store, area and brand")
      }
      this.setState({
        promiseTime: result && result.expected_delivery_time
      })
      // this.props.allGroupsList(e.value)
      if (result && result.is_active == 0) {
        toast.error(result.store_name + " Store is temprorily closed! Please find backup store")
        this.setState({
          storeOpenFlag: false,
        })
        if (result.mainStoreId.length > 0) {
          let backupStoreData: any = [];
          result.mainStoreId.map((backupStore: any) => {
            if (backupStore.backupStoreId.is_active == 1) {
              this.setState({ backupStorePromiseTime: result.expected_delivery_time + 15 })
              backupStoreData.push(backupStore);
            }
          })
          //sorting array with priority basis if backup stores are greater than 1
          if (backupStoreData.length > 1) {
            backupStoreData.sort((a: any, b: any) => {
              return a.priority - b.priority
            })
          }
          //finding the backupstore object from response data and match them by store_id
          let foundedStore: any = []
          if (backupStoreData.length > 0) {
            foundedStore.push(allStoresList.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
            this.props.saveBackupStores(backupStoreData)
          }
        }
      }
      if (e.value != '') {
        if (this.props.orderPeriod == 'now') {
          if (result && (result.isDays == 0 && result.is_active == 1)) {
            this.compareOpenClosingTime(result)
          }
          else if (result && (result.isDays == 1 && result.is_active == 1)) {
            this.compareTimingbyEachDay(result)
          }
        }
        else if (this.props.orderPeriod == 'later') {
          if (result && (result.isDays == 0 && result.is_active == 1)) {
            this.compareLaterOpenClosingTime(result)
          }
          else if (result && (result.isDays == 1 && result.is_active == 1)) {
            this.compareLaterTimingbyEachDay(result)
          }
        }
      }
    }
    // }
  };

  handleSaveStore(event: { target: { name: any; value: any } }) {
    let answer
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(event)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer== true) {
        this.props.saveStore({})
        sessionStorage.removeItem("cart")
        this.props.saveCart([])
        // console.log("handle zone is triggered", event)
      }
    }
    else{
      this.props.saveStore({})
      let { allStoresList } = this.props;
      let result: any = allStoresList.find((x: any) => x.store_id == event.target.value); //find the store from the stores list
      if (result) {
        this.setState({
          promiseTime: result && result.expected_delivery_time
        })
      }
      this.props.allGroupsList(event.target.value, this.state.brand_id)
      if (result && result.is_active == 0) {
        toast.error(result.store_name + " Store is temprorily closed! Please find backup store")
        this.setState({
          storeOpenFlag: false,
        })
        if (result.mainStoreId.length > 0) {
          let backupStoreData: any = [];
          result.mainStoreId.map((backupStore: any) => {
            if (backupStore.backupStoreId.is_active == 1) {
              this.setState({ backupStorePromiseTime: result.expected_delivery_time + 15 })
              backupStoreData.push(backupStore);
            }
          })
          //sorting array with priority basis if backup stores are greater than 1
          if (backupStoreData.length > 1) {
            backupStoreData.sort((a: any, b: any) => {
              return a.priority - b.priority
            })
          }
          //finding the backupstore object from response data and match them by store_id
          let foundedStore: any = []
          if (backupStoreData.length > 0) {
            foundedStore.push(allStoresList.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
            this.props.saveBackupStores(backupStoreData)
          }
        }
      }
      if (event.target.value != '') {
        if (this.props.orderPeriod == 'now') {
          if (result && (result.isDays == 0 && result.is_active == 1)) {
            this.compareOpenClosingTime(result)
          }
          else if (result && (result.isDays == 1 && result.is_active == 1)) {
            this.compareTimingbyEachDay(result)
          }
        }
        else if (this.props.orderPeriod == 'later') {
          if (result && (result.isDays == 0 && result.is_active == 1)) {
            this.compareLaterOpenClosingTime(result)
          }
          else if (result && (result.isDays == 1 && result.is_active == 1)) {
            this.compareLaterTimingbyEachDay(result)
          }
        }
      }
    }
    
  }

  isOrderReady = () => {
    let { selectedStore, delivery_address, cart } = this.props;
    let data: any = cart;
    let cartData: any = JSON.parse(data);
    let { orderType } = this.state;
    if (orderType == 'Delivery') {
      return (
        !_.isEmpty(selectedStore) &&
        delivery_address !== "" && cartData.length != 0
      );
    }
    else if (orderType == 'Pickup') {
      return (!_.isEmpty(selectedStore) && cartData.length != 0 && this.state.pickupCity !== '');
    }

  };

  //Setting lat lng from geo-code api
  setDeliveryAddress(fullAddress: any) {
    let { Block, Area, City } = this.props;
    let Area_city = Block + " " + Area + " " + City;
    // console.log("address for geocode", Area_city)
    // Geocode.fromAddress(fullAddress)
    //   .then((json: any) => {
    //     var location = json.results[0].geometry.location;
    //     this.setState({ lat: location.lat, lng: location.lng })
    //     this.props.findLocalStores(location.lat, location.lng);
    //   })
    //   .catch((error: any) => {
    //   });
  }

  ValidateOrder() {
    // this.props.handleAddressInput(this.props.houseNo + ' ' + this.props.tradeZoneName + ' ' + this.props.tradeAreaName + ' ' + this.props.cityName)
    this.props.handleAddressInput(this.props.houseNo + ' ' + this.props.tradeZoneName + ' ' + this.props.cityName)
    let temp = true;
    let customerName = sessionStorage.getItem('customername');
    let customerphone = sessionStorage.getItem('phone');
    let customerEmail = sessionStorage.getItem('email');
    let { selectedStore, delivery_address, orderPeriod } = this.props;
    let { validTime } = this.state;
    if ((customerName === '' || customerName === null) && (customerphone === '' || customerphone === null || this.state.pickupCity == '')) {
      toast.error("Customer information is incomplete! Please complete customer information in order to place order. ")
      return temp = false;
    }
    else if (_.isEmpty(selectedStore)) {
      toast.error("Please select the store in order to place order!")
      return temp = false;
    }
    // else if (this.state.orderType === 'Delivery' && delivery_address === '') {
    //   toast.error("Please enter delivery address in order to proceed order!")
    //   return temp = false;
    // }

    else if (this.state.orderType === 'Delivery') {
      if (this.state.changeLocation) {
        // console.log("state-verified",this.state.building )
        // if ((this.state.area == '' || this.state.room == '' || this.state.fullAddress == '')) {
        // if ((this.state.building == '' && this.props.tradeAreaName == '' && this.props.tradeZoneName == '' && this.props.cityName == '' )) {
          if ((this.state.building == '' && this.props.tradeZoneName == '' && this.props.cityName == '' )) {
          toast.error("Please enter delivery address in order to proceed order!")
          return temp = false;
        }
      } else {
        if (_.isEmpty(this.props.savedAddress) || _.isEmpty(this.props.savedAddress.full_address)) {
          toast.error("Please enter delivery address in order to proceed order!")
          return temp = false;
        }
      }

    }
    else if(this.state.orderType == 'Pickup'){
      // if(this.state.cityClicked == false){
      //   //  toast.error("Please fill out the mandatory fields");
      //   // return temp = false;
      // }
    }
    else if (orderPeriod === 'later' && validTime === false) {
      toast.error("Please enter valid date time in order to proceed order!")
      return temp = false;
    }
    if (temp === true) {
      this.setState({ netWork: true })
      setTimeout(() => {
        this.setState({ netWork: false })
      }, 2500);
      this.confirmOrder();
    }

  }


  confirmOrder = async () => {
    let { callduration } = this.props;
    let brand_id: any = sessionStorage.getItem("brand_id")
    let { storeSelected, taxDataCard, taxDataCash, selectedStore } = this.props;
    let pos_fee: any = selectedStore.state_id.pos_service_fee ? selectedStore.state_id.pos_service_fee : 0
    let data: any = this.props.cart;
    let tax: any = 0;
    tax = taxDataCash.tax_percent
    let cart: any = JSON.parse(data);
    let discount = 0;
    cart.forEach((item: any) => {
      item.tax_percent = tax;
      item.check_number = '';
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * Math.round(item.combo.combo_mrp_price - item.combo.discount_price);
        }
        if (item.combo.choices && typeof item.combo.choices == 'object') {
          Object.keys(item.combo.choices).map(key => {
            item.combo.choices[key].map((element: any) => {
              if (element.selected == true && element.quantity > 0) {
                this.quantity += item.quantity * parseInt(element.quantity);
              }
            })
          })
        }
        else {
          if (item.combo.choices && typeof item.combo.choices == 'string') {
            let choicesData = [];
            choicesData = JSON.parse(item.combo.choices);
            choicesData.map((choice: any) => {
              this.quantity += item.quantity * parseInt(choice.quantity)
            })
          }
        }
      } else {
        this.quantity += item.quantity;
        let sizearray = JSON.parse(item.item.item_size);
        if (item.selectedsize.discount_price) {
          discount += item.quantity * (item.selectedsize.mrp - item.selectedsize.discount_price);
        }
      }
    })

    if (taxDataCash.tax_type == 1 || taxDataCash.tax_type == 0) {
      cart.forEach((item: any) => {
        if (item.combo) {
          // item.tax_amount = (item.combo.discount_price && (item.combo.order_channel == 'callcenter')) ? (item.combo.discount_price * tax / 100) * item.quantity : (item.combo.combo_mrp_price * tax / 100) * item.quantity;
          // item.tax_amount = (item.totalItemPrice * tax / 100);
          item.tax_amount = taxDataCash.tax_type == 1 ? (item.totalItemPrice * tax / 100) : ((item.totalItemPrice) * (parseInt(tax) / (100 + parseInt(tax))))
          if (item.combo.choices && typeof item.combo.choices == 'object') {
            Object.keys(item.combo.choices).map((key: any) => {
              item.combo.choices[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    // item.tax_amount = item.extra_price * tax / 100;
                    item.tax_amount = taxDataCash.tax_type == 1 ? item.extra_price * tax / 100 : ((item.extra_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                    item.tax_type = taxDataCash.tax_type
                  }
                })
            })
          }
          if (item.combo.modifiers && typeof item.combo.modifiers == 'object') {
            Object.keys(item.combo.modifiers).map((key: any) => {
              item.combo.modifiers[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    // item.tax_amount = item.modifier_sale_price * tax / 100;
                    item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (tax / (100 + parseInt(tax)))).toFixed(2);
                    item.tax_type = taxDataCash.tax_type
                  }
                })
            })
          }
        } else {
          let sizearray = JSON.parse(item.item.item_size);
          // console.log(sizearray)
          // item.tax_amount = (item.selectedsize.discount_price && (item.selectedsize.order_channel == 'callcenter')) ? (Math.round(item.selectedsize.discount_price) * tax / 100) * item.quantity : (Math.round(item.selectedsize.mrp) * tax / 100) * item.quantity;
          // item.tax_amount = (item.totalItemPrice * tax / 100);
          item.tax_amount = taxDataCash.tax_type == 1 ? (item.totalItemPrice * tax / 100) : ((item.totalItemPrice) * (parseInt(tax) / (100 + tax)))
          if (item.item.modifiers && typeof item.item.modifiers == 'object') {
            Object.keys(item.item.modifiers).map((key: any) => {
              item.item.modifiers[key].map(
                (item: any, index: any) => {
                  if (item.selected == true) {
                    item.tax_percent = tax;
                    // item.tax_amount = item.modifier_sale_price * tax / 100;
                    item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (tax / (100 + tax))).toFixed(2);
                    item.tax_type = taxDataCash.tax_type
                  }
                })
            })
          }
        }
      })
    }
    this.discount = discount;
    const token = await sessionStorage.getItem("token");
    if (this.state.orderType !== "" && !_.isEmpty(selectedStore)) {
      if (token) {
        let obj: any = {
          order_status_code: 1,
          order_grossprice: (taxDataCash.tax_type == 1) ? this.cartTotal + this.totalTax : this.cartTotal,
          order_channel: "call center",
          discount: discount,
          store_id: selectedStore && selectedStore.store_id,
          trade_zone: this.props.tradeZoneValue && this.props.tradeZoneValue,
          // trade_area: this.props.tradeAreaValue && this.props.tradeAreaValue,
          branch_code: selectedStore && selectedStore.branch_code,
          delivery_status: this.state.orderType,
          special_inst: this.props.special_inst,
          brand_id: brand_id ? brand_id : null,
          check_number: '',
          payment_method: this.state.paymentMethod,
          tax_percent: tax,
          lat: this.state.lat,
          lng: this.state.lng,
          quantity: this.quantity,
          cartItems: cart,
          pos_service_fee: selectedStore.state_id.pos_service_fee ? selectedStore.state_id.pos_service_fee : 0
        };
        if (this.state.orderType == "Delivery") {
          obj.order_netprice = this.cartTotal - this.props.deliveryfee;
          obj.delivery_fee = this.props.deliveryfee;
        } else if (this.state.orderType == "Pickup") {
          obj.order_netprice = this.cartTotal;
        }
        if (this.props.delivery_address !== "" && this.state.orderType == "Delivery") {
          // obj.delivery_address =
          //   this.props.Additional_address_info !== ""
          //     ? this.props.delivery_address + " /" + this.props.Additional_address_info
          //     // : this.props.delivery_address;
          //     :this.state.area + ' ' + this.state.building + ' ' + this.state.room + ' ' + this.state.fullAddress
          // if (this.state.changeLocation == false) {
          //   obj.delivery_address = this.props.savedAddress && this.props.savedAddress.full_address
          // }

          // if (this.state.changeLocation == true) {
          //   obj.delivery_address = this.state.area + ' ' + this.state.building + ' ' + this.state.room + ' ' + this.state.fullAddress
          // }
            // obj.delivery_address = (`${(this.state.building ? this.state.building : "") + " " + (this.props.tradeAreaName ? this.props.tradeAreaName : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "") }`)
            obj.delivery_address = (`${(this.state.building ? this.state.building : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "") }`)
          
        }
        if (!_.isEmpty(this.props.coupon)) {
          obj.coupon = this.props.coupon;
          obj.coupon_redeem = 1;
          obj.coupon_discount_value = this.props.discountAmount;
        }
        if (this.state.orderType == "Delivery") {
          obj.order_netprice = this.cartTotal - this.props.deliveryfee;
          // obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : 0;
          obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : ((obj.order_netprice) * (parseFloat(tax) / (100 + parseFloat(tax)))).toFixed(2);
          obj.tax_type = taxDataCash.tax_type
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + obj.tax_amount : obj.order_netprice
          obj.delivery_fee = this.props.deliveryfee;
        } else if (this.state.orderType == "Pickup") {
          obj.order_netprice = this.cartTotal;
          // obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : 0;
          obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : ((obj.order_netprice) * (parseFloat(tax) / (100 + parseFloat(tax)))).toFixed(2);
          obj.tax_type = taxDataCash.tax_type
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + obj.tax_amount : obj.order_netprice
        }
        if (this.props.orderPeriod == 'later') {
          obj.future_status = 1;
          obj.future_date = moment(this.state.laterdatetime).utc(false).format('YYYY-MM-DD HH:mm')
          obj.laterdatetime = new Date(this.state.laterdatetime);
        }
        //   if (this.props.orderperiod == 'later') {
        //     this.props.saveLaterOrder(obj, this.props.selectedStore, tax);
        //   } else if (this.props.orderperiod == 'now') {
        callduration = callduration / 60;
        let rounded_time = Math.round(callduration * 10) / 10
        let callStatsObj = {
          call_duration: rounded_time,
          call_status: 1,
          customer_number: sessionStorage.getItem('phone'),
          total_sale: this.cartTotal,
          order_method: this.state.orderType
        }
        this.props.saveOrder(obj, this.props.selectedStore, tax, callStatsObj);
        this.props.stopTimer();
        //   }
      } else {
        toast.error("error");
      }
    }
  };
  getStyle() {
    let Activeclasses = "statistic bg-danger d-flex justify-content-center has-shadow img-fluid";
    return Activeclasses;
  }
  FormatAddress() {
    // let { houseNo, Block, Area, City, delivery_address, cityName, tradeAreaName, tradeZoneName, Additional_address_info } = this.props;
    let { houseNo, Block, Area, City, delivery_address, cityName, tradeZoneName, Additional_address_info } = this.props;
    // let fullAddress = houseNo + " " + Block + " " + Area + " " + City;
    // let fullAddress = houseNo + " " + Additional_address_info + ' ' + tradeZoneName + " " + tradeAreaName + " " + cityName;
    let fullAddress = houseNo + " " + Additional_address_info + ' ' + tradeZoneName + " " + cityName;
    // console.log('fullAddress', fullAddress, ' tradeZoneName ', tradeZoneName, ' tradeAreaName ', tradeAreaName, ' cityName ', cityName);

    this.selectRef.select.clearValue();
    this.props.saveStore()
    this.setState({ fullAddress: fullAddress })
    this.props.handleAddressInput(fullAddress)
    setTimeout(
      () => {
        this.setDeliveryAddress(fullAddress);
      }
      ,
      1000
    );
  }
  pickUpFormatAddress() {
    let obj = document as any
    var dropDown: any = obj.getElementById("clearPickupStore");
    let o = ''
    dropDown && dropDown.selectedIndex ? dropDown.selectedIndex = 0 : o = '';
    this.setState({ isPickupClicked: true, isPickUpSubmitClicked: true })
    let { Area, City } = this.props;
    this.props.findPickupStores(
      this.state.lat,
      this.state.lng,
      City,
      this.state.brand_id
    );
    this.props.storesList();
  }
  menuItemsListByGroupsID(group_id: any) {
    let { groupsData } = this.props;
    this.setState({ active_group_id: group_id });
    let data: any = {
      group_id: group_id,
    }
    groupsData.map((item: any, index: any) => {
      if (group_id == item.group_id) {
        data.menuItemsData = item.data
      }
    })
    this.props.UpdateMenuItems(data);
    // this.props.menuItemsListByGroupsID(group_id);
  }
  isAddressReady = (ordertab: any) => {
    if (ordertab == 'delivery') {
      // let { Area, City, cityName, tradeZoneName, tradeAreaName } = this.props;
      let { Area, City, cityName, tradeZoneName } = this.props;
      // console.log("cityPropsData9999", City);
      // return (Area !== "" && City !== "");
      // return (tradeZoneName !== "" && tradeAreaName !== "" && cityName !== "");
      return (tradeZoneName !== "" && cityName !== "");
    }
    else {
      let { City } = this.props;
      let {city} = this.state;
      // return (City !== "");
      return (city !== "");
    }
  }

  handleCityInput = (e: any) => {
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      let answer = window.confirm("Are you sure? Your cart will be emptied")
      if (answer) {
        this.props.saveStore({})
        sessionStorage.removeItem("cart")
        this.props.saveCart([])
        if (!_.isEmpty(e)) {
          this.props.setCityName(e.label)
          this.props.setTradeZoneName('', null)
          this.props.getTradeZones(e.value)
          this.props.setTradeAreas()
        }
      }
    }
    else {
      this.props.saveStore({})
      if (this.props.tradeZoneName !== '') {
        this.selectRef.select.clearValue();
        this.selectRef2.select.clearValue();
      }
      this.props.setCityName(e.label)
      this.props.setTradeZoneName('', null)
      this.props.setTradeAreas()      
      this.props.getTradeZones(e.value)
      this.props.setStoreByTradeZone()
      this.props.menuSet()
      
    }
  }

  // handleZone = (e: any) => {
  //   console.log('this.props.selectedStore',this.props.selectedStore);

  //   if(this.props.selectedStore.store_name){
  //     if(window.confirm("cart will be empty")){
  //     this.props.saveStore({})
  //     sessionStorage.removeItem("cart")
  //     this.props.saveCart([])
  //     if (this.props.tradeAreaName !== '') {
  //       this.selectRef.select.clearValue();
  //       this.selectRef3.select.clearValue();
  //     }
  //     }
  //     else{

  //     }
  //   }
  //   else{
  //   console.log("handle zone is triggered", e)
  //   if (!_.isEmpty(e)) {
  //     // let zone = JSON.parse(e.zone)
  //     if (this.props.tradeAreaName !== '') {
  //       this.selectRef.select.clearValue();
  //       this.selectRef3.select.clearValue();
  //     }
  //     // console.log('zone', zone);
  //     // this.props.findLocalStoreWithoutMap(zone.store_id)

  //     this.props.setTradeZoneName(e.label,e.value)
  //     console.log('e.label,e.value',e.label,e.value);
  //     this.props.setTradeAreaName('',null)
  //     this.props.getTradeAreas(e.value)
  //   }
  //   this.props.setTradeAreaName('',null)

  // }
  // }

  handleZone = (e: any) => {
    let answer
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(e)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer) {
        this.props.saveStore({})
        sessionStorage.removeItem("cart")
        this.props.saveCart([])
        // console.log("handle zone is triggered", e)
        if (!_.isEmpty(e)) {
          // let zone = JSON.parse(e.zone)
          if (this.props.tradeZoneName !== '') {
            this.selectRef.select.clearValue();
            // this.props.storesList();
            // this.props.getStoreByTradeZone(e.value)
            // this.selectRef3.select.clearValue();
          }
          // console.log('zone', zone);
          // this.props.findLocalStoreWithoutMap(zone.store_id)
          this.props.setTradeZoneName(e.label, e.value)
          // this.props.setTradeAreaName('', null)
          // this.props.getTradeAreas(e.value)
        }
        // this.props.setTradeAreaName('', null)
      }

    } else {
      this.props.saveStore({})
      if (!_.isEmpty(e)) {
        if(this.state.orderType == "Delivery"){
             this.selectRef.select.clearValue();
             this.props.getTradeAreasByTradeZone(e.value)
             this.props.setStoreByTradeZone()
        }
        this.props.setTradeZoneName(e.label, e.value)
        this.props.setTradeAreaName('', null)
        this.props.menuSet()
      }
    }
  }

  // handleArea = (e: any) => {
  //   let answer
  //   if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
  //     if (!_.isEmpty(e)) {
  //       answer = window.confirm("Are you sure? Your cart will be emptied")
  //     }
  //     if (answer) {
  //       this.props.saveStore({})
  //       sessionStorage.removeItem("cart")
  //       this.props.saveCart([])
  //       let obj = document as any;
  //       let o
  //       obj.getElementById("clear") ? obj.getElementById("clear").selectedIndex = 0 : o = ''
  //       this.setState({ isClicked: false })
  //       // this.props.saveBackupStores([])
  //       // this.props.saveStore({})
  //       this.setState({ promiseTime: 0, backupStorePromiseTime: 0 })
  //       if (!_.isEmpty(e)) {
  //         // let zone = JSON.parse(e.zone)

  //         // console.log('zone', zone);
  //         // this.props.findLocalStoreWithoutMap(zone.store_id)
  //         this.props.saveBackupStores([])
  //         this.selectRef.select.clearValue();
  //         let area = JSON.parse(e.area)
  //         // console.log('area', area);
  //         this.props.setTradeAreaName(e.label, e.value)
  //         this.props.findLocalStoreWithoutMap(area.store_id)
  //       }

  //     }

  //   }
  //   else {

  //     // this.props.saveStore({})
  //     // sessionStorage.removeItem("cart")
  //     // this.props.saveCart([])
  //     // let obj = document as any;
  //     // let o
  //     // obj.getElementById("clear") ? obj.getElementById("clear").selectedIndex = 0 : o = ''
  //     // this.setState({ isClicked: false })
  //     // // this.props.saveBackupStores([])
  //     // // this.props.saveStore({})
  //     // this.setState({ promiseTime: 0, backupStorePromiseTime: 0 })
  //     // // this.props.setTradeAreaName('')
  //     // // this.props.saveSelectStoreId()
  //     this.props.saveStore({})
  //     if (!_.isEmpty(e)) {
  //       // this.props.saveBackupStores([])
  //       // this.selectRef.select.clearValue();
  //       let area = JSON.parse(e.area)
  //       // console.log('area', area);
  //       this.props.setTradeAreaName(e.label, e.value)
  //       this.props.findLocalStoreWithoutMap(area.store_id)
  //     }
  //   }
  // }

  blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;
    // console.log('specialkey', specialkey);

    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }

  handleSearch = (e: any) => {
    this.setState({ googlesearch: e.target.value })
    axios.post(`${BASE_URL}customer/autoComplete/en/${e.target.value}`)
      .then((res: any) => {
        this.setState({ placesSuggestion: res.data.successResponse })
      })
      .catch((err: any) => {
        //  toast.error("something went wrong!!!")
      })
  }

  onPlaceClick = (place: any) => {
    // console.log("place", place)
    const address = place.description
    this.setState({ fullAddress: address, googlesearch: address, changeLocation: true })
    if (address) {
      Geocode.fromAddress(place.description)
        .then((json: any) => {
          // console.log("json", json)
          var location: any = json.results[0].geometry.location;
          let latValue: any = location.lat;
          let lngValue: any = location.lng;
          this.props.findLocalStores(latValue, lngValue)
          this.setState({ lat: latValue, lng: lngValue, placesSuggestion: [] })
          this.props.handleGeoCodeAddress(address);
        })
        .catch((error: any) => {
          // console.log("json err", error)
          // console.warn(error));
        });
    }
  };

  getCurrentPositionMark = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        // console.log("current position call", position)
        this.setState({ lat: position.coords.latitude, lng: position.coords.longitude })
      })
    }
  };
  handleInputChangeCity = (event:any) => {
    if(this.state.brand_id ==null || this.state.brand_id ==undefined || this.state.brand_id ==""){
      alert('Please select brand first')
    }
    else{
    const { cities_portal} = this.props;
    const inputValue = event.target.value;
    this.setState({ pickupCity: inputValue });
    const filteredCities:any = cities_portal.filter((city: any) => {
    const cityName = (city?.name || '').toLowerCase();
    const inputCityName = inputValue.toLowerCase();
    
    return cityName.includes(inputCityName);
    },
   

);
if(filteredCities.length>0){
  this.props.getStoreByCity(filteredCities[0].name)
}
    
  //  this.props.getStoreByCity(.id)

    if (inputValue.trim() === '') {
      this.setState({ suggestions: [], showSuggestions: false, cityClicked: false, selectedStores: [], pickupCity: '' });
    } else if (filteredCities.length === 0) {
      this.setState({ suggestions: [], showSuggestions: false, cityClicked: false, selectedStores: [], pickupCity: '' });
    } else {
      // console.log("suggestionsData333==>",)
      this.setState({ suggestions: filteredCities, showSuggestions: true});
    }
  }
  };
  
  handleTradeArea =(e:any)=>{
    let answer
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(e)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer== true) {
        this.props.saveStore({})
        sessionStorage.removeItem("cart")
        this.props.saveCart([])
        this.props.setTradeAreaName(e?.label, e?.value)
        this.props.getStoreByTradeZone(e?.value,this?.props?.tradeZoneValue)
        this.props.menuSet()
        this.props.saveStore({})
      }
    }else{
      this.props.setTradeAreaName(e?.label, e?.value)
      this.props.getStoreByTradeZone(e?.value,this?.props?.tradeZoneValue)
      this.props.menuSet()
      this.props.saveStore({})
    }
  }
  handleSelectStore = (cityName:any) => {
    const { stores } = this.props;
    const selectedStores = stores.filter((store: any) => {
      if (store) {
        const storeCityName = store.city?.toLowerCase();
        return storeCityName === cityName.toLowerCase();
      }
      return false;
    });
      const newSelectedStores = [...selectedStores];
      this.setState({
        pickupCity: cityName,
        showSuggestions: false,
        selectedStores: newSelectedStores,
        cityClicked: true,
      });
  };
  
  render() {
    let {
      groups,
      itemsbygroupid,
      cart,
      stores,
      counter,
      itemData,
      order_id,
      open,
      backupStores,
      isLoading,
      customer,
      selectedStore,
    } = this.props;
    const { suggestions, showSuggestions } = this.state;
    let { modifiers, showCutomerPanel, fullAddress } = this.state;
    // let FullAddressForStoreSelection = (this.state.building ? this.state.building : "") + " " + (this.props.tradeAreaName ? this.props.tradeAreaName : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "")
    let FullAddressForStoreSelection = (this.state.building ? this.state.building : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "")
    
    let todayDateTime = new Date();

    // 

    let m = moment(todayDateTime)
    let momentDate = m.format()
    let momentUpdate = momentDate.split("+")[0]
    let today = momentUpdate

    // 


    // let today = todayDateTime.toISOString().split(".")[0];
    let maxDateTime = todayDateTime.setDate(todayDateTime.getDate() + 1);
    // 

    let maxMomentDate = m.add(1, "days")
    let maxMomentDateUpdate = maxMomentDate.format().split("+")[0]
    let maxDate = maxMomentDateUpdate

    // 

    // let maxDate = new Date(maxDateTime).toISOString().split(".")[0];
    // let data: any[] = (cart && cart.length > 0) ? JSON.parse(cart) : [];
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }

    let cities_arr: any = []
    let zones_arr: any = []
    // let areas_arr: any = []
    this.props.cities_portal && this.props.cities_portal.map((city: any) => {
      cities_arr.push({ label: city.name, value: city.id })
    })

    this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
      zones_arr.push({ label: zone.delivery_zone_name, value: zone.id })
    })

    // this.props.tradeAreas_portal && this.props.tradeAreas_portal.map((area: any) => {
    //   areas_arr.push({ label: area.area_name, value: area.id, area: JSON.stringify(area) })
    // })

    if (this.props.houseNo == '') {
      this.selectRef && this.selectRef.select.clearValue();
      let o;
      let obj = document as any;
      obj.getElementById("clear") ? obj.getElementById("clear").selectedIndex = 0 : o = ''
    }

    let check = true
    if (this.state.changeLocation) {
      // if (this.props.storesOption.length == 1 && !_.isEmpty(this.state.area) && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress)) {
        if (this.props.storesOption.length == 1 && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress)) {
        check = true
      } else {
        check = false
      }
    } else {
      check = true
    }
    let { brands } = this.props;
    let { city, store} = this.props;
    return (
      <div className="page">
        <CheckChanges path="/menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center py-2">
                  <h4 className="br-headtitle mt-2">Choose Items</h4>
                  {!_.isEmpty(customer) &&
                    <div className="d-flex bg-white p-2">
                      <div className="customer-items-set">
                        <span><b>Customer Name:</b> {customer.login_name}</span>
                        <span><b>Customer Phone:</b> {customer.phone_number}</span>
                      </div>
                      <div style={{ marginLeft: "10px" }} className="customer-items-set">
                        <div><b>Total Revenue:</b> {(customer.totalRevenue ? priceunit : "AED" + " "+ "0")}{ customer && customer.totalRevenue ? parseFloat(customer.totalRevenue).toFixed(2) : '.00' }</div>
                        <div><b>Last order date:</b> {customer?.lastOrderDate && customer?.lastOrderDate[0]}{' '} {customer?.lastOrderDate && customer?.lastOrderDate[1]}</div>
                      </div>
                      <div style={{ marginLeft: "10px" }} className="customer-items-set">
                        <span><b>Member Since:</b> {moment(customer.date_created).local().format('YYYY-MM-DD').split(' ')}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </header>
            {/* <section className="dashboard-counts no-padding mt-4">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow">

                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            <div className="icon bg-red"><i className="fa fa-clipboard"></i></div>
                                            <div className="title"><span>Total<br />Orders</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.totalOrders / 100}%`, height: "4px" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-red"></div>
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.totalOrders}</strong></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            <div className="icon bg-orange"><i className="fa fa-shopping-cart"></i></div>
                                            <div className="title"><span>Processing<br />Orders</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.processingOrders / 100}%`, height: "4px" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-orange"></div>
                                                </div>
                                            </div>
                                            <div><strong>{counter.processingOrders}</strong></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Ready<br />Orders</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.completeOrders / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.completeOrders}</strong></div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center">
                                            <div className="icon bg-blue"><i className="fa fa-ticket"></i></div>
                                            <div className="title"><span>Cancelled<br />Orders</span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${counter.cancelOrders / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                                                </div>
                                            </div>
                                            <div className="number"><strong>{counter.cancelOrders}</strong></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section> */}
            <div className="lmp">
              <section className="dashboard-counts no-padding-bottom py-0">
                <div className="container-fluid">
                  <div className="row bg-white has-shadow p-2">
                    <div className="col-sm-3">
                      <div className="item d-flex align-items-center no-padding">
                        <div>
                          <input
                            id="radioDelivery"
                            type="radio"
                            checked={this.state.orderType === "Delivery"}
                            value="Delivery"
                            onChange={() => {
                              this.props.saveStore({})
                              this.setState({ isPickupClicked: false, isPickUpSubmitClicked: false })
                              this.setState({ orderType: "Delivery" });
                            }}
                            name="Delivery"
                            className="radio-template"
                          />
                          <label htmlFor="radioDelivery">Delivery</label>
                        </div>
                        <div className="icon bg-blue ml-3">
                          <i className="fa fa-motorcycle"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="item d-flex align-items-center no-padding">
                        <div>
                          <input
                            id="radioPickup"
                            type="radio"
                            checked={this.state.orderType === "Pickup"}
                            value="Pickup"
                            onChange={() => {
                              this.props.saveStore({})
                              // this.setState({ isPickupClicked: true })
                              // this.setState({ isPickupClicked: true, isPickUpSubmitClicked: true })
                              // this.setState({ isClicked: false })
                              // let obj = document as any
                              // let o;
                              // obj.getElementById("clearPickupStore") ? obj.getElementById("clearPickupStore").selectedIndex = 0 : o = ''
                              this.setState({ orderType: "Pickup" });
                            }}
                            name="Pickup"
                            className="radio-template"
                          />
                          <label htmlFor="radioPickup">Pickup</label>
                        </div>
                        <div className="icon bg-red ml-3">
                          <i className="fa fa-suitcase"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <div
                          onClick={() => {
                            this.setState({ paymentMethod: 'Cash' });
                          }}
                          // onClick={() => {
                          //   this.setState({ paymentMethod: this.state.orderType == 'Delivery' ? "Cash On Delivery" : 'Cash On Pickup' });
                          // }}
                        >
                          <input
                            style={{ fontSize: "16px" }}
                            type="radio"
                            name="paymentMethod"
                            checked={this.state.paymentMethod == "Cash"}
                            value={this.state.paymentMethod}
                            className="radio-template"
                            id="nowMobile"
                          />
                          <label style={{ marginLeft: "10px" }}>Cash </label>
                        </div>

                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div
                      //  onClick={() => {
                      //       this.setState({ paymentMethod: 'Cash' });
                      //     }}
                      // onClick={() => {
                      //   this.setState({ paymentMethod: 'Card' });
                      // }}
                      onClick={() => {
                        this.setState({
                          paymentMethod: this.state.orderType === 'Delivery' ? 'Card On Delivery' : 'Card On Pickup'
                        });
                      }}
                      >
                        <input
                          style={{ fontSize: "16px" }}
                          type="radio"
                          name="paymentMethod"
                          checked={this.state.paymentMethod == 'Card On Delivery' || this.state.paymentMethod == 'Card On Pickup'}
                          value={this.state.paymentMethod}
                          className="radio-template"
                          id="nowMobile"
                        />
                        {/* {console.log("PaymentMethod9999", this.state.paymentMethod)} */}
                        {/* <label style={{ marginLeft: "10px" }}>{this.state({ paymentMethod: this.state.orderType == 'Delivery' ? "Card On Delivery" : 'Card On Pickup' })}</label> */}
                        <label style={{ marginLeft: '10px' }}>
                          {
                            this.state.orderType === 'Delivery'
                            ? 'Card On Delivery' : 'Card On Pickup'
                          }
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {this.state.orderType == "Delivery" && (
                <section className="pt-2 pb-2">
                  <div className="container-fluid">
                    <div id="accordion">
                      <div className="card-header no-padding" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Customer Address Information
                          </button>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        {!this.props.is_saved_address_selected ?
                          <div className="project bg-white has-shadow ">

                            <div style={{ padding: "unset" }} className="row mb-0">

                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    City/Town{" "}
                                  </label>
                                  <span className="text-danger">*</span>
                                  {/* <input
                                className="form-control form-control-sm"
                                name="Block"
                                value={this.props.Block}
                                onChange={this.props.handleMenuInput}
                                placeholder="Enter Block/Street/Sector"
                              /> */}
                                  <Select
                                    name="cities"
                                    value={{ label: this.props.cityName ? this.props.cityName : 'Select...', value: 'Select...' }}
                                    options={cities_arr}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    isLoading={cities_arr.length == 0 ? true : false}
                                    onChange={
                                      this.handleCityInput
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Trade Zones{" "}
                                  </label>
                                  <span className="text-danger">*</span>
                                  {/* <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.area}
                                onChange={(e) => {
                                  this.setState({ area: e.target.value })
                                }}
                                placeholder="Enter Delivery Address"
                              /> */}
                                  <Select
                                    ref={ref => { this.selectRef2 = ref; }}
                                    name="zones"
                                    value={{ label: this.props.tradeZoneName ? this.props.tradeZoneName : 'Select...', value: '' }}
                                    options={this.props.cityName ? zones_arr : []}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    isLoading={zones_arr.length == 0 ? true : false}
                                    onChange={
                                      this.handleZone
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div className="col-4">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Block/Street/Sector{" "}
                                  </label>
                                  <span className="text-danger">*</span>
                                  <input
                                className="form-control form-control-sm"
                                name="City"
                                value={this.props.City}
                                onChange={this.props.handleMenuInput}
                                placeholder="Enter City/Town"
                              />
                                  <Select
                                    ref={ref => { this.selectRef3 = ref; }}
                                    value={{ label: this.props.tradeAreaName ? this.props.tradeAreaName : 'Select...', value: '' }}
                                    name="areas"
                                    options={this.props.tradeZoneName != '' ? areas_arr : []}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select"
                                    isLoading={areas_arr.length == 0 ? true : false}
                                    onChange={
                                      this.handleArea
                                    }
                                  />
                                </div>
                              </div> */}

                            <div className="col-4">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Trade Area{" "}
                                  </label>
                                  <span className="text-danger">*</span>
                                  {/* <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.area}
                                onChange={(e) => {
                                  this.setState({ area: e.target.value })
                                }}
                                placeholder="Enter Delivery Address"
                              /> */}
                                  <Select
                                    ref={ref => { this.selectRef2 = ref; }}
                                    name="zones"  
                                    value={{label:this.props.tradeAreaName, value:this.props.tradeAreaValue}}
                                    options={this.props.tradeAreas}
                                    className="text-capitalize basic-multi-select mt-2"
                                    classNamePrefix="select" 
                                    //isLoading={zones_arr.length == 0 ? true : false}
                                    onChange={
                                      this.handleTradeArea
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <label className="form-control-label">
                                  Select Brand
                                </label>
                                <span className="text-danger">*</span>
                                <select
                                  name="brand"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select brand"
                                  onChange={this.handleBrand}
                                >
                                  <option value="">Select Brand</option>
                                  {brands &&
                                    brands.map(
                                      (brand: any, index: any) =>
                                        brand.is_active == 1 && (
                                          <option key={index} {...(brand.brand_id == this.state.brand_id) && { selected: true }} value={brand.brand_id}>
                                            {brand.brand_name}
                                          </option>
                                        )
                                    )
                                    }
                                </select>
                              </div>
                              <div className="col-8">
                                <div className="form-group">  
                                  <label className="form-control-label">
                                    Extra Details{" "}
                                  </label>
                                  <input
                                    style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                    className="form-control form-control-sm"
                                    name="houseNo"
                                    onKeyDown={this.blockInvalidChar}
                                    value={this.state.building}
                                    defaultValue={this.state.building}
                                    onChange={(e) => {
                                      this.setState({ building: e.target.value })
                                    }}
                                    placeholder="Enter Extra Details"
                                  />
                                </div>
                              </div>
                              {/* <div className="col-4">
                                <label className="form-control-label">
                                  Select Brand
                                </label>
                                <select
                                  name="brand"
                                  className="form-control text-capitalize mt-2"
                                  required
                                  data-msg="Please select brand"
                                  onChange={this.handleBrand}
                                >
                                  <option value="">Select Brand</option>
                                  {brands &&
                                    brands.map(
                                      (brand: any, index: any) =>
                                        brand.is_active == 1 && (
                                          <option key={index} {...(brand.brand_id == this.state.brand_id) && { selected: true }} value={brand.brand_id}>
                                            {brand.brand_name}
                                          </option>
                                        )
                                    )}
                                </select>
                              </div> */}
                              {/* <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Building Name/Building Number{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.building}
                                onChange={(e) => {
                                  this.setState({ building: e.target.value })
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div> */}

                              {/* <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Villa/Flat No.{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                value={this.state.room}
                                onChange={(e) => {
                                  this.setState({ room: e.target.value })
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div> */}

                            </div>
                            {/* <div className="row no-padding">
                            <div className="col-9">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select Your Location for Delivery
                                </label>
                                <input
                                  autoComplete='off'
                                  style={{ height: "calc(1.8125rem + 10px)", borderRadius: '5px' }}
                                  name="Additional_address_info"
                                  className="form-control form-control-sm search-wrapper"
                                  value={this.state.googlesearch}
                                  onChange={this.handleSearch}
                                  placeholder="Enter your full address"
                                />
                                {this.state.placesSuggestion.length > 0 && <div className="address-suggestion mt-4">
                                  <ul style={{ padding: "10px" }}>
                                    {this.state.placesSuggestion.map((place: any) => {
                                      return (
                                        <>
                                          <li className='suggestions' style={{ listStyle: "none" }}
                                            onClick={() => this.onPlaceClick(place)}
                                          >
                                            <span className="upper-text">
                                              <strong>
                                                {place.structured_formatting.main_text}
                                              </strong>
                                            </span>
                                            <span className="bottom-text">
                                              {place.structured_formatting.secondary_text}
                                            </span>
                                          </li>
                                        </>
                                      )
                                    })
                                    }
                                  </ul>
                                </div>}
                              </div>
                            </div>
                            <div className="col-6">
                              <label className="form-control-label">
                                Select Brand
                              </label>
                              <select
                                name="brand"
                                className="form-control text-capitalize mt-2"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleBrand}
                              >
                                <option value="">Select Brand</option>
                                {brands &&
                                  brands.map(
                                    (brand: any, index: any) =>
                                      brand.is_active == 1 && (
                                        <option key={index} value={brand.brand_id}>
                                          {brand.brand_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div> */}
                          </div> :

                          <div style={{ marginBottom: '15px' }} className="project bg-white has-shadow">
                            {
                              <div className="project bg-white has-shadow ">
                                {/* <div className="row no-padding">
                                  <div className="col-9">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Select Your Location for Delivery
                                      </label>
                                      <input
                                        autoComplete='off'
                                        style={{ height: "calc(1.8125rem + 10px)", borderRadius: '5px' }}
                                        name="Additional_address_info"
                                        className="form-control form-control-sm search-wrapper"
                                        value={this.state.googlesearch}
                                        onChange={this.handleSearch}
                                        placeholder="Enter your full address"
                                      />
                                      {this.state.placesSuggestion.length > 0 && <div className="address-suggestion mt-4">
                                        <ul style={{ padding: "10px" }}>
                                          {this.state.placesSuggestion.map((place: any) => {
                                            return (
                                              <>
                                                <li className='suggestions' style={{ listStyle: "none" }}
                                                  onClick={() => this.onPlaceClick(place)}
                                                >
                                                  <span className="upper-text">
                                                    <strong>
                                                      {place.structured_formatting.main_text}
                                                    </strong>
                                                  </span>
                                                  <span className="bottom-text">
                                                    {place.structured_formatting.secondary_text}
                                                  </span>
                                                </li>
                                              </>
                                            )
                                          })
                                          }
                                        </ul>
                                      </div>}
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <label className="form-control-label">
                                      Select Brand
                                    </label>
                                    <select
                                      name="brand"
                                      className="form-control text-capitalize mt-2"
                                      required
                                      data-msg="Please select brand"
                                      onChange={this.handleBrand}
                                    >
                                      <option value="">Select Brand</option>
                                      {brands &&
                                        brands.map(
                                          (brand: any, index: any) =>
                                            brand.is_active == 1 && (
                                              <option key={index} value={brand.brand_id}>
                                                {brand.brand_name}
                                              </option>
                                            )
                                        )}
                                    </select>
                                  </div>
                                </div> */}
                                <div style={{ padding: "unset" }} className="row mb-0">

                                  <div className="col-4">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        City/Town{" "}
                                      </label>
                                      <span className="text-danger">*</span>
                                      {/* <input
                                 className="form-control form-control-sm"
                                 name="Block"
                                 value={this.props.Block}
                                 onChange={this.props.handleMenuInput}
                                 placeholder="Enter Block/Street/Sector"
                               /> */}
                                      <Select
                                        name="cities"
                                        value={{ label: this.props.cityName ? this.props.cityName : 'Select...', value: 'Select...' }}
                                        options={cities_arr}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={cities_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleCityInput
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Area/Place{" "}
                                      </label>
                                      <span className="text-danger">*</span>
                                      {/* <input
                                 style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                 className="form-control form-control-sm"
                                 name="houseNo"
                                 onKeyDown={this.blockInvalidChar}
                                 value={this.state.area}
                                 onChange={(e) => {
                                   this.setState({ area: e.target.value })
                                 }}
                                 placeholder="Enter Delivery Address"
                               /> */}
                                      <Select
                                        ref={ref => { this.selectRef2 = ref; }}
                                        name="zones"
                                        value={{ label: this.props.tradeZoneName ? this.props.tradeZoneName : 'Select...', value: '' }}
                                        options={this.props.cityName ? zones_arr : []}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={zones_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleZone
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="col-4">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Block/Street/Sector{" "}
                                      </label>
                                      <span className="text-danger">*</span>
                                      <input
                                 className="form-control form-control-sm"
                                 name="City"
                                 value={this.props.City}
                                 onChange={this.props.handleMenuInput}
                                 placeholder="Enter City/Town"
                               />
                                      <Select
                                        ref={ref => { this.selectRef3 = ref; }}
                                        value={{ label: this.props.tradeAreaName ? this.props.tradeAreaName : 'Select...', value: '' }}
                                        name="areas"
                                        options={this.props.tradeZoneName != '' ? areas_arr : []}
                                        className="text-capitalize basic-multi-select mt-2"
                                        classNamePrefix="select"
                                        isLoading={areas_arr.length == 0 ? true : false}
                                        onChange={
                                          this.handleArea
                                        }
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-8">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        Delivery Address{" "}
                                      </label>
                                      <span className="text-danger">*</span>
                                      <input
                                        style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                        className="form-control form-control-sm"
                                        name="houseNo"
                                        onKeyDown={this.blockInvalidChar}
                                        value={this.state.building}
                                        onChange={(e) => {
                                          this.setState({ building: e.target.value })
                                        }}
                                        placeholder="Enter Delivery Address"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <label className="form-control-label">
                                      Select Brand
                                    </label>
                                    <select
                                      name="brand"
                                      className="form-control text-capitalize mt-2"
                                      required
                                      data-msg="Please select brand"
                                      onChange={this.handleBrand}
                                    >
                                      <option value="">Select Brand</option>
                                      {brands &&
                                        brands.map(
                                          (brand: any, index: any) =>
                                            brand.is_active == 1 && (
                                              <option key={index} value={brand.brand_id}>
                                                {brand.brand_name}
                                              </option>
                                            )
                                        )}
                                    </select>
                                  </div>
                                  {/* <div className="col">
                             <div className="form-group">
                               <label className="form-control-label">
                                 Building Name/Building Number{" "}
                               </label>
                               <span className="text-danger">*</span>
                               <input
                                 style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                 className="form-control form-control-sm"
                                 name="houseNo"
                                 onKeyDown={this.blockInvalidChar}
                                 value={this.state.building}
                                 onChange={(e) => {
                                   this.setState({ building: e.target.value })
                                 }}
                                 placeholder="Enter Delivery Address"
                               />
                             </div>
                           </div> */}

                                  {/* <div className="col">
                             <div className="form-group">
                               <label className="form-control-label">
                                 Villa/Flat No.{" "}
                               </label>
                               <span className="text-danger">*</span>
                               <input
                                 style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                 className="form-control form-control-sm"
                                 name="houseNo"
                                 onKeyDown={this.blockInvalidChar}
                                 value={this.state.room}
                                 onChange={(e) => {
                                   this.setState({ room: e.target.value })
                                 }}
                                 placeholder="Enter Delivery Address"
                               />
                             </div>
                           </div> */}

                                </div>
                              </div>
                            }
                            {/* <div className='form-group row no-padding ml-3'>
                              <div className='col pt-3'>
                                <p onClick={() => { this.setState({ changeLocation: !this.state.changeLocation }) }} style={{ color: "#DC3545", cursor: 'pointer' }}>{this.state.changeLocation ? 'Select from saved addresses' : 'Select new location'}</p>
                              </div>
                            </div> */}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {this.state.orderType == "Pickup" && (
                <section className="pt-2 pb-2">
                  <div className="container-fluid">
                    <div id="accordion">
                      <div className="card-header no-padding" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Customer Address Information
                          </button>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="project bg-white has-shadow ">
                          <div className="row mb-0 p-1">
                            
                            <div className="col-3">
                              <label className="form-control-label">
                                Select Brand
                              </label>
                              <span className="text-danger">*</span>
                              <select
                                name="brand"
                                className="form-control text-capitalize"
                                required
                                data-msg="Please select brand"
                                onChange={this.handleBrand}
                              >
                                 <option value="">Select Brand</option>
                                  {brands &&
                                    brands.map(
                                      (brand: any, index: any) =>
                                        brand.is_active == 1 && (
                                          <option key={index} {...(brand.brand_id == this.state.brand_id) && { selected: true }} value={brand.brand_id}>
                                            {brand.brand_name}
                                          </option>
                                        )
                                    )
                                    }
                              </select>
                            </div>

                            <div className="col-9">
                            <div className="form-group">
                              <label className="form-control-label">City/Areas</label>
                              <span className="text-danger">*</span>

                              {/* Input field for typing */}
                              <input
                                className="form-control form-control-sm"
                                name="City"
                                value={this.state.pickupCity}
                                onChange={this.handleInputChangeCity}
                                placeholder="Enter City/Town"
                                style={{ height: "41px" }}
                              />
                              {/* Dropdown for suggestions */}
                              {showSuggestions && (
                                  <ul className="city-dwn" style={{ cursor: 'pointer', maxHeight: '150px', overflowY: 'auto' }}>
                                    {suggestions.map((city:any, index:any) => (
                                      <li
                                        style={{color: `000`}}
                                        key={index}
                                        value={city.id}
                                        className="dropdown-item"
                                        onClick={() => this.handleSelectStore(city.name)}
                                      >
                                        {city.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </div>
                            </div>
                          </div>
                          {/* <div className="row no-padding">
                            <div className="col">
                              <div className="form-group">
                                <button
                                  onClick={this.pickUpFormatAddress}
                                  disabled={!this.isAddressReady("pickup")}
                                  className="btn-warning btn mt-2"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {/* <section className="pt-2 no-padding-bottom">
              <div className="container-fluid">
                <div className="project bg-white has-shadow ">
                  <div className="row mb-0 p-1">
                    <div className="col-3">
                    <div className="form-group">
                      <label className="form-control-label">House/Flat/Plot </label>
                      <input className="form-control form-control-sm" name="houseNo" value={this.props.houseNo} onChange={this.props.handleMenuInput} placeholder="Enter House/Flat/Plot"/>
                    </div>
                    </div>
                    <div className="col-3">
                    <div className="form-group">
                      <label className="form-control-label">Block/Street </label>
                      <input className="form-control form-control-sm" name="Block" value={this.props.Block} onChange={this.props.handleMenuInput} placeholder="Enter Block/Street"/>
                    </div>
                    </div>
                    <div className="col-3">
                    <div className="form-group">
                      <label className="form-control-label">Area/Place </label>
                      <input className="form-control form-control-sm" name="Area" value={this.props.Area} onChange={this.props.handleMenuInput} placeholder="Enter Area/Road/Place"/>
                    </div>
                    </div>
                    <div className="col-3">
                    <div className="form-group">
                      <label className="form-control-label">City/Town    </label>
                      <input className="form-control form-control-sm" name="City" value={this.props.City} onChange={this.props.handleMenuInput} placeholder="Enter City/Town"/>
                    </div>
                    </div>
                   
                  </div>
                  <div className="row no-padding">
                    <div className="col">
                  <div className="form-group">
                    <label className="form-control-label">Additional address info</label>
                      <input name="Additional_address_info" className="form-control form-control-sm" value={this.props.Additional_address_info} onChange={this.props.handleMenuInput} placeholder="Enter Additional Address Info"/>

                  <button onClick={this.FormatAddress} className="btn-warning btn mt-2">Submit</button>

                  <div >
                  {this.state.lat && this.state.lng &&
                  <span>{'  '}Lat:{this.state.lat} Lng: {this.state.lng}</span>
                  }
                  </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>

            </section> */}

              <section className="projects pt-2 no-padding-bottom">
                <div className="container-fluid">
                  <div className="project">
                    {this.state.orderType == "Delivery" && (
                      <div className="row bg-white has-shadow p-1">
                        <div className="col-lg-3 d-flex align-items-center justify-content-between">
                          <div className="project-title d-flex align-items-center">
                            <div style={{ wordBreak: 'break-word', overflow: "hidden" }} className="form-group">
                              <label className="form-control-label">
                                Delivery Address
                              </label>
                              <span className="text-danger">*</span><br />


                              {/* {
                              
                              !_.isEmpty( this.props.selectedAddress) &&`${this.props.selectedAddress.extra_details + " " + (this.props.selectedAddress.area_id && this.props.selectedAddress.area_id.area_name) + " " + (this.props.selectedAddress.zone_id && this.props.selectedAddress.zone_id.delivery_zone_name) + " " + (this.props.selectedAddress.city_id && this.props.selectedAddress.city_id.name)}`
                             

                              } */}
                              {

                                // (`${(this.state.building ? this.state.building : "") + " " + (this.props.tradeAreaName ? this.props.tradeAreaName : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "")
                                (`${(this.state.building ? this.state.building : "") + " " + (this.props.tradeZoneName ? this.props.tradeZoneName : "") + ", " + (this.props.cityName ? this.props.cityName : "")
                                  // + " " + (this.state.zone_id && this.state.zo) + " " + (this.props.selectedAddress.city_id && this.props.selectedAddress.city_id.name)
                                }`)
                              }
                              {/* <input
                              id="delivery_address"
                              type="text"
                              // value={this.props.delivery_address}
                              value={this.props.houseNo + ' ' + this.props.tradeZoneName + ' ' + this.props.tradeAreaName + ' ' + this.props.cityName}
                              name="delivery_address"
                              required
                              data-msg="Please enter address"
                              className="input-material"
                              onChange={this.props.handleMenuInput}
                            /> */}
                            </div>
                            {/* <div style={{ width: '21rem' }} className="form-group">
                          <label className="form-control-label">
                            Geo Coded Address
                              </label>
                          <Map findLocalStores={this.props.findLocalStores} handleGeoCodeAddress={this.props.handleGeoCodeAddress} handleAddressInput={this.props.handleAddressInput} />
                        </div> */}
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center">
                          <div className="project-title d-flex align-items-center">
                            <div className="form-group">
                              <label className="form-control-label">
                                Special Instructions
                              </label>
                              <input
                                id="special_inst"
                                type="text"
                                name="special_inst"
                                required
                                data-msg="Please enter special Instructions"
                                className="input-material"
                                onChange={this.props.handleMenuInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center">
                          <div className="form-group col">
                            <div className="row-wrapper">
                              <div>
                                {/* {this.props.storesOption && this.props.storesOption.length == 1 && !_.isEmpty(this.state.area) && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress) ? (
                                <label className="form-control-label green-text">
                                  Store Detected
                                </label>
                              ) : (
                                <label className="form-control-label">
                                  Select Nearest Store
                                </label>
                              )} */}
                                { this.state.changeLocation  ?
                                //this.props.storesOption.length == 1 && !_.isEmpty(this.state.area) && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress)) {
                                  // this.props.storesOption && this.props.storesOption.length == 1 && !_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName) && !_.isEmpty(this.props.cityName) && !_.isEmpty(FullAddressForStoreSelection) && !_.isEmpty(this.state.building)  ? (
                                    this.props.matchingStores && this.props.matchingStores.length > 0 && !_.isEmpty(this.props.tradeZoneName) && !_.isEmpty(this.props.cityName) && !_.isEmpty(FullAddressForStoreSelection) && !_.isEmpty(this.state.building)  ? (
                                    <label className="form-control-label green-text">
                                      Store Detected
                                    </label>
                                  ) : (
                                    <label className="form-control-label">
                                      Select Nearest Stores
                                    </label>
                                  ) : this.props.matchingStores && this.props.matchingStores.length > 0  && !_.isEmpty(this.state.building) ? (
                                    <label className="form-control-label green-text">
                                      Store Detected
                                    </label>
                                  ) : (
                                    <label className="form-control-lab  el">x
                                      Select Nearest Store
                                    </label>
                                  )
                                }
                                <span className="text-danger">*</span>
                              </div>
                              <div>
                                <label
                                  style={{ fontWeight: 700 }}
                                  className="form-control-label"
                                >
                                  EDT: {this.state.promiseTime} {"Min"}
                                </label>
                              </div>
                            </div>

                            {/* <select
                            name="store"
                            className="form-control mr-3"
                            onChange={this.handleSaveStore}
                          >
                            <option value="">Select Store</option>
                            {stores &&
                              stores.map((store: any, index: any) => (
                                <option onClick={() =>
                                  this.props.saveSelectStoreId(store)
                                } key={index} value={store.store_id}>
                                  {store.store_name}
                                </option>
                              ))}
                          </select> */}
                            {this.state.orderType == "Delivery" && 
                            <Select
                              ref={ref => { this.selectRef = ref; }}
                              value={{ label: this.props.selectedStore.store_name, value: this.props.selectedStore.store_id }}
                              // value={this.props.matchingStores.map((store:any)=>{
                              //   return label: store.store_name value: store_store_id
                              // })} 
                              name="stores"
                              // options={!this.state.changeLocation ? !_.isEmpty(this.state.area) && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress) ? this.props.storesOption : [] : this.props.storesOption}
                              options={
                              this.props.matchingStores.map((store: any) => ({
                                      label: store.store_name,
                                      value: store.store_id
                                    }))
                                
                              }
                              // options={this.props.storesOption}
                              className="text-capitalize basic-multi-select mt-2"
                              classNamePrefix="select"
                              // onChange={this.handleSaveStore}
                              onChange={(e: any, i: any) =>

                              this.handleStoresInputChange(e, i)
                            }
                          />
                            }

                            {this.state.orderType == "Pickup" && <Select
                              ref={ref => { this.selectRef = ref; }}
                              value={{ label: this.props.selectedStore.store_name, value: this.props.selectedStore.store_id }}
                              name="stores"
                              // options={!this.state.changeLocation ? !_.isEmpty(this.state.area) && !_.isEmpty(this.state.building) && !_.isEmpty(this.state.room) && !_.isEmpty(this.state.fullAddress) ? this.props.storesOption : [] : this.props.storesOption}
                              options={(!_.isEmpty(this.state.brand_id) && !_.isEmpty(this.state.building)) ? this.props.storesOption : []}
                              // options={this.props.storesOption}
                              className="text-capitalize basic-multi-select mt-2"
                              classNamePrefix="select"
                              // onChange={this.handleSaveStore}
                              onChange={(e: any, i: any) =>

                                this.handleStoresInputChange(e, i)
                              }
                            />}
                          </div>
                        </div>
                        {/* <div className="col-lg-3 d-flex align-items-center">
                          <div className="form-group col">
                            <div className="row-wrapper">
                              <div>
                                <label className="form-control-label">
                                  Select Backup Store
                                </label>

                                <span className="text-danger">*</span>
                              </div>
                              <div>
                                <label
                                  style={{ fontWeight: 700 }}
                                  className="form-control-label"
                                >
                                  EDT: {this.state.backupStorePromiseTime} {"Min"}
                                </label>
                              </div>
                            </div>

                            <select
                              id='clear'
                              name="store"
                              className="form-control mr-3"
                              onChange={this.handleSaveStore}
                            >
                              <option value="">Select Store</option>
                              {!_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName) && this.state.isClicked && !_.isEmpty(this.props.houseNo) && backupStores &&
                                backupStores.map((store: any, index: any) => (
                                  <option
                                    onClick={() =>
                                      this.props.saveSelectStoreId(store)
                                    }
                                    key={index}
                                    value={store.backupStoreId.store_id}
                                  >
                                    {store.backupStoreId.store_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div> */}
                      </div>
                    )}

                    {this.state.orderType == "Pickup" && (
                      <div className="row bg-white has-shadow p-1">
                        <div className="col-lg-3 d-flex align-items-center">
                          <div className="project-title d-flex align-items-center">
                            <div className="form-group">
                              <label className="form-control-label">
                                Special Instructions
                              </label>
                              <input
                                id="special_inst"
                                type="text"
                                name="special_inst"
                                required
                                data-msg="Please enter special Instructions"
                                className="input-material"
                                onChange={this.props.handleMenuInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex align-items-center">
                          <div className="form-group col">
                            <div className="row-wrapper">
                            <div>
                              {((stores && stores.length >= 1) &&
                                stores.filter((store:any) => store.city === this.state.pickupCity).length >= 1) ? (
                                <label className="form-control-label green-text">
                                  Store Detecteds
                                </label>
                              ) : (
                                <label className="form-control-label">
                                  Select Pickup Stores
                                </label>
                              )}
                              <span className="text-danger">*</span>
                            </div>

                              {/* {!_.isEmpty(this.props.selectedStore) &&
                                            <React.Fragment>
                                              {this.state.storeOpenFlag &&
                                                <div className="online">
                                                    <div className="online-indicator"></div>
                                                    <span>Open</span>
                                                    </div>
                                                      }                                
                                                  </React.Fragment>
                                            }
                                            {!this.state.storeOpenFlag &&
                                              <div className="offline">
                                              <div className="offline-indicator"></div>
                                              <span>Close</span> 
                                              </div> 
                                          
                                            } */}
                            </div>

                            {/* <select
                                id='clearPickupStore'
                                name='store'
                                className='form-control mr-3'
                                onChange={this.handleSaveStore}
                              >
                             
                                  <>
                                    <option value=''>Select Store</option>
                                    {this.state.cityClicked && this.state.selectedStores !== '' ? 
                                    (
                                            
                                            this.state.selectedStores.map((store:any, index:any) => (
                                              <option
                                                onClick={() => {
                                                  this.props.saveSelectStoreId(store);
                                                }}
                                                key={index}
                                                value={store.store_id}
                                              >
                                                {store.store_name}
                                              </option>
                                            ))
                                          ) : ''
                                    }
                                  </>
                            </select> */}
                            {/* Existing Brand Data */}
                            
                            <select
                              id='clearPickupStore'
                              name="store"
                              className="form-control mr-3"
                              onChange={(e) => {
                                e.preventDefault();
                                if (e.target.value === '') {
                                   toast.error("City is empty. Please select a city first.");
                                } else {
                                  this.handleSaveStore(e);
                                }
                              }}
                            >
                              <option value="">Select Store</option>
                              {stores && (
                                this.state.pickupCity !== '' ? (
                                  stores
                                    .map((store: any, index: any) => (
                                      <option
                                        key={index}
                                        value={store.store_id}
                                        onClick={() => {
                                          if (!this.state.pickupCity) {
                                             toast.error("City is empty. Please select a city first.");
                                          } else {
                                            this.props.saveSelectStoreId(store)
                                            // Handle the click event for this option, e.g., this.props.saveSelectStoreId(store);
                                          }
                                        }}
                                      >
                                        {store.store_name}
                                      </option>
                                    ))
                                ) : (
                                  stores && this.state.pickupCity == '' && stores.map((store:any, index:any) => (
                                    <option
                                      key={index}
                                      value={store.store_id}
                                      onClick={() => {
                                        if (!this.state.city) {
                                           toast.error("City is empty. Please select a city first.");
                                        } else {
                                          this.props.saveSelectStoreId(store)
                                          // Handle the click event for this option, e.g., this.props.saveSelectStoreId(store);
                                        }
                                      }}
                                    >
                                      {store.store_name}
                                    </option>
                                  ))
                              )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
            <section className="updates no-padding">

              {/* !_.isEmpty(customer) && !_.isEmpty(selectedStore && this.state.orderType !== "Pickup" && !_.isEmpty(this.props.tradeZoneName) && !_.isEmpty(this.props.tradeAreaName) */}
              {(!_.isEmpty(customer) && !_.isEmpty(selectedStore)) ?
                <div className="container-fluid">
                  <div className="row item-statics-list">
                    {/* <!-- Groups --> */}
                    <div className="statistics col-lg-2 col-12 listScroll">
                      <div style={{ textAlign: 'center' }}>
                        <Loader
                          type="ThreeDots"
                          // color="#dc3545"
                          color = "#d9b165"
                          visible={isLoading}
                          height={80}
                          width={80}
                        // timeout={3000} //3 secs
                        />
                      </div>
                      {groups && isLoading === false &&
                        groups.map((obj: any, index: any) => {
                          return (
                            // <div className="statistic d-flex justify-content-center has-shadow img-fluid" key={index} style={{ cursor: "pointer" }} onClick={() => { this.props.menuItemsListByGroupsID(obj.group_id) }}>
                            <div
                              className={
                                obj.group_id == this.state.active_group_id
                                  ? this.getStyle()
                                  : "statistic card-list-statics d-flex justify-content-center bg-white has-shadow img-fluid"
                              }
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.menuItemsListByGroupsID(obj.group_id)
                              }
                            >
                              <h6>{obj.group_name}</h6>
                            </div>
                          );
                      })}
                    </div>
                    <div className="statistics col-lg-3 col-12 listScroll menu-item-card">
                      {itemsbygroupid.length > 0 ? (
                        itemsbygroupid.map((obj: any, index: any) => {
                          return (
                            <div
                              className="card"
                              style={{ padding: "20px", cursor: "pointer" }}
                            >
                              <div
                                className="row d-flex align-items-center"
                                onClick={() => this.getItemDetail(obj)}
                              >


                                <div className="d-flex ">
                                  {obj.menu_item_id && obj.menu_item_id !== "" && JSON.parse(obj.item_size)[0].image_url.length > 0 ? (
                                    <img
                                      style={{ width: "50px", height: "50px" }}
                                      // src={`${BASE_URL}${JSON.parse(obj.item_size)[0].image_url}`}
                                      src={
                                        (function() {
                                          const url = JSON.parse(obj.item_size)[0].image_url;
                                          // const firstHttpsIndex = url.indexOf("https://");
                                          // const secondHttpsIndex = url.indexOf("https://", firstHttpsIndex + 1);
                                          // const url = "https://kyochoncrmstg.simplexts.com.au:3007/https://www.baskinrobbinsmea.com/HDImages/IMAGE_002.jpg";
                                          const search = "https://www.baskinrobbin";
                                          const index = url.indexOf(search);
                                          
                                          if (index !== -1) {
                                            // If the search string is found, create a new string starting from it
                                            const newUrl = url.substring(index);
                                            // console.log("newURL", newUrl);
                                            return newUrl;
                                          }
                                           else {
                                            // If there's no second occurrence, use the original URL
                                            return `${BASE_URL}${url}`;
                                          }
                                        })()
                                      }
                                      
                                    />
                                  ) : (
                                    [
                                      obj.image_url ? (
                                        <img
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          src={`${BASE_URL}${obj.image_url}`}
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/img/optp.png"
                                          }
                                          alt="product"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          className="img-fluid backgroud"
                                        />
                                      ),
                                    ]
                                  )}
                                  <div className="d-flex flex-column ml-2">

                                    <h6>
                                      {" "}
                                      {(obj.item_name && obj.item_name) ||
                                        (obj.combo_name && obj.combo_name)}
                                    </h6>
                                    {obj.item_size &&
                                      JSON.parse(obj.item_size)[0].discount_price &&
                                      JSON.parse(obj.item_size)[0].order_channel ===
                                      "callcenter" && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit +
                                              JSON.parse(obj.item_size)[0]
                                                .discount_price
                                            }
                                          </h6>
                                          <h6
                                            style={{
                                              textDecoration: "line-through",
                                              color: "rgb(192 10 39)",
                                              paddingLeft: "1rem",
                                            }}
                                            className="product-price"
                                          >
                                            {priceunit + JSON.parse(obj.item_size)[0].mrp}
                                          </h6>
                                        </div>
                                      )}
                                    {obj.item_size &&
                                      JSON.parse(obj.item_size)[0].discount_price ==
                                      null && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit + JSON.parse(obj.item_size)[0].mrp}
                                          </h6>
                                        </div>
                                      )}

                                    {obj.combo_id > 0 &&
                                      obj.discount_price &&
                                      obj.order_channel == "callcenter" && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {/* {comboData.combo_mrp_price} PKR */}
                                            {priceunit + obj.discount_price}
                                          </h6>

                                          <h6
                                            style={{
                                              textDecoration: "line-through",
                                              color: "rgb(192 10 39)",
                                              paddingLeft: "1rem",
                                            }}
                                            className="product-price"
                                          >
                                            {priceunit + obj.combo_mrp_price}
                                          </h6>
                                        </div>
                                      )}

                                    {obj.combo_id > 0 &&
                                      obj.discount_price == null && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit + obj.combo_mrp_price}
                                          </h6>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12 statistic d-flex justify-content-center">
                          <h4>No Item</h4>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 col-12 no-padding listScroll">
                      <div className="daily-feeds card">
                        {!_.isEmpty(itemData) && (
                          <div className="d-flex justify-content-between">
                            <div className="card-header">
                              <h3 className="h4">
                                {itemData.item_name
                                  ? itemData.item_name
                                  : itemData.combo_name}
                              </h3>
                              <h5>{priceunit + this.state.itemPrice}</h5>
                            </div>
                            <button
                              className="btn add-btn btn-xs btn-warning m-2"
                              onClick={() => this.handleAdd(itemData)}
                            >
                              Add
                            </button>
                          </div>
                        )}
                        {!_.isEmpty(itemData) && itemData.item_size && (
                          <div className="product-filters-container">
                            <div className="product-single-filter mb-2">
                              <div
                                className="form-group p-2 pb-0"
                                style={{ paddingLeft: 0 }}
                              >
                                <h5 style={{ paddingBottom: "0.5rem" }} id="txt">
                                  Choose an option
                                </h5>
                                <select
                                  onChange={this.renderItemPrice}
                                  className="custom-select drop-down custom-select-md mb-3"
                                  name="country"
                                >
                                  {/* <option value="" disabled>Choose an option</option> */}
                                  {/* {JSON.parse(itemData.item_size).map((itemSize: any, index: any) => (
                            <option key={index} value={JSON.stringify(itemSize)}>{itemSize.size}</option>
                          ))} */}
                                  {JSON.parse(itemData.item_size).map(
                                    (itemSize: any, index: any) => (
                                      <option
                                        key={index}
                                        value={JSON.stringify(itemSize)}
                                        {...(this.state.selectedsize.size ==
                                          itemSize.size && { selected: true })}
                                      >
                                        {itemSize.size}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                        {!_.isEmpty(itemData) && (
                          <div className="card-body no-padding">
                            {/* {cart.map((carts, indexes) => ( */}
                            <div className="item">
                              <div className="form-group row">
                                {/* <div className="col-sm-9">
                                      {Object.keys(this.state.modifiers).map(
                                        key => {
                                          return (
                                            <>
                                              <h5 className="mb-3">{key}</h5>
                                              {this.state.modifiers[key].map(
                                                (item: any, index: any) => {
                                                  return (
                                                    <div>
                                                      <div
                                                        className="i-checks"
                                                        onClick={() =>
                                                          this.handleChangeChk(
                                                            key,
                                                            item.modifier_name
                                                          )
                                                        }
                                                      >
                                                        <input
                                                          id="checkboxCustom1"
                                                          checked={item.selected}
                                                          type="checkbox"
                                                          value=""
                                                          className="checkbox-template"
                                                        />
                                                        <label htmlFor="radioCustom1">
                                                          {item.modifier_name}{" "}
                                                          {item.modifier_sale_price ===
                                                            0
                                                            ? null
                                                            : `+ PKR ${item.modifier_sale_price}`}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </div> */}
                                <div className="col">
                                  {!_.isEmpty(itemData) &&
                                    itemData.choices &&
                                    Object.keys(itemData.choices).map(
                                      (key) => {
                                        return (
                                          <div
                                            key={key}
                                            className="form-group-custom-control mt-4"
                                          >
                                            <h4>{key}</h4>

                                            {itemData.choices[key].map(
                                              (item: any, index: any) => {
                                                if (item.limit && item.limit > 1) {
                                                  return (
                                                    <div className="d-flex pt-2 mx-2 justify-content-between">
                                                      <div className="lt-item">
                                                        {item.size ? (
                                                          <span>
                                                            {item.item_name}
                                                            ({JSON.parse(item.size).size})
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {item.item_name}
                                                          </span>
                                                        )}
                                                        {item.extra_price ==
                                                          0 ||
                                                          item.extra_price ==
                                                          null ? (
                                                          <span></span>
                                                        ) : (
                                                          <span>
                                                            ( +
                                                            {priceunit +
                                                              item.extra_price
                                                            }{" "}
                                                            )
                                                          </span>
                                                        )}
                                                      </div>

                                                      {item.selected && (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            width: "5rem",
                                                          }}
                                                        >
                                                          <div
                                                            className="decrement-btn"
                                                            onClick={() =>
                                                              this.decrementChoiceQuantity(
                                                                key,
                                                                item.item_name,
                                                                item.limit,
                                                                JSON.parse(item.size).size
                                                              )
                                                            }
                                                          >
                                                            <a className="dec-icon">
                                                              -
                                                            </a>
                                                          </div>
                                                          <div
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              fontSize: 18,
                                                              width: "2rem",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {item.quantity}
                                                          </div>
                                                          <div
                                                            className="increment-btn"
                                                            onClick={() =>
                                                              this.incrementChoiceQuantity(
                                                                key,
                                                                item.item_name,
                                                                item.limit,
                                                                JSON.parse(item.size).size
                                                              )
                                                            }
                                                          >
                                                            <a className="inc-icon" style={{backgroundColor: '#d9b165'}}>
                                                              +
                                                            </a>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div
                                                      onClick={() =>
                                                        this.addVariation(
                                                          key,
                                                          item.item_name,
                                                          JSON.parse(item.size).size
                                                        )
                                                      }
                                                      className="d-flex pt-2 align-center justify-content-between"
                                                    >
                                                      <div className="lt-item">
                                                        {item.size ? (
                                                          <span>
                                                            {item.item_name}
                                                            ({JSON.parse(item.size).size})
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {item.item_name}
                                                          </span>
                                                        )}
                                                        {item.extra_price ==
                                                          0 ||
                                                          item.extra_price ==
                                                          null ? (
                                                          <span></span>
                                                        ) : (
                                                          <span>
                                                            ( +
                                                            {priceunit +
                                                              item.extra_price
                                                            }{" "}
                                                            )
                                                          </span>
                                                        )}
                                                      </div>

                                                      <div className="add-btn d-flex">
                                                        {item.selected ? (
                                                          <div className="added">
                                                            <span className="btn-text-clr-added">
                                                              Added
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <div className="notAdded">
                                                            <span className="btn-text-clr">
                                                              Add
                                                            </span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </div>
                                        );
                                      }
                                    )}

                                  {/*for showing modifiers */}
                                  {!_.isEmpty(itemData) &&
                                    itemData.modifiers &&
                                    Object.keys(modifiers).map((key) => {
                                      return (
                                        <div
                                          key={key}
                                          className="form-group-custom-control mt-4"
                                        >
                                          <h4>{key}</h4>
                                          {modifiers[key][0].modGroup_min_quantity == 0 ?
                                            <h6 style={{ float: "right", position: "relative", bottom: "25px" }} className="mb-0 myprofile required-label">
                                              Optional
                                            </h6>
                                            :
                                            <h6 style={{ float: "right", position: "relative", bottom: "25px" }} id={`${key}`} className="myprofile required-label">
                                              Required
                                            </h6>
                                          }
                                          <div className="subscript mb-2">
                                            {modifiers[key][0].modGroup_min_quantity == 0 ?
                                              <p className="mb-0 myprofile choose-label{">
                                                Choose upto {modifiers[key][0].modGroup_max_quantity}
                                              </p>
                                              :
                                              <p id={`choose ${key}`} className="mb-0 myprofile choose-label{">
                                                Choose {modifiers[key][0].modGroup_min_quantity}
                                              </p>
                                            }
                                          </div>
                                          {modifiers[key].map(
                                            (item: any, index: any) => {
                                              if (
                                                item.modifier_type ==
                                                "single"
                                              ) {
                                                return (
                                                  <div
                                                    onClick={() =>
                                                      this.handleCheckRadio(
                                                        key,
                                                        item.modifier_name
                                                      )
                                                    }
                                                    className="d-flex pt-2 justify-content-between"
                                                  >
                                                    <span>
                                                      {item.modifier_name}
                                                    </span>
                                                    <div className="add-btn d-flex">
                                                      {
                                                        item.modifier_sale_price ==
                                                          null ? (
                                                          <span></span>
                                                        ) : (
                                                          <span>
                                                            ( +
                                                            {priceunit +
                                                              item.modifier_sale_price
                                                            }{""}
                                                            )
                                                          </span>
                                                        )}
                                                      {item.selected ? (
                                                        <div className="added">
                                                          <span className="btn-text-clr-added">
                                                            Added
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <div className="notAdded">
                                                          <span className="btn-text-clr">
                                                            Add
                                                          </span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="multi-addon-row mb-2">
                                                    <div
                                                      key={index}
                                                      className="lt-item flex-nowrap justify-content-start"
                                                      onClick={() =>
                                                        this.handleCheck(
                                                          key,
                                                          item.modifier_name
                                                        )
                                                      }
                                                    >
                                                      <input
                                                        style={{ position: 'absolute' }}
                                                        type="checkbox"
                                                        checked={
                                                          item.selected
                                                        }
                                                        className="checkbox-template"
                                                        id="checkboxCustom1"
                                                        name="adon"
                                                      />
                                                      <span
                                                        style={{
                                                          marginLeft:
                                                            "20px",
                                                        }}
                                                      >
                                                        {""}
                                                        {
                                                          item.modifier_name
                                                        }{""}
                                                        {item.modifier_sale_price <
                                                          0
                                                          ? null
                                                          : `(${priceunit + item.modifier_sale_price} )`}{" "}
                                                      </span>
                                                    </div>
                                                    {item.selected && item.max > 1 && (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          width: "5rem",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <div className="decrement-btn">
                                                          <a
                                                            className="dec-icon"
                                                            onClick={() =>
                                                              this.decrementQuantity(
                                                                key,
                                                                item.modifier_name
                                                              )
                                                            }
                                                          >
                                                            -
                                                          </a>
                                                        </div>
                                                        <div
                                                          style={{
                                                            fontWeight:
                                                              "bold",
                                                            fontSize: 18,
                                                          }}
                                                        >
                                                          {item.quantity}
                                                        </div>
                                                        <div className="increment-btn">
                                                          <a
                                                            className="inc-icon"
                                                            onClick={() =>
                                                              this.incrementQuantity(
                                                                key,
                                                                item.modifier_name
                                                              )
                                                            }
                                                          >
                                                            +
                                                          </a>
                                                        </div>
                                                      </div>

                                                      /* <NumericInput
                                                    min={1}
                                                    value={item.quantity}
                                                    max={100}
                                                    type="number"
                                                    mobile
                                                    size={10}
                                                    className="numericInput"
                                                    onChange={(value) =>
                                                      this.handleCheckQuantity(key,item.modifier_name,value)
                                                    }
                                                  /> */
                                                    )}
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <h5>
                                {/* {(!_.isEmpty(carts.item) &&
                                                                      carts.item.item_name) ||
                                                                      (!_.isEmpty(carts.combo) &&
                                                                          carts.combo.combo_name)} */}
                              </h5>

                              {/* <div className="date text-right">
                                <strong>
                                  {this.state.itemPrice}
                                  PKR
                                </strong>
                              </div> */}
                            </div>
                            {/* ))} */}
                            <div className="item d-flex justify-content-between">
                              <div className="info d-flex">
                                <div className="title">
                                  <button
                                    className="btn btn-xs btn-warning"
                                    onClick={() => this.handleAdd(itemData)}
                                  >
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 listScroll">{this.cartItem(cart)}</div>
                  </div>
                </div>
                :
                <div className="container-fluid">
                  <div className="error-msg">
                    <img src="assets/img/danger.png" alt="warning icon" />
                    {_.isEmpty(customer) && _.isEmpty(selectedStore) &&
                      <span className="error-text">Select customer and store to continue with menu section</span>
                    }
                    {!_.isEmpty(customer) && _.isEmpty(selectedStore) &&
                      <span className="error-text">Select store to continue order taking process</span>
                    }
                    {_.isEmpty(customer) && !_.isEmpty(selectedStore) &&
                      <span className="error-text">Select customer to continue order taking process</span>
                    }
                  </div>
                </div>
              }
            </section>
            {/* <div>
                <h1>+</h1>
        {showCutomerPanel &&
            <footer className="customer-footer has-shadow">
                <div onClick={() => this.setState({showCutomerPanel: false})} style={{position:'absolute',top:'-13px',left:'600px'}}>
                <h1>+</h1>

                </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-4">
                    <h4>Customer Info</h4>
                    <div className="row pl-3">
                      <span>Customer Name:</span>
                    </div>
                    <div className="row pl-3">
                      <span>Phone No:</span>
                    </div>
                    <div className="row pl-3">
                      <span>Recent Address:</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <h4>Order History</h4>
                  </div>
                </div>
              </div>
            </footer>
  }
  </div> */}
            {/* <Footer /> */}
          </div>
        </div>
        <Modal
          open={open}
          showCloseIcon={false}
          onClose={this.onCloseModal}
          center
        >
          <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
            <p>
              Congratulations! Your order has been succesfully placed.
              <h6>Your Order tracking ID is {order_id}</h6>
            </p>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.GotoHome}
                className="btn btn-sm btn-primary center"
                style={{
                  // backgroundColor: "#C00A27",
                  // borderColor: "#C00A27",
                  backgroundColor: "#d9b165",
                  borderColor: "#d9b165",
                  color: "#fff",
                }}
              >
                OKAY
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.allGroupsData,
    itemsbygroupid: state.menu.menuItemsData,
    itemData: state.menu.itemData,
    counter: state.menu.counter,
    cart: state.cart.cartData,
    stores: state.customer.pickupStoresList,
    selectedStore: state.customer.selectedStore,
    tax: state.cart.taxData,
    deliveryfee: state.menu.deliveryfee,
    delivery_address: state.menu.delivery_address,
    special_inst: state.menu.special_inst,
    geo_code_address: state.menu.geocoded_address,
    houseNo: state.menu.houseNo,
    Block: state.menu.Block,
    selectedAddress: state.menu.selectedAddress,
    Area: state.menu.Area,
    City: state.menu.City,
    Additional_address_info: state.menu.Additional_address_info,
    callduration: state.customer.clockCount,
    taxDataCash: state.menu.taxDataCash,
    taxDataCard: state.menu.taxDataCard,
    backupStores: state.menu.backupStoresList,
    allStoresList: state.customer.allStoresList,
    coupanCode: state.menu.coupanCode,
    coupon: state.menu.coupon,
    deliveryTime: state.menu.deliveryTime,
    taxAmount: state.menu.taxAmount,
    discountAmount: state.menu.discountAmount,
    open: state.customer.successFlag,
    order_id: state.customer.order_id,
    CartTotal: state.menu.CartTotal,
    orderPeriod: state.menu.orderPeriod,
    storesOption: state.menu.storesoptions,
    isLoading: state.menu.isLoading,
    groupsData: state.menu.groupsData,
    customer: state.customer.customerData,
    cities_portal: state.trade_zones_areas.cities,
    tradeZones_portal: state.trade_zones_areas.tradeZones,
    tradeAreas_portal: state.trade_zones_areas.tradeAreas,
    cityName: state.trade_zones_areas.cityName,
    tradeZoneName: state.trade_zones_areas.tradeZoneName,
    tradeAreaName: state.trade_zones_areas.tradeAreaName,
    tradeZoneValue: state.trade_zones_areas.tradeZoneValue,
    tradeAreaValue: state.trade_zones_areas.tradeAreaValue,
    is_saved_address_selected: state.customer.is_saved_address_selected,
    savedAddress: state.customer.savedAddress,
    brands: state.brand.brands,
    // stores new list 
    matchingStores: state.customer.storesList,
    tradeAreas:state.customer.tradeAreasList
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    handleMenuInput: (event: any) => {
      dispatch(handleMenuInput(event));
    },
    allGroupsList: (store_id: any, brand_id: any) => {
      dispatch(allGroupsList(store_id, brand_id));
    },
    ordersCounter: () => {
      dispatch(ordersCounter());
    },
    menuItemsListByGroupsID: (groupId: any) => {
      dispatch(menuItemsListByGroupsID(groupId));
    },
    getComboDetail: (combo_id: any) => {
      dispatch(getComboDetail(combo_id));
    },
    getItemDetail: (menu_item_id: any) => {
      dispatch(getItemDetail(menu_item_id));
    },
    storesList: () => {
      dispatch(getStores());
    },
    getStoreByTradeZone:(tradeZone:any,tradeArea:any) =>{
      dispatch(getStoreByTradeZone(tradeZone,tradeArea));
    },
    setStoreByTradeZone:()=>{
      dispatch(setStoreByTradeZone());
    },
    setStoreByCity:()=>{
      dispatch(setStoreByCity());
    },

    getStoreByCity:(city_id:any) =>{
      dispatch(getStoreByCity(city_id));
    },
    saveBrand: (brand_id: any) => {
      dispatch(saveBrand(brand_id))
    },
    getAllStores: () => {
      dispatch(getAllStores())
    },
    saveSelectStoreId: (store: any) => {
      dispatch(saveSelectStoreId(store));
    },
    getCart: () => {
      dispatch(getCart());
    },
    saveCart: (cart: any[]) => {
      dispatch(saveCart(cart));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveBackupStores: (backupStores: any) => {
      dispatch(saveBackupStores(backupStores));
    },
    getTax: (state_id: any) => {
      dispatch(getTaxValue(state_id));
    },
    saveOrder: (data: any, store: any, tax: any, callObj: any) => {
      dispatch(saveOrder(data, store, tax, callObj));
    },
    applyCoupon: function (coupon: any) {
      dispatch(applyCoupon(coupon));
    },
    handleAddressInput: (event: any) => { dispatch(handleAddressInput(event)) },
    handleGeoCodeAddress: (event: any) => { dispatch(handleGeoCodeAddress(event)) },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    stopTimer: function () {
      dispatch(stopTimer())
    },
    findPickupStores: function (lat: any, long: any, value: any, brand_id: any) {
      dispatch(findPickupStores(lat, long, value, brand_id));
    },
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    SaveCartTotal: function (cart_total: any) {
      dispatch(SaveCartTotal(cart_total));
    },
    saveOrderPeriod: (period: any) => {
      dispatch(saveOrderPeriod(period));
    },
    storesOptionList: () => {
      dispatch(storesListForMultiSelect())
    },
    UpdateMenuItems: function (data: any) {
      dispatch(UpdateMenuItems(data))
    },
    getCities: function () {
      dispatch(getCitiesWeb());
    },
    getTradeZones: function (id: any) {
      dispatch(getTradeZonesWeb(id))
    },
    getTradeAreas: function (id: any) {
      dispatch(getTradeAreas(id))
    },
    getTradeAreasByTradeZone: function (id: any) {
      dispatch(getTradeAreasByTradeZone(id))
    },
    setTradeAreas:function () {
      dispatch(setTradeAreas())
    },
    setTradeZoneName: function (name: String, value: any) {
      dispatch(setTradeZoneName(name, value))
    },
    setTradeAreaName: function (name: String, value: any) {
      dispatch(setTradeAreaName(name, value))
    },
    setCityName: function (name: String) {
      dispatch(setCityName(name))
    },
    setHouseNo: function (address: String) {
      dispatch(handleHouseNo(address))
    },
    brandsList: () => {
      dispatch(brandsList())
    },
    findLocalStoreWithoutMap: function (store: any) {
      // console.log("storeData55=>", store)
      dispatch(findLocalStoreWithoutMap(store))
    },
    customerDetail: (data: any) => {
      dispatch(customerDetail(data));
    },
    getTimezone: function (id: number) {
      return dispatch(getTimezone(id))
    },
    getTimezoneForLater: function (id: number) {
      return dispatch(getTimezoneForLater(id))
    },
    tradeZoneWiseStoresList: function(storeArr:[]){
      // console.log("dataFindout22222",storeArr )
      return dispatch(tradeZoneWiseStoresList(storeArr))
    },
    menuSet: function(){
      return dispatch(menuSet())
    }
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);