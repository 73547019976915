import moment from 'moment';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import { priceunit, API_URL } from '../../client-config';
import './invoice.css'
class Invoice extends Component<{ data: any, orderId: any, selectedBrandId?:any }, {}> {
    componentRef: any;
    calcSubTotalTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return sum;
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return Math.round(sum);
    };
    // setBrandLogo = (brandId: number) => {
    //     switch (brandId) {
    //         case 1:
    //             return process.env.PUBLIC_URL + "/assets/img/ky.png";
    //         case 2:
    //             return process.env.PUBLIC_URL + "/assets/img/BR.png";
    //         case 3:
    //             return process.env.PUBLIC_URL + "/assets/img/halla-shawarmaa.jpg";
    //         default:
    //             return process.env.PUBLIC_URL + "/assets/img/ky.png";
    //     }
    // }
    
    render() {
        let { data, orderId, selectedBrandId } = this.props;
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        return (
            <div>
         
                <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                    <header className="clearfix">
                        <div id="logo">
                            {/* <img width={100} height={100} src={process.env.PUBLIC_URL + "/assets/img/br-logo.png"} /> */}
                            {/* <img width={150} height={80} src={`${data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : this.setBrandLogo(selectedBrandId)}`} /> */}
                            <img width={150} height={80} src={`${data.orderDetail && data.orderDetail.landing_image ? `${API_URL}/${data.orderDetail.landing_image}` : process.env.PUBLIC_URL + "/assets/img/ky.png"}`} />
                        </div>
                        <h1 className="invoiceh1"><b>ORDER #{orderId}</b></h1>
                        <div id="project">
                            <h3>Customer Information</h3>
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>Customer</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && data.orderDetail.login_name}</span></div>
                            {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>Phone</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.phone_number}</span></div>}
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>Store</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>From</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address}</span></div>
                            {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>To </span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail.delivery_address}</span></div>}
                            <div style={{ display: 'flex' }}><span>Special Ins:</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst}</span></div>
                            <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}>Date</span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                            {utcDateTime._isValid && <div><span style={{ textAlign: "left" }}>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time</span><br /><span>{FutureDate}</span></div>}
                        </div>
                        <div id="secProject1">
                            <div id="project2">
                                <div style={{paddingRight: '10px'}}><h6 style={{fontSize:'13px'}}>Payment Mode</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{fontSize:'13px'}}>Ordering Channel</h6>{data.orderDetail && data.orderDetail.order_channel}
                                </div>
                            </div>
                            <div id="project2">
                                <div>
                                    <h6 style={{fontSize:'13px'}}>Ordering Mode</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <table className="invoicetable">
                            <thead>
                                <tr>
                                    {/* <th className="service" style={{ width: "5vw" }}></th> */}
                                    <th className="desc">Name</th>
                                    {/* <th className="desc">DESCRIPTION</th> */}
                                    {/* <th>PRICE</th> */}
                                    {/* <th style={{ width: 50 }}>QTY</th> */}
                                    <th style={{ width: 100 }}>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.Items.map((obj: any, index: any) => {
                                    let AddOnsPrice: any = 0;
                                    const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                        return <li><b>{element.quantity*obj.order_item_quantity} </b>{element.item_name} ({JSON.parse(element.size).size}){element.extra_price > 0 && `+${priceunit + element.extra_price*obj.order_item_quantity} `}</li>
                                    })
                                    return (
                                        <>
                                            <tr key={index}>
                                                {/* <td className="service">{index + 1}</td> */}
                                                <td className="desc" title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}><b>{(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)} x {obj.order_item_quantity}</b>
                                                    <ul>
                                                        {ingredients}
                                                        <br />
                                                        <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                    </ul>
                                                </td>
                                                {/* <td className="desc" title={modifiers}>{modifiers}</td> */}
                                                {/* <td className="unit">{Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td> */}
                                                {/* <td className="qty">{obj.order_item_quantity}</td> */}
                                                <td className="total">{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    AddOnsPrice += (element.quantity * element.modifier_sale_price);
                                                })}
                                                    {priceunit }
                                                    {obj.order_item_grossprice - AddOnsPrice} </td>
                                            </tr>

                                            {
                                                obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                    return <tr><td style={{ textAlign: "left" }}><b>{element.quantity} </b>{element.modifier_name}</td><td> {priceunit + element.modifier_sale_price * element.quantity} </td></tr>
                                                })
                                            }
                                            <tr><td>Total</td><td>{priceunit + obj.order_item_grossprice} </td></tr>
                                            {/* <tr><td>Total</td><td>{priceunit + obj.order_netprice} </td></tr> */}
                                        </>
                                    )
                                })}
                                <tr>
                                    <td colSpan={1}>SUBTOTAL</td>
                                    <td className="total">{data.Items.length > 0 && priceunit + this.calcSubTotalTotal(data.Items)} </td>
                                </tr>
                                {data.orderDetail.coupon &&
                                    <tr>
                                        <td colSpan={1}>Your Discount</td>
                                        <td>{priceunit + Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                    }
                                {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                    <td colSpan={1}>Delivery Fee</td>
                                    <td className="total">{data.orderDetail && priceunit + data.orderDetail.delivery_fee}</td>
                                </tr>}

                                <tr>
                                    <td colSpan={1}>Tax : {data.orderDetail.tax_percent}%</td>
                                    <td>{priceunit + Math.round(data.orderDetail.tax_amount)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                </tr>
                                {   (data.orderDetail.discount &&
                                    data.orderDetail.discount > 0) ?
                                    <tr>
                                        <td className="discountStyle" colSpan={1}>Your Discount</td>
                                        <td className="discountStyle">{priceunit} {Math.round(data.orderDetail.discount)} </td>
                                    </tr>
                                    :
                                    null
                                }
                                <tr>
                                    <td colSpan={1} className="grand total">GRAND TOTAL</td>
                                    <td className="grand total">
                                        {priceunit }
                                        {(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items)-data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.brand_name}</p> */}
                        <p className="text-center mt-3">{data.orderDetail && data.orderDetail.brand_name}</p>
                        {/* <p className="text-center mt-3">NTN #<br /><b> الرقم الضريبي الوطني</b>  {data.orderDetail.ntn_number}</p> */}
                        {/* <p className="text-center mt-3">{decoded && decoded.brand && decoded.brand.website_link}</p> */}
                        <p className="text-center mt-3">{data.orderDetail.website_link == 'null' ? '' : data.orderDetail.website_link}</p>
                        <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6>
                        <footer className="invoicefooter mb-3">
                            {/* "Thank you for Ordering {decoded && decoded.brand && decoded.brand.brand_name}" */}
                            "Thank you for Ordering {data.orderDetail && data.orderDetail.brand_name}"
                        </footer>
                    </main>
                </div>
            </div>
        )
    }
}
export default Invoice;