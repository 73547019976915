import React, { Component } from "react";
import Sidebar from "../../components/sidebar";
import Topbar from "../../components/topbar";
import Footer from "../../components/footer/main";
//  import "./order.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import OrderDetail from "../customerDetail/orderDetail";
import _, { dropRight } from "lodash";
import { OrderProps, OrderState } from "../../interfaces/order";
import { connect } from "react-redux";
import {  brandsList, saveBrand } from "../../redux/actions/brandAction";
import { filterOrdersList } from "../../redux/actions/orderAction";
import HallaInvoice from "../order/HallaInvoice";
import Invoice from "../order/invoice";
import InvoiceAggregator from "../order/invoice-aggregator";
import './invoiceList.css'
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Complaint from '../customerDetail/complaint';

import { showorderItems } from "../../redux/actions/orderAction";
import moment from "moment";
import jwt from 'jsonwebtoken'
import { API_URL, priceunit } from "../../client-config";
import { toast } from "react-toastify";
// import { ReactTimer } from "./timer/timer";

class ActionFormatter extends Component<{ row: any, action_state: any }, { selectedOrderId: any, checkTimer: any, selectedBrandId:any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectedOrderId: "",
      selectedBrandId: "",
      checkTimer: false
    }
  }
  showorderItems = (id: any) => {
    // this.props.data.stopTimer();
    // if (this.props.data.timerStatus == true) {
    //   this.setState({ checkTimer: true })
    // }
    const ordersStringData = sessionStorage.getItem("orderListData");
    let ordersDataInSession = [];
    if(ordersStringData){
        ordersDataInSession = JSON.parse(ordersStringData);
    }
    const selectedOrder = ordersDataInSession.find((obj: any) => obj.order_id === id);

    if (selectedOrder) {
        const selectedBrandId = selectedOrder.brand_id;
        this.setState({ selectedBrandId});
    }
    let storeId: any = "";
    let stateId: any = "";
    let {row} = this.props;

    let orderChannel = row.order_channel;
    this.props.data.showorderItems(id, ordersDataInSession, orderChannel);
    storeId = ordersDataInSession.find((obj: any) => {
      return obj.order_id == id;
    })?.store_id;
    if (storeId && storeId !== "") {
      const store = this.props.data.stores?.find((obj: any) => {
        return obj.store_id === storeId;
      });
    
      if (store) {
        stateId = store.state_id;
      }
    }
    // if (stateId !== "") {
    //     tax = this.props.data.states.find((obj: any) => {
    //         return obj.state_id == stateId;
    //     }).tax_percent;
    // }
    this.setState({ selectedOrderId: id });
  }
  handleTimer = () => {
    // console.log("this.props.action_state.days", this.props)
    if (this.state.checkTimer == true) {
      this.props.data.luanchTimer(1, this.props.action_state.days);
    }
  }
  render() {
    const { row, data } = this.props;
    // console.log("rowData999", row.order_id);
    return (
      <div>
        {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
        {/* <!-- Modal--> */}
        {/*Invoice Modal */}
        <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                <button onClick={() => this.handleTimer()} type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                {/* <Invoice data={data} orderId={this.state.selectedOrderId} selectedBrandId={this.state.selectedBrandId} /> */}
                {
                  getInvoiceComponent(row.brand_id, data, this.state.selectedOrderId)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, cancelReason: any, otherReason: any, orderstatus: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      orderstatus: "",
      cancelReason: "",
      otherReason: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ orderstatus: row.order_status_code })
  }
  handleSave = () => {
    const { row } = this.props
    const { orderstatus } = this.state;
    let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
    let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
    let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
    let currentDateforComp = moment().format('YYYY-MM-DD');
    var createdTime = moment(row.date_created).local().format('HH:mm');
    var currentTime = moment().local().format('HH:mm');
    var startTime = moment(createdTime, "HH:mm");
    var endTime = moment(currentTime, "HH:mm");
    var duration: any = moment.duration(endTime.diff(startTime));
    var minutes = parseInt(duration.asMinutes());
    var minutesduration = 0;
    if (moment(currentDateforComp).isAfter(orderDateforComp)) {
      var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
      var d = moment.duration(ms);
      minutesduration = Math.floor(d.asMinutes());
    } else {
      minutesduration = minutes;
    }
    let deliverytime: any = row.delivery_time_json != null ? JSON.parse(row.delivery_time_json) : { pending: 0, kitchen: 0, ready: 0, dispatched: 0 }
    if (row.delivery_status == "Delivery") {
      if (orderstatus == row.order_status_code) {
        toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
      } else {
        if (orderstatus == 3) {
          if (row.delivery_boy !== "" && row.delivery_boy !== null) {
            deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
            const data = {
              order_status_code: orderstatus,
              delivery_status: row.delivery_status,
              delivery_time_json: JSON.stringify(deliverytime)
            }
            this.props.data.updateOrder(row.order_id, data, this.props.data.history)
          } else {
            alert('Please Assign the order to driver first')
          }
        } else if (orderstatus == 6) {
          this.openModal.click();
        } else {
          if (orderstatus == 2) {
            deliverytime["pending"] = minutesduration;
          }
          if (orderstatus == 4) {
            deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
          }
          if (orderstatus == 8) {
            deliverytime["kitchen"] = minutesduration - deliverytime.pending;
          }
          const data: any = {
            order_status_code: orderstatus,
            delivery_status: row.delivery_status,
            delivery_time_json: JSON.stringify(deliverytime)
          }
          this.props.data.updateOrder(row.order_id, data, this.props.data.history)
        }
      }
    } else {
      const data: any = {
        order_status_code: orderstatus,
        delivery_status: row.delivery_status,
      }
      if (orderstatus == row.order_status_code) {
        toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
      } else {
        if (orderstatus == 6) {
          this.openModal.click();
        } else {
          this.props.data.updateOrder(row.order_id, data, this.props.data.history)
        }
      }
    }
  }
  isCancelOrderReady = () => {
    const { cancelReason } = this.state;
    return (cancelReason !== "");
  }
  cancelOrder(order_id: any) {
    const data: any = {
      order_status_code: 6,
      cancel_reason: this.state.cancelReason
    }
    if (this.state.otherReason !== "") {
      data.cancel_reason_description = this.state.otherReason;
    }
    this.props.data.updateOrder(order_id, data, this.props.data.history);
    this.closeModal.click();
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { data, row } = this.props;
    const { orderstatus } = this.state;
    let tokenData: any = jwt.decode(sessionStorage.token)
    let roleId = tokenData.role_id;
    return (
      <div>
        {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <span {...(((row.order_status_code === 4 || row.order_status_code === 5) && { className: "badge badge-success text-capitalize p-1" }) || (row.order_status_code === 6 && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
          <div>
            <select
              disabled={true}
              name="orderstatus"
              value={orderstatus}
              onChange={this.handleInputChange}
              className="text-capitalize">
              {data.statusList &&
                data.statusList.map((status: any, index: any) => (
                  (row.future_status == 1) ?
                    (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 1) ?
                      (status.order_status_code === 1 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                      : (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 7) ?
                        (status.order_status_code == 7 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                        : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                          (status.order_status_code === 6 || status.order_status_code === 1) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                          (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                            (status.order_status_code === 7 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                            : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                              (row.delivery_status == "Delivery")
                                //if order is delivery,so Delivered option will be show instead of picked
                                ?
                                (status.order_status_code === 6 || status.order_status_code === 2) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                //else if order is pick up,so Picked option will be show instead of picked
                                :
                                (status.order_status_code === 6 || status.order_status_code === 2) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                              (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                //then 2.3
                                (row.delivery_status == "Delivery")
                                  ?
                                  (status.order_status_code === 6 || status.order_status_code === 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                  :
                                  //else 2.3
                                  (status.order_status_code === 6 || status.order_status_code === 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                :
                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                  (row.delivery_status == "Delivery")
                                  ?
                                  (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Dispatched" : status.order_status_description}</option>
                                  :
                                  //else 2.3
                                  (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready For Pickup" : status.order_status_description}</option>
                    :
                    row.order_status_code == 1 ?
                      //if order status is 1 so then
                      (status.order_status_code == 6 || status.order_status_code == 1) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                      //if status is not 1 so then
                      :
                      //if status is 2
                      (row.order_status_code == 2) ?
                        (row.delivery_status == "Delivery")
                          //if order is delivery,so Delivered option will be show instead of picked
                          ?
                          (status.order_status_code == 2 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                          //else if order is pick up,so Picked option will be show instead of picked
                          :
                          (status.order_status_code == 2 || status.order_status_code == 6) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                        //Nested else if order status is 3
                        : (row.order_status_code == 8) ?
                          //then 2.3
                          (row.delivery_status == "Delivery")
                            ?
                            (status.order_status_code === 8 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                            :
                            //else 2.3
                            (status.order_status_code === 8 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                          : (roleId == 9) ? //if role is supervisor then cancel option is available for dispatched or ready to pickup status 
                            //then 2.3
                            (row.delivery_status == "Delivery") && (row.order_status_code == 3)
                              ?
                              (status.order_status_code === 3 || status.order_status_code === 6) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                              :
                              //else 2.3
                              (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                            : (row.delivery_status == "Delivery") && (row.order_status_code == 3) // else call center agent will not have cancel options
                              ?
                              (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                              :
                              //else 2.3
                              (status.order_status_code === 3) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                ))
              }
            </select>
            <br />
            {orderstatus == 6 && <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>}
          </div>
        }
        <div style={{ display: "none" }}>
          <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
        </div>
        <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <h6>Are you sure,you want to cancel the order?</h6>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <select
                        name="cancelReason"
                        onChange={this.handleInputChange}
                        className="form-control mt-2">
                        <option value="">Select Reason</option>
                        {data.cancelReasons &&
                          data.cancelReasons.map((reason: any, index: any) => (
                            <option key={index} value={reason.id}>{reason.reason}</option>
                          ))
                        }
                      </select>

                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        id="otherReason"
                        style={{ fontSize: 15 }}
                        type="text"
                        name="otherReason"
                        placeholder="Description"
                        className="input-material"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                </div>
                <div className="form-group d-flex justify-content-end mt-4">
                  <button className='btn btn-primary btn-warning' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id)}>Save</button>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class DriverEditor extends React.Component<{ data: any, row: any, driver_state: any }, { [x: number]: any, deliveryboy: any }> {
  constructor(props: any) {
    super(props);
    this.state = {
      deliveryboy: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ deliveryboy: row.delivery_boy })
  }
  handleSave = () => {

    const { row } = this.props
    const { deliveryboy } = this.state;
    const data = {
      delivery_boy: deliveryboy ? deliveryboy : "",
      channel: row.order_channel ? row.order_channel : "",
    }
    if (deliveryboy !== "" && deliveryboy > 0) {
      this.props.data.updateOrder(row.order_id, data, this.props.data.history, this.props.driver_state.brand_id.value)
    } else {
      this.setState({ deliveryboy: row.delivery_boy })
      toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
    }

  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { data, row } = this.props
    const { deliveryboy } = this.state;
    return (
      <div>
        {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <p>{row.driver_name}</p> :
          // (row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 7) &&
          (
            <div>
              <select
                name="deliveryboy"
                {...row.delivery_boy != null && { disabled: true }}
                // {...row.order_status_code == 3 && { disabled: true }}
                // {...(row.order_status_code == 3 || row.aggregator_delivery == 1) && { disabled: true }}
                value={deliveryboy || ''}
                onChange={this.handleInputChange}
                className="text-capitalize">
                <option value="">Select Driver</option>
                {data.delivery_boys &&
                  data.delivery_boys.map((driver: any, index: any) => (
                    // To lock local rider after one order dispatched
                    // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                    // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                    (((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) || driver.store_id == null) ?
                      <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                      : ""
                  ))
                }
              </select>
              <br />
              {/* {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
              {(row.order_status_code != 3 && row.aggregator_delivery == null && row.delivery_boy == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
            </div>
          )
        }
      </div>
    );
  }
}

const selectStatusEditor = (cell: any, row: any, props: any) => {
  return (
    <StatusEditor row={row} data={props} />
  )
}


function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `AED. ${cell}`;
  } else {
    return `AED. ${Math.round(cell)}`;
  }
}
function complaintFormatter(cell: any, row: any, props: any) {
  return (
    <Complaint row={row} data={props} />
  );
}

function brandFormatter(cell: any, row: any, props: any) {
  return (
    <img className="img-fluid rounded-circle" src={API_URL + cell} />
  );
}
function actionFormatter(cell: any, row: any, props: any) {
  // console.log("props", props)
  return <ActionFormatter row={row} data={props.props} action_state={props.state} />;
}
function getInvoiceComponent(brandId:any, data:any, selectedOrderId:any ) {
  if (brandId === 1) {
    return <Invoice data={data} orderId={selectedOrderId}/>
  } else if (brandId === 3) {
    return <InvoiceAggregator data={data} orderId={selectedOrderId} />
  } else if(brandId === 5) {
    return <HallaInvoice data={data} orderId={selectedOrderId} />
  }
  else {
    return <Invoice data={data} orderId={selectedOrderId} />;
  }
}

const selectDriverEditor = (cell: any, row: any, props: any) => {
  return (
    row.delivery_status == "Delivery" ?
      <DriverEditor row={row} data={props.props} driver_state={props.state} /> : ""
  )
}

class InvoiceList extends Component<OrderProps, OrderState, {brand_id: any}> {
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      days: 0,
      orderId: '',
      brand_id: {
        value: 0,
        label: "All"
    }
  }
    this.handleDaysFilter = this.handleDaysFilter.bind(this);
    this.handleBrandsInputChange = this.handleBrandsInputChange.bind(this);
    this.handleTimer = this.handleTimer.bind(this);

  }
  componentDidMount() {
    const {days} = this.state;
    this.setState({days: days});
    let stateData = {days: 0};
    let obj = {days: this.state.days, brand_id: this.state.brand_id}
    // this.props.ordersList(obj);
    this.props.getAgentStats(stateData);
    this.props.storesList();
    this.props.complaintReasonsList();
    this.props.orderStatusList();
    this.props.cancelOrdersReasons();
    this.props.luanchTimer(1, this.state.days);
    this.props.brandsList()
  }
  // handleBrand = (e: any) => {
  //   this.props.saveBrand(JSON.parse(e.target.value))
  // }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.orders.length > 0) {
      var audio = new Audio();
      audio.src = process.env.PUBLIC_URL + "/assets/sound/old-style-phone-ringing.mp3";
      // if (prevOrdersLength < nextProps.orders.length) {
      nextProps.orders.map((element: any) => {
        if (element.future_status == 1) {
          // element.order_status_code == 1 && element.orderStatus == "future" && 
          if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
            // audio.play();
            if (element.order_status_code != 6) {
              audio.oncanplaythrough = (event) => {
                var playedPromise = audio.play();
                if (playedPromise) {
                  playedPromise.catch((e) => {
                    // console.log(e)
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                      // console.log(e.name);
                    }
                  }).then(() => {
                    // console.log("playing sound !!!");
                    return
                  });
                }
              }
            }

            // (element.order_status_code == 1 || element.order_status_code == 7) && element.orderStatus == "now" && 
          } else if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
            if (element.order_status_code != 6) {
              audio.oncanplaythrough = (event) => {
                var playedPromise = audio.play();
                if (playedPromise) {
                  playedPromise.catch((e) => {
                    // console.log(e)
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                      // console.log(e.name);
                    }
                  }).then(() => {
                    // console.log("playing sound !!!");
                    return
                  });
                }
              }
            }

          }
        } else {
          // console.log("element.delivery_status", element.delivery_status, element.delivery_boy)
          // element.order_status_code == 1 && 
          if (element.delivery_status == 'Delivery' && element.delivery_boy == null) {
            // audio.play();
            if (element.order_status_code != 6) {
              audio.oncanplaythrough = (event) => {
                var playedPromise = audio.play();
                if (playedPromise) {
                  playedPromise.catch((e) => {
                    // console.log(e)
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                      // console.log(e.name);
                    }
                  }).then(() => {
                    // console.log("playing sound !!!");
                    return
                  });
                }
              }
            }


          }
        }
      });
      // }
    }
  }
 async handleDaysFilter(event: { target: { name: any; value: any; }; }) {
    const {brand_id} = this.state;
    const days = event.target.value;
    await this.setState({days: days});
    // console.log("brandId", brand_id, "days", this.state.days);
    let obj = { days: this.state.days, brand_id:brand_id };
    // console.log("obj999", obj);
    // this.props.ordersList(obj);
}

  handleInput = (event: { target: { name: any; value: any; }; }) => {
    this.setState({ orderId: event.target.value })
  };

  handleTimer() {
    // console.log("this.props", this.props)
    if (this.props.timerStatus == false) {
      this.props.luanchTimer(1, this.state.days);
    } else if (this.props.timerStatus == true) {
      this.props.stopTimer();
    }
    this.closeModal.click();
  }

  handleSearchOrder = (event: any) => {
    event.preventDefault();
    let { orderId } = this.state;
    this.props.searchOrder(orderId)
  }

  refreshOrders = () => {
    let obj = {
      days: 0
    }
    // this.props.ordersList(obj);
  }

  classFormatter = (row: any, ind: any) => {
    // console.log("row", row)
    let disable = true
    if (row.delivery_status == 'Delivery' && row.delivery_boy == null) {
      if (row.order_status_code == 6) {
        disable = true
      } else {
        disable = false
      }
    }
    return disable == false ? 'pending_status' : 'other_status'
  }

  async FilterbyBrands(brandId:any) {
    let { orders } = this.props;
    // console.log("orders9999", orders);
    let filteredArr: any = [];
    if ( brandId === 1) {
      // console.log("reached-here9999", brandId);
        let filteredBrands = orders.filter((order: any) => order.brand_id == brandId || order.brand_id == brandId);
        filteredArr  = filteredBrands
    } else if (brandId === 3) {
      let filteredBrands = orders.filter((order: any) => order.brand_id == brandId || order.brand_id == brandId);
      filteredArr  = filteredBrands
    }
    else if (brandId === 4) {
      let filteredBrands = orders.filter((order: any) => order.brand_id == brandId || order.brand_id == brandId)
      filteredArr  = filteredBrands
    }
    // console.log("filteredArr9999", filteredArr)
    return await this.props.filterOrdersList(filteredArr)
  }

  async handleBrandsInputChange(event: { target: { name: any; value: any; }; }){
    // this.setState({brand_id:{label:event.target.name,value:event.target.value}});
    const getBrandId = JSON.parse(event.target.value);
    let brandID  = getBrandId.brand_id;
    await this.setState({brand_id :brandID})
    const {brand_id} = this.state;
    let obj = { days: this.state.days, brand_id: brandID };
    //  this.props.ordersList(obj);
     if(brand_id){
      this.FilterbyBrands(brand_id)
    }
    //  Test Data Start
    
    // Test Data End
      // this.props.statesList(e.value);
      // this.props.storesList(e.value);
}

   render() {
    const ordersString = sessionStorage.getItem("orderListData");
    let { row } = this.props;
    let orders : any = [];
    let matchedOrder : any = [];
    let matchedOrderArray: any = [];
    if (ordersString) {
      orders = JSON.parse(ordersString);
      // Assuming you have a row object with an order_id property
      const rowOrderId = row.order_id;
      console.log("rowOrderId999", row);
      // Find the order that matches the row's order_id
      matchedOrder = orders.find((order:any) => order.order_id == rowOrderId);
      console.log("matchedOrder888==>", matchedOrder, typeof matchedOrder);
      matchedOrderArray = matchedOrder ? [matchedOrder] : [];
    }
    // console.log("orderData888", orders);
    let tokenData: any = jwt.decode(sessionStorage.token)
    let roleId = tokenData.role_id;
    const options: any = {
      sizePerPage: -1, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found"
    };
    // let { brands } = this.props;
    return (
        <div className="customerDetailInvoice">
        <BootstrapTable
        trClassName={this.classFormatter}
        version="4"
        search={false}
        data = { matchedOrderArray }
        pagination={false}
        options={options}
        hover={false}
      >
         <TableHeaderColumn
          thStyle={{ fontSize: 11, whiteSpace: 'unset', border: 'none' }}
          dataField="action"
          dataFormat={actionFormatter}
          isKey
          formatExtraData={{ props: this.props, state: this.state }}
          width='100'
          export={false}
        >
        </TableHeaderColumn>
        </BootstrapTable>
        </div>
    );
  }
}
const mapStateToProps = (state: any) => {
//   console.log("order-agent_stats", state.order.agent_stats)
  return {
    orders: state.order.data,
    filteredOrders: state.order.filteredOrders,
    emptyFilteredOrder: state.order.emptyFilterOrder,
    stores: state.customer.storesList,
    agentStats: state.order.agent_stats,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    reasonsList: state.customerComplaint.complaintReasonList,
    statusList: state.order.orderStatus,
    cancelReasons: state.order.cancelReasons,
    delivery_boys: state.order.delivery_boys,
    updateTimer: state.order.isTimerUpdate,
    timerStatus: state.order.timerStatus,
    brands: state.brand.brands,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
    //   dispatch(logoutUser());
    },
    // ordersList: (obj: any) => {
    //   dispatch(ordersList(obj));
    // },
    // orderDetail: function (orderId: any) {
    //   dispatch(orderDetail(orderId));
    // },
    getTaxValue: (state_id: any) => {
    //   dispatch(getTaxValue(state_id))
    },
    getAgentStats: (days: any) => {
    //   dispatch(getAgentStats(days))
    },
    // customerDetail: (data: any) => {
    //     dispatch(customerDetail(data))
    // },
    // handleCustomerInput: (event: any) => { dispatch(handleCustomerInput(event)) },
    // addCustomer: (data: any) => { dispatch(addCustomer(data)); },
    // validateEmail: (event: any) => { dispatch(validateEmail(event)) },
    // handleAddressInput: (event: any) => { dispatch(handleAddressInput(event)) },
    storesList: function () {
    //   dispatch(getStores());
    },
    // getTaxValue: (state_id: any) => {
    //     dispatch(getTaxValue(state_id))
    // },
    getCart: function () {
    //   dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
    //   dispatch(saveCart(cart));
    },
    showorderItems: function (id: any, orders: any, channel: any) {
      dispatch(showorderItems(id, orders, channel))
    },
    complaintReasonsList: function () {
    //   dispatch(complaintReasonsList());
    },
    orderStatusList: function () {
    //   dispatch(orderStatusList())
    },
    cancelOrdersReasons: () => {
    //   dispatch(cancelOrdersReasons())
    },
    updateOrder: function (id: any, data: any, history: any) {
    //   dispatch(updateOrder(id, data, history))
    },
    luanchTimer: (brand_id: number, days: any) => {
    //   dispatch(luanchTimer(brand_id, days))
    },
    stopTimer: () => {
    //   dispatch(stopTimer())
    },
    searchOrder: (orderId: any) => {
    //   dispatch(searchOrder(orderId))
    },
    saveBrand: (brand_id: any) => {
    //   dispatch(saveBrand(brand_id))
    },
    brandsList: function () {
    //   dispatch(brandsList("GET_BY_BRAND"))
  },
  filterOrdersList : (filteredOrders: any) => {
    // dispatch(filterOrdersList(filteredOrders))
  }
};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);