import { AGENT_STATS, CANCEL_ORDER_REASONS, DELIVERY_BOYS, ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, TIMER_STATUS, UPDATE_TIMER, FILTERED_ORDERS } from "../actions/orderType";

const initialState: any = {
    data: "" || [],
    orderItems: [] || "",
    orderStatus: [] || "",
    cancelReasons: [] || "",
    order: {},
    agent_stats: {},
    delivery_boys:[] || '',
    timerStatus: true,
    filteredOrders: [] || "",
    emptyFilterOrder: false,

}

const orderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ORDERS_LIST: return {
            ...state,
            data: action.payload,
            isTimerUpdate: action.isTimer
        }
        case UPDATE_TIMER: return {
          ...state,
          isTimerUpdate: action.isTimerUpdate
        }  
        case TIMER_STATUS: return {
          ...state,
          timerStatus: action.payload == false ? false : true
        }
        case DELIVERY_BOYS: return {
          ...state,
          delivery_boys: action.payload
        }
        case AGENT_STATS: return {
          ...state,
          agent_stats: action.payload
      }
      case ORDER_ITEMS_LIST: return {
        ...state,
        orderItems: action.payload,
        order: action.orderDetail
      } 
      case ORDER_STATUS_LIST: return {
        ...state,
        orderStatus: action.payload
      }
      case CANCEL_ORDER_REASONS: return {
        ...state,
        cancelReasons: action.payload
      }
      case FILTERED_ORDERS: return {
        ...state,
        filteredOrders: action.payload,
        emptyFilterOrder: action.emptyFilterOrder
    }
      default:
        return state;
    }
  };
export default orderReducer;
  