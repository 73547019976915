import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCart, logoutUser } from '../redux'
import Clock from './clock';
import jwt from 'jsonwebtoken'
import { saveCallrecord, StartClockTimer, stopTimer } from '../redux/actions/headerAction';
import './genesysWidget.css';
// import GenesysCustomerWidget from "../redux/actions/customerAction";
// import GenesysWidget from './genesysWidget';
import GenesysWidget from './genesysWidget2';
import { GenesysCustomerWidget, customerDetail, receiveCustomerData } from '../redux/actions/customerAction';

class Topbar extends Component<{ cart: any[],  time: any,saveCallrecord:(data:any) => {},customerDetail:(data:any)=>{},GenesysCustomerWidget:(data:any) => {}, stopTimer: ()=> {}, getCart: () => {}, logoutUser: () => {} }, {showWidget: boolean, genesysTestClick: boolean, isJQueryCodeExecuted: boolean}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            isJQueryCodeExecuted: false,
            //showWidget: false, // Initially, the widget is hidden
            genesysTestClick: false,
            showWidget: JSON.parse(sessionStorage.getItem('widgetVisibility') as string) || false,
        };
        // this.captureAgentStats=this.captureAgentStats.bind(this)
        this.toggleGenesysWidget=this.toggleGenesysWidget.bind(this)
        // this.testGenesys=this.testGenesys.bind(this);
    }
    componentDidMount() {
        this.props.getCart();
    }
    toggleGenesysWidget = () => {
        // Use a callback function to ensure that sessionStorage is updated after setState is complete
        this.setState((prevState) => ({
          showWidget: !prevState.showWidget,
        }), () => {
          // Store the visibility state in sessionStorage
          sessionStorage.setItem('widgetVisibility', JSON.stringify(this.state.showWidget));   
          const isFirstClick = sessionStorage.getItem('isFirstClick') === null;
          if (isFirstClick) {
            sessionStorage.setItem('isFirstClick', 'true');
            this.props.GenesysCustomerWidget(true);
          } else {
            sessionStorage.removeItem('isFirstClick');
            // this.props.GenesysCustomerWidget(false);
          }
          let { time } = this.props;
          // console.log(time)
          time = time / 60;
          let rounded_time = Math.round(time * 10) / 10;
          // console.log(rounded_time)
          let PhoneData = sessionStorage.getItem('phone');
          if (PhoneData !== '') {
            let callStatsObj = {
              call_duration: rounded_time,
              call_status: 1,
              customer_number: PhoneData,
              total_sale: 0,
            };
            // this.props.saveCallrecord(callStatsObj);
            // this.props.stopTimer()
          }
        });
    }
      
//       testSampleGenesys = async() => {
//         console.log("JUNAID SHEIKH");

//         let payload = {
//     "EVENT":"EventEstablished",
//     "Service":"PureCloud",
//     "MediaType":"voice",
//     "attachdata":{
//         "AuthenticationStatus":"CustomerAuthenticated",
//         "calledAddress":"+13172140816",
//         "queueName":"test queue",
//         "BusinessArea ":"IFP",
//         "Skill":"MySkill"
//     },
//     "CallType":"Inbound",
//     "eventType":"Established",
//     "InteractionID":"860a3317-4851-4766-8ebd-ada497b02b13",
//     "ConnectionID":"860a3317-4851-4766-8ebdada497b02b13",
//     "AgentID":"0ae78cef-69f4-4c20-be6b-1b8724d9bc35",
//     "CustomerID":"b63e962d-09dd-4a93-8ae3-6378b9338198",
//     "Duration":0,
//     "TimeStamp":0,
//     "ANI":"+971509353528",
//     "DNIS":"+13172140816"
// }

//         if (window.myPublicFunction) {
//             window.myPublicFunction(payload);
//           } else {
//             console.error('myPublicFunction is not defined in the public JS file');
//           }
//       }  

    testGenesys = async() => {
        let callData = 
            {
                'EVENT': 'EventEstablished',
                'SourceMsg': {
                    'topicName': 'v2-users-d006d09a-99074d1a-e562c82cfce.conversations',
                    'version': '2',
                    'eventBody': {
                        'id': '2f2e5ac-d52a-454b-b3a8-e44b9ad0facf',
                        'participants': [
                            {
                                'id': 'bac05b71-6899-41e5-b6cb-9a'
                            },
                            {
                                'id': 'bac05b71-6899-41e5-b6cb-9h'
                            },
                            {
                                'id': 'bac05b71-6899-41e5-b6cb-9a'
                            },
                        ],
                        'recordingState': 'none',
                        'address': "sp:JohnBrown_Cell_1@localhost",
                    },
                    'metaData': {},
                    'service': 'PureCloud',
                    'MediaType': 'voice',
                    'attachData': {},
                    'callType': 'Outbound',
                    'eventType': 'Established',
                    'InteractionId': '2f2e5ac-d52a-454b-b3a8-e44b9ad0facf',
                    'ConnectionId': '2f2e5ac-d52a-454b-b3a8-e44b9ad0facf',
                    'AgentId': '8fa5e776-0743-4356-93e1-3b791cedd6a6',
                    'CustomerId': '029cdc84-9bba-4ecd-9b7d-1313d7e61bbe',
                    'Duration': 0,  // Assuming these are numbers
                    'Timestamp': 0, // Assuming these are numbers
                    'ConnectedTime': '2019-03-15T13:51:59.205Z',
                    // 'DNIS': 'tel: +39022222222222222',
                    'DNIS': 'tel: 971504828225',
                    'ANI': 'sip: JohnBrown_Cell_1@localhost'
                },
            };
        let parsePayloadData = callData;
        // console.log("completeStaticData900==>", parsePayloadData.SourceMsg.DNIS);
        // const parseDNS = parsePayloadData.SourceMsg.DNIS;
        if(parsePayloadData){
            if (parsePayloadData.SourceMsg.eventType === 'Established') {
                const customerPhoneNumber = parsePayloadData.SourceMsg.DNIS
                // const customerPhoneNumber = "tel: +3902222222222";
                // Use regex to extract the phone number
                const phoneNumberMatch = customerPhoneNumber.match(/\d+/);
                // sessionStorage.setItem('customerPhone', phoneNumberMatch);
                if (phoneNumberMatch) {
                    const extractedPhoneNumber = phoneNumberMatch[0];
                    sessionStorage.setItem('customerPhone', extractedPhoneNumber);
                    const sessionPhone = sessionStorage.getItem('customerPhone');
                    if(sessionPhone){
                        const currentPathname = window.location.pathname;
                        if (currentPathname !== '/customerDetail') {
                            window.location.href = '/customerDetail';
                        } else{
                            let csphone = sessionStorage.getItem("customerPhone");
                            this.props.receiveCustomerData(csphone)
                            if(csphone){
                                sessionStorage.removeItem("customerPhone")
                                setTimeout(() => {
                                    sessionStorage.setItem('customerPhone', extractedPhoneNumber);
                                }, 500);
                            }else{
                                sessionStorage.setItem('customerPhone', extractedPhoneNumber);
                            }
                            // let payloadData = { "brand_id": 0, "phone_number": csphone };
                            // this.props.customerDetail(payloadData);
                            this.props.GenesysCustomerWidget(true);
                        }
                    }
                    // console.log("extractPhoneNumber", extractedPhoneNumber)
                    // let payloadData = { "brand_id": 0, "phone_number": sessionPhone };
                    // const tokenData = sessionStorage.getItem('token');
                    // try {
                    //     const token = tokenData;  
                    //     // Define your headers
                    //     const headers = {
                    //     Accept: 'application/json',
                    //     'Content-Type': 'application/json',
                    //     Authorization: `Bearer ${token}`,
                    //     };
                    //         // Make the POST request using Axios
                    //         const response = await axios.post('http://localhost:5007/call-portal/getCustomer', payloadData, {
                    //         headers: headers,
                    //         });
                    //         // console.log("response800", response)
                    //         // Check if the response status code is in the 200 range (i.e., success)
                    //         if (response.status === 201) {
                               
    //                 //         const responseData = response.data;
    //                 //         // Check if the response contains 'success'
    //                 //         if (responseData.success) {
    //                 //             // console.log("successResponseData9000==>>", responseData.successResponse)
    //                 //             //sessionStorage.setItem('customerPhone', responseData.successResponse.phone_number);     
    //                 //             if (responseData.success) {
    //                 //                 const currentPathname = window.location.pathname;
    //                 //                 if (currentPathname !== '/customerDetail') {
    //                 //                   window.location.href = '/customerDetail';
    //                 //                 }
    //                 //             }                                  
    //                 //         } else {
    //                 //             console.log(responseData.error.message);
    //                 //         }
    //                 //         } else {
    //                 //         throw new Error('Network response was not ok');
    //                 //         }
    //                 //     } catch (err) {
    //                 //         console.log("An error occurred during the HTTP request: " + err.message);
    //                 // }

    //             } else {
    //                 console.log("Please Enter Valid phone number");
    //             }
    //         }        
    //     }
    //     this.setState((prevState) => ({
    //     genesysTestClick: !prevState.genesysTestClick,
    //     }));
    // }
    }}}}    
    // captureAgentStats () {
    //     let {time} = this.props;
    //     console.log(time)
    //     time = time/60;
    //     let rounded_time = Math.round(time*10)/10
    //     console.log(rounded_time)
    //     let callStatsObj = {
    //         call_duration: rounded_time,
    //         call_status: 3,
    //         customer_number: sessionStorage.getItem('phone'),
    //         total_sale: 0,
    //       }
    //       this.props.saveCallrecord(callStatsObj);
    //    this.props.stopTimer()
    // }
    render() {
        // dc3545
        let token:any = sessionStorage.getItem("token")
        let decoded:any = jwt.decode(token)
        let color:any
        if(decoded && decoded.brand && decoded.brand.color) {
            color = decoded.brand.color
        } else {
            color = '#dc3545'
        }
        let { time } = this.props;
        return (
            <header className="header">
                <nav style={{background:"#d9b165"}} className="navbar">
                    <div className="container-fluid">
                        <div className="navbar-holder d-flex align-items-center justify-content-between">
                            {/* <!-- Navbar Header--> */}
                            <div className="navbar-header">
                                {/* <!-- Navbar Brand --> */}
                                <Link to="/dashboard" className="navbar-brand d-none d-sm-inline-block">
                                    <div className="brand-text d-none d-lg-inline-block"><span>Simplex</span><strong>CRM</strong></div>
                                    <div className="brand-text d-none d-sm-inline-block d-lg-none"><strong>SimplexCRM</strong></div></Link>
                                {/* <!-- Toggle Button--> */}
                                <Link id="toggle-btn" to="#" className="menu-btn active"><span></span><span></span><span></span></Link>
                            </div>
                            {/* <div className='test-btn'>
                                <button type="button" onClick={this.testSampleGenesys} className="btn btn-success">Receive Call</button>
                            </div> */}
                            {/* <!-- Navbar Menu --> */}
                            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                                {/* <li className="nav-item" title="Cart"><Clock /></li> */}
                                {/* { this.props.showCount && */}
                                {/* genesis new stats */}
                                <li onClick={this.toggleGenesysWidget} style={{cursor: 'pointer', padding: '0 2%'}} className="nav-item genesys-widget">                  
                                    {/* <img src={process.env.PUBLIC_URL + "/assets/img/genesys-call-center-icon.png"} alt="..." className="img-fluid rounded-circle" style={{ marginTop: 5, width:'40px', height: 'auto'}} /> */}
                                    <i className="fa fa-headphones circleIcon"></i>
                                </li> 
                                {/* <li onClick={this.captureAgentStats} style={{cursor: 'pointer'}} className="nav-item">                        
                                    <img src={process.env.PUBLIC_URL + "/assets/img/phone-hang-up.png"} alt="..." className="img-fluid rounded-circle" style={{ marginTop: 5, width:'25px'}} />
                                </li>  */}
                                <li style={{width:'100px'}} className="nav-item"><Clock time={time} /> </li>
                                <li className="nav-item" title="Cart"><Link to="/cart" className="nav-link"><i className="fa fa-shopping-cart"></i><span className="badge bg-red badge-corner">{this.props.cart.length > 0 && JSON.parse(this.props.cart).length}</span></Link></li>
                                <li className="nav-item" title="Profile"><Link to="/profile" className="nav-link"><i className="fa fa-user-circle"></i></Link></li>
                                {/* <!-- Logout    --> */}
                                <li onClick={() => this.props.logoutUser()} className="nav-item btn"><span className="d-none d-sm-inline mx-1">Logout</span><i className="fa fa-sign-out"></i></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* <div>
                    {
                        (this.state.showWidget||this.props.isFirstClick) &&  <GenesysWidget isVisible={this.state.showWidget} toggleVisibility={this.toggleGenesysWidget} />
                    }
                </div> */}
                <div>
                    {this.state.showWidget && <GenesysWidget isVisible={this.state.showWidget} toggleVisibility={this.toggleGenesysWidget} />}
                </div>
            </header>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        cart: state.cart.cartData,
        time: state.customer.clockCount,
        showCount: state.customer.showCount,
        isFirstClick: state.customer.isFirstClick,
        customerPhone: state.customer.customerPhone,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getCart: function () {
            dispatch(getCart());
        },
        stopTimer: function () {
            dispatch(stopTimer())
          },
          saveCallrecord: function (data:any) {
              dispatch(saveCallrecord(data))
          },
          GenesysCustomerWidget: (value: any) => {
            dispatch(GenesysCustomerWidget(value))
          },
          receiveCustomerData: function (data: any) {
            // alert(data)
            dispatch(receiveCustomerData(data));
        },
        customerDetail: (data: any) => {
            dispatch(customerDetail(data));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);