import {
  GET_CART,
  CLEAR_CART,
  SAVE_CART,
  COUPON_DATA,
  GET_TAX,
  GET_INST,
} from './cartType';
import Api from '../../components/Api';

export const getCart = () => {
  return async (dispatch: any) => {
    let cart: any = await sessionStorage.getItem('cart');
    if (cart == null || cart.length <= 0) {
      cart = [];
    }
    dispatch({
      type: GET_CART,
      payload: cart
    });
  };
};
export const clearCart = () => {
  return async (dispatch: any) => {
    sessionStorage.removeItem('cart');
    dispatch(getCart());
  };
};
export const saveCart = (cart: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    //convert cart to string
    let strCart = JSON.stringify(cart);
    //save cart
    sessionStorage.setItem('cart', strCart);
    dispatch(getCart());
  };
};
export const applyCoupon = (coupon_code: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    Api.post(`/menu/apply_coupon`, {
      coupon_code: coupon_code,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_DATA,
            payload: response.data.coupon,
          });
          alert(response.data.successResponse)
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === 'string') {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === 'object') {
            error = err.response.data.message[0].replace(/_/g, ' ');
          }
          alert("Sorry,this coupon is not valid")
        } else {
          alert(err.message)
        }
      });
  };
};
export const getTaxValue = (state_id: any) => {
  return async (dispatch: any) => {
    Api.get(`menu/tax_value/${state_id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          dispatch({
            type: GET_TAX,
            payload: "",
          });
        } else {
          alert(err.message)
        }
      });
  };
};
export const getTaxValueByStateAndBrand = (state_id: any) => {
  return async (dispatch: any) => {
    Api.get(`menu/tax_value/${state_id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          dispatch({
            type: GET_TAX,
            payload: "",
          });
        } else {
          alert(err.message)
        }
      });
  };
};
export const getInst = (text: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_INST,
      instructions: text
    });
  };
};
