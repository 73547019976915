export const ALL_GROUPS = "ALL_GROUPS"
export const MENU_ITEMS = "MENU_ITEMS"
export const HANDLE_INPUT = "HANDLE_INPUT"
export const ORDERS_COUNTER = "ORDERS_COUNTER"
export const ITEM_DETAIL = "ITEM_DETAIL"
export const HANLE_ADDRESS_INPUT="HANLE_ADDRESS_INPUT"
export const STORES_LIST="STORES_LIST"
export const HANDLE_GEOCODE_ADDRESS = "HANDLE_GEOCODE_ADDRESS"
export const SELECT_STORE_ID = "SELECT_STORE_ID"
export const GET_TAX_CASH = "GET_TAX_CASH"
export const GET_TAX_CARD = "GET_TAX_CARD"
export const BACKUP_STORES_LIST = "BACKUP_STORES_LIST"
export const COUPON_DATA = "COUPON_DATA"
export const SAVE_DELIVERY_TIME = "SAVE_DELIVERY_TIME"
export const SAVE_TAX= "SAVE_TAX"
export const SAVE_DISCOUNT="SAVE_DISCOUNT"
export const SAVE_CART_TOTAL="SAVE_CART_TOTAL"
export const  SAVE_DELIVERY_FEE=" SAVE_DELIVERY_FEE"
export const ORDER_PERIOD="ORDER_PERIOD"
export const STORES_OPTIONS="STORES_OPTIONS"
export const LOADING_INDICATOR="LOADING_INDICATOR"
export const ORDER_RESET = "ORDER_RESET"
export const HOUSE_NO = "HOUSE_NO"
export const SELECTED_ADDRESS = "SELECTED_ADDRESS"
// export const RECENT_ITEMS = "RECENT_ITEMS"
// export const CUSTOMERS_LIST ="CUSTOMERS_LIST"
