import React, { Component } from 'react';
import interact from 'interactjs';
import { connect } from 'react-redux';
import { GenesysCustomerWidget } from '../redux/actions/customerAction';

interface State {
  isDragging: boolean;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  isWidgetVisible: boolean;
  width: number;
  height: number;
}

interface Props {
  isVisible: boolean;
  toggleVisibility: () => void;
  GenesysCustomerWidget:(data:any) => void;
}

 class GenesysWidget extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDragging: false,
      offsetX: window.innerWidth / 2 - 150,
      offsetY: window.innerHeight / 2 - 150,
      zIndex: 9999,
      isWidgetVisible: true,
      width: 300,
      height: 400,
    };
  }

  componentDidMount() {
    // Retrieve the URL from sessionStorage
    const iframeUrl = sessionStorage.getItem('iframeUrl');

    if (iframeUrl) {
      // Set the URL as the src attribute of the iframe
      const iframeElement = document.getElementById('pureFrame') as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.src = iframeUrl;
      }
    }

    // Add Interact.js behavior for dragging and resizing
    interact('.draggable-widget')
      .draggable({
        listeners: {
          start: () => {
            this.setState({ isDragging: true, zIndex: this.state.zIndex + 1 });
          },
          move: (event) => {
            const { x, y } = event.target.dataset;
            const offsetX = parseFloat(x || '0') + event.dx;
            const offsetY = parseFloat(y || '0') + event.dy;

            event.target.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
            event.target.dataset.x = offsetX.toString();
            event.target.dataset.y = offsetY.toString();
          },
          end: () => {
            this.setState({ isDragging: false });
          },
        },
        // modifiers: [
        //   interact.modifiers.restrictRect({
        //     restriction: 'parent', // Constrain to the parent element (the screen)
        //   }),
        // ],
        inertia: true,
      })
      .resizable({
        edges: { left: true, right: true, bottom: false, top: false }, 
        listeners: {
          move: (event) => {
            // Test Data
            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)
    
            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'
    
            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top
    
            target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
    
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            // End Test
            const { width } = event.rect;
            // event.target.style.width = width + 'px';
            // event.target.style.height = this.state.height + 'px'; 
            const iframe = event.target.querySelector('iframe');
            if (iframe) {
              iframe.width = width;
            }
          },
        },
        modifiers: [
          // interact.modifiers.restrictEdges({
          //   outer: 'parent',
          // }),
          interact.modifiers.restrictSize({
            min: { width: 300, height: 400 }, // Adjust minimum width as needed
          }),
        ],
        inertia: true,
      });
  }

  handleCloseWidget = () => {
    this.props.toggleVisibility();
    sessionStorage.removeItem('isFirstClick');
    this.props.GenesysCustomerWidget(false)

  };

  render() {
    const { offsetX, offsetY, zIndex, isDragging, isWidgetVisible, width, height } = this.state;
    const widgetStyle = {
      transform: `translate(${offsetX}px, ${offsetY}px)`,
      zIndex: zIndex,
      width: width + 'px',
      height: height + 'px',
    };

    return (
      <div className={`draggable-widget ${isDragging ? 'dragging' : ''}`} style={widgetStyle}>
        {isWidgetVisible ? (
          <div className='h-100 overflow-hidden'>
            <iframe id="pureFrame" allow="camera *; microphone *; autoplay *" width={width} height={height}></iframe>
            <div className="closeMark" onClick={this.handleCloseWidget}>
              {' '}
              X{' '}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  
}
const mapStateToProps = (state: any) => {
  return {
    
      isFirstClick: state.customer.isFirstClick
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
   
        GenesysCustomerWidget: (value: any) => {
          dispatch(GenesysCustomerWidget(value))
        }

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GenesysWidget);
